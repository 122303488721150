footer {
  display: flex;
  flex-flow: row;
  background-color: #333;
  color: #fff;
  padding: 2rem;
  padding-bottom: 0;
  width: 100%;
  margin: auto;
}

footer.shrink {
  width: 90%;
}

.footer-links {
  width: 25%;
  text-align: right;
}

.footer-links li {
  padding: 0.5rem 0rem;
}

footer h2 {
  text-align: right;
  padding: 0.5rem 0rem;
}

.footer-contact {
  width: 25%;
}

.footer-contact p {
  display: flex;
  flex-flow: row;
  align-items: center;
  color: #fff;
}

.footer-contact p span {
  margin-left: 0.5rem;
}

.footer-contact li {
  padding: 0.5rem 0rem;
}

footer .hotline a {
  background-color: #478e9e;
  padding: 0.5rem;
  border-radius: 6rem;
  width: auto;
  display: inline-block;
}

footer .hotline a img {
  margin: auto;
  display: block;
  width: auto;
}

.footer-post {
  width: 50%;
}

.footer-post-heading {
  display: flex;
  flex-flow: row;
  padding: 0rem 1rem;
}

.footer-post-heading h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-post-heading h2 span {
  width: 30px;
  margin-left: 1rem;
}

footer .articles_section_container {
  display: flex;
  flex-flow: row;
  background-color: transparent;
}

footer .articles_box_container {
  display: flex;
  flex-flow: row wrap;
  background-color: transparent;
  padding: 0;
}

footer .articles_section_container .articles_box {
  width: 33.3%;
}

footer .articles_section_container .articles_box .inner_container {
  height: 200px !important;
  border-radius: 6px;
}

/* start sticky download banner  */
.download-app-sticky {
  background: linear-gradient(to right, #2b60a4, #427ab5);
  color: #fff;
  padding: 0.5rem 0;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999999;
}

.download-app-sticky img {
  width: 150px !important;
}

.download-app-sticky .android-mobile,
.download-app-sticky .ios-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
