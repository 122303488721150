.contact_us_page .intro_form_container {
  padding-top: 0rem;
}

.contact_us_title {
  padding: 0rem 3rem;
}

.contact_us_page .register_form_container form input,
.contact_us_page .register_form_container form textarea,
.contact_us_page .register_form_container form select {
  margin: 0.5rem 0rem;
}

.contact_form_header {
  color: $base-1;
  padding: 0.5rem;
}

.contact_us_page .intro .headign_h1 span {
  padding: 1rem 0rem;
}

.inner_big_container {
  position: relative;
}

.contact_us_page .intro {
  position: relative;
  height: auto;
}

.webview .contact_us_page .intro {
  padding-top: 0rem !important;
}
.webview .contact_us_page .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.webview .contact_form_header {
  padding-top: 1.5rem;
}

.home-medical-form {
  position: relative;
  margin: 2rem 2%;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-top: -5rem;
}
.home-medical-form .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  object-fit: contain;
  left: 0;
  right: auto;
}

.home-medical-form .form_header {
  position: relative;
  border: none !important;
}

.home-medical-form .contact_form_header {
  font-size: 1.5rem;
  font-weight: 900;
  color: #000;
  border: none;
  border-radius: 0 !important;
  width: 100%;
  margin: auto;
  /* text-align: center; */
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  float: none;
  text-align: right;
  padding-right: 1.5rem;
}

.home-medical-form .form_container {
  position: relative;
  width: 100%;
  margin: auto;
  padding: 2rem;
  background: #eee;
  border-radius: 1rem;
}
.home-medical-form form input,
.home-medical-form form textarea,
.home-medical-form form select {
  width: 100%;
  margin: 0.5rem 0rem !important;
  font-size: 1.2rem !important;
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  border: none;
  border: 1px solid #e3e3e3;
  border-radius: 100px !important;
}
.home-medical-form form select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: left;
}
.home-medical-form .submit-btn:hover {
  background-color: #fff;
}
.home-medical-form .form_btn_container {
  padding: 10px 0;
}

.upload-input-container {
  width: 100%;
  margin: 0.5rem 0rem !important;
  font-size: 1.2rem !important;
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  border: none;
  border: 1px solid #e3e3e3;
  border-radius: 100px !important;
  color: #8b8b8b;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-upload-contactus {
  border: solid 1px #e5e5e5;
  background-color: #478e9e;
  color: #fff;
  font-size: 1rem;
}

.input-upload-contactus {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
