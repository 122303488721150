@media only screen and (min-width: 1367px) {
  .intro .owl-carousel .owl-item img {
    object-fit: cover;
  }
}

@media only screen and (min-width: 1090px) and (max-width: 1250px) {
  .editProfile_page .top_profile_details .name_img span {
    bottom: 17.5rem;
  }
  .header-logo {
    max-width: 16%;
  }
}

@media only screen and (min-width: 1025px) {
  .playstore-mobile {
    /* display: none; */
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }
  .playstore-mobile h2 {
    padding-left: 8px;
  }
  [dir='ltr'] .playstore-mobile h2 {
    padding-right: 5rem;
    padding-left: unset;
  }
  header .logout_header_link a {
    background-color: transparent;
    border-bottom: 1px solid #eee;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    color: #000;
  }
  header .logedin_header_link a:last-of-type {
    border: none;
  }
  header .logout_header_link a:hover {
    background-color: #eee;
  }
  .notificationList_title {
    display: none;
  }
  .intro {
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
  }
  header .logedin_header_link:hover ~ .logout_header_link {
    display: block !important;
    transition: all 300ms ease;
  }
  .how_it_work .section_title {
    width: 76%;
    text-align: start;
    display: inline-block;
    padding-right: 3rem;
  }
  .how_it_work .doctor_tab,
  .how_it_work .user_tab {
    padding: 0.6rem 2rem;
    border: 1px solid;
    border-radius: 58px;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
  }
  .how_it_work_tabs .tab_item h2 {
    color: #fff !important;
  }
  .how_it_work .doctor_tab:hover,
  .how_it_work .user_tab:hover {
    border: 1px solid;
    color: #176ba0;
    background-color: #767171;
    border-color: #767171;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
  }
  .how_it_work .doctor_tab.active,
  .how_it_work .user_tab.active {
    border: 1px solid;
    color: #176ba0;
    background-color: #fff;
    border-color: #fff;
  }
  .how_it_work_tabs .tab_item.active h2 {
    color: #176ba0 !important;
  }
  .how_it_work .doctor_tab:hover .tab_item h2,
  .how_it_work .user_tab:hover .tab_item h2 {
    color: #176ba0 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .feature_item {
    padding: 1rem;
  }
  .feature_item p {
    min-height: auto;
  }
  .feature_item img {
    max-width: 44%;
  }
  .intro .owl-carousel .owl-item img {
    object-fit: cover;
  }
  .header-fixed {
    display: none;
  }
  .advices_box_container .article_img_overlay .h2_hide {
    display: none;
  }
  .advices_box_container.articles_box .article_img_overlay_p {
    display: none;
  }
  .advices_box_container .article_img_overlay {
    top: 0;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.51);
  }
  .playstore-mobile {
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0rem;
  }
  .playstore-mobile h2 {
    padding-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
  .rotating-item {
    object-fit: contain;
    position: relative;
    height: auto;
  }
  .how_it_work .section_title {
    width: 100%;
  }
  .how_it_work_user,
  .how_it_work_doctor {
    flex-flow: column;
  }
  .homepage .intro ul {
    display: none;
  }
  .footer-links,
  .footer-contact,
  .footer-post {
    width: 100%;
  }
  footer {
    flex-flow: column;
  }
  .header-logo span {
    display: none;
  }
  .intro .owl-controls .owl-dots {
    top: 88%;
  }
  .intro .intro-slider {
    position: relative;
    border-radius: 0;
    left: 0 !important;
    right: 0 !important;
    bottom: 0;
    text-align: center;
    font-size: 0.8rem;
    width: 100% !important;
  }
  .intro .owl-carousel .owl-item img {
    max-height: 187px;
    height: 100% !important;
  }
  .how_it_work_step h2 {
    font-size: 1.2rem;
  }
  .how_it_work_step {
    margin-bottom: 1rem;
  }
  footer .articles_section_container .articles_box {
    width: 100%;
  }
  .footer-contact,
  .footer-links {
    border-bottom: 2px solid #e8308a;
  }
  .features .section_subtitle {
    width: 100%;
  }
  .footer-post-heading {
    flex-flow: column;
    flex-grow: 1;
  }
  .header-social,
  .header-actions {
    display: none;
  }
  .navbar .header-social {
    display: flex !important;
  }
  .header_hotline_mobile {
  }
  .hiddenLoader {
    height: auto;
  }
  [dir='ltr'] header nav.navbar .login_header_link {
    line-height: 19px;
    padding-left: 3vw !important;
  }
  [dir='ltr'] header .logout_header_link {
    left: auto;
    right: 5rem;
    width: 16rem;
    max-width: 100%;
  }
  [dir='ltr'] header .profileLoader {
    float: left;
    margin-right: 10px;
    margin-left: 0rem;
  }
  [dir='ltr'] .login_header_link a {
    display: block !important;
    width: 72% !important;
    float: left !important;
  }
  [dir='ltr'] header .hello_span {
    float: left;
    width: 72%;
    text-align: left;
  }
  .intro .intro_form_container {
    margin-bottom: 0px !important;
  }
  #homePage .left_form.IsUser .form_btn_container {
    width: 50% !important;
  }
  #homepage,
  footer {
    width: 100%;
  }
  .hiddenLayer {
    display: none;
  }
  header .main_nav_links a {
    line-height: 2rem !important;
  }
  .noDegree_notify {
    padding: 11px 2rem;
  }
  .noDegree_notify h2 {
    font-size: 1rem;
    line-height: 2.6rem;
  }
  .payment_btn {
    position: relative;
    display: block;
    left: 0;
    display: inline-block !important;
    width: 49.61% !important;
  }
  .profile_page .profileName {
    line-height: 8rem;
  }
  .TextCenter {
    text-align: center;
  }
  .blockDiv {
    display: inline-block;
    width: 100%;
    background-color: #f0f0f0;
  }
  .qualificationImgContainer {
    max-height: 160px;
    margin: -1.5rem -1rem 1rem;
  }
  .table_heading {
    display: none;
  }
  .notificationList_title {
    display: inline-block;
    width: 100%;
    padding: 0.5rem;
  }
  .notification_item a {
    padding: 0.5rem 0rem !important;
  }
  #notification_list .link_back i {
    padding-right: 0.5rem;
    vertical-align: sub;
    padding: 0.6rem 0.3rem;
    float: left;
  }
  #notification_list {
    padding: 0.5rem;
    position: fixed;
    top: 0;
    left: -100%;
    right: auto;
    bottom: 0;
    width: 320px !important;
    max-width: 100% !important;
    z-index: 99999999999;
    overflow: hidden;
    box-shadow: 0px 2px 4px #333;
    height: 100% !important;
    transition: all 200ms;
    display: inline !important;
    max-height: 100% !important;
    height: 100% !important;
  }
  .show_notification #notification_list {
    transition: all 200ms;
    left: 0;
  }
  .notification a.link_back {
    border-bottom: 1px solid #eee;
  }
  .notification a i {
    padding: 1rem;
  }
  .notification {
    padding-left: 0rem !important;
    padding-bottom: 0rem !important;
  }
  header nav.navbar .login_header_link {
    position: absolute;
    top: 1rem;
    left: 0;
    text-align: end;
    display: inline-block;
    padding: 0.3rem;
    width: auto;
    border-bottom: none;
  }
  header nav.navbar .logedin_header_link {
    height: 120px;
  }
  .logout_header_link.show {
    padding: 0.5rem 2rem !important;
  }
  header .hello_span {
    font-size: 1rem !important;
    float: right;
  }
  header nav.navbar li a {
    font-size: 1rem;
    display: block;
    text-align: start;
  }
  .highlight-language {
    margin-top: auto !important;
  }
  header .logout_header_link {
    position: relative !important;
    top: 0 !important;
    border: none;
    width: 100% !important;
    box-shadow: none !important;
    left: 0 !important;
    background-color: #0c73c0 !important;
    display: inline-block !important;
    border-bottom: 1px solid #eeeeee0f !important;
    right: 0;
  }
  .logedin_header_link.link_back {
    padding: 0.5rem !important;
    display: none !important;
  }
  .logout_header_link {
    display: none !important;
  }
  .logout_header_link.show,
  .logedin_header_link.link_back.show {
    display: block !important;
  }
  .visits_section .show_msg,
  .show_details {
    margin: auto;
    float: none !important;
    height: auto;
    display: block;
    clear: both;
    background-color: #eee;
    padding: 0rem;
    margin-bottom: 0.3rem;
  }
  .grouped_elements {
    height: auto;
  }
  .payments_section .section_body {
    text-align: center;
  }
  .payment_details {
    padding: 0.5rem 0rem;
  }
  .payment_month {
    padding: 0.5rem 0rem;
  }
  .articles_box_container {
    padding: 0rem 2rem;
  }
  .paymentDetails .payment_summary {
    padding: 1rem 2rem;
  }
  .paymentDetails .visit_details,
  .paymentDetails .person_name {
    padding: 0.5rem 0rem;
  }
  .paymentDetails .payment_price {
    text-align: center;
  }
  .sitemap-inner-container {
    padding: 1rem;
  }
  .help .help_questions_inner_container {
    padding: 1rem 3rem;
  }
  #homePage .intro .intro_heading_right {
    padding-top: 7%;
  }
  #homePage .intro .intro_mobile_div {
    overflow: hidden;
    width: 44%;
    height: auto;
    display: flex;
    flex: inherit;
    float: left;
  }
  #homePage .intro .intro_mobile_div img {
    object-fit: cover;
    height: 100%;
  }
  #homePage .intro .app_icons_group {
    display: none;
  }
  #homePage .intro_heading {
    width: 56% !important;
    display: inline-block;
  }
  #homePage .intro .headign_h1 {
    padding-bottom: 1.2rem;
  }
  .tabletGrid {
    display: flex;
    max-height: 252px;
    direction: ltr;
  }
  .intro .intro_mobile_div img {
    height: auto;
    width: 100%;
  }
  .editProfile_page #bottom_profile,
  #top_profile,
  #certificates_profile,
  #experience_profile,
  #specializedIn_profile,
  .editProfile_page #top_profile {
    padding: 0 2rem;
  }
  .social-media li {
    width: 25% !important;
  }
  header .navbar {
    position: fixed;
    display: inline-block !important;
    top: 0;
    left: -320px;
    right: auto;
    bottom: 0;
    width: 320px !important;
    max-width: 100% !important;
    background-color: #0c73c0;
    color: #fff;
    overflow: hidden;
    box-shadow: 0px 2px 4px #333;
  }
  .nav_inner_container {
    display: inline-block;
  }
  .hello_span {
    font-size: 1rem !important;
  }
  header .toogle_navbar_icon {
    display: inline-block !important;
    float: left;
    color: #3366ff;
    padding: 0.5rem;
    font-size: 2rem;
    border-radius: 5px;
    margin-top: 0px;
    cursor: pointer;
    padding-left: 1rem;
    margin-right: 10%;
    /* margin-right: auto; */
  }
  [dir='ltr'] header .toogle_navbar_icon {
    margin-left: 0;
    margin-right: auto;
  }
  header .toogle_navbar_icon:hover {
    color: $base-4;
    background-color: $base-1-alt;
    transition: all 200ms ease;
  }
  header .navbar {
    padding: 1rem 0rem;
    transition: all 200ms;
  }
  header .navbar.openNav {
    left: 0;
    z-index: 99999999999;
  }
  .notification_item i {
    line-height: 2rem;
  }
  header .link_back {
    display: block !important;
    float: left;
    text-align: end;
    cursor: pointer;
  }
  header .link_back i {
    padding-right: 0.5rem;
    vertical-align: middle;
  }
  header .navbar .link_with_border {
    border-bottom: none;
  }
  header nav.navbar li {
    display: block;
    padding: 0.2rem 2rem !important;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(238, 238, 238, 0.05);
  }
  header .navbar li.link_back {
    display: inline-block !important;
    float: right;
    width: auto;
    padding: 0.5rem 1rem;
  }
  header .navbar .link_with_border a {
    text-align: center;
  }
  .intro {
    padding-top: 0rem !important;
    background-color: #fff;
  }
  .intro-slider h1 {
    padding: 0rem 2rem;
    color: #478e9e;
    font-size: 1.2em;
  }
  header .header_hotline_mobile {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    line-height: inherit;
    top: 100%;
    text-align: center;
    border-radius: 0;
    margin: auto !important;
    display: block;
    background-color: #f39;
    color: #fff;
    font-size: 1.2rem;
    padding: 1.1rem 0rem;
  }
  .intro .intro_form_container {
    right: 0% !important;
    left: 0% !important;
  }
  .intro .intro_heading {
    position: relative;
    padding: 0rem !important;
  }
  .intro .headign_h1 {
    font-size: 2.4rem;
    text-align: center;
  }
  .intro .headign_h1 span {
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
  }
  .intro .intro_button_group .intro_button {
    font-size: 0.8rem !important;
  }
  .intro .heading_inner_container {
    width: 100% !important;
    float: none;
    margin: auto;
    float: none;
    display: block;
  }
  .intro .intro_heading_right {
    width: 100% !important;
    padding: 1rem;
  }
  .intro .app_icons_group {
    width: 100%;
    left: 0;
    right: 0;
    background-color: #81a3ca;
    padding: 0.5rem 1rem;
    margin: auto;
    display: block;
    float: none;
    position: relative;
    padding-bottom: 1.5rem;
  }
  .testimo_box_container .owl-next,
  .articles_box_container .owl-next {
    top: -53px;
    right: 2%;
  }
  .testimo_box_container .owl-prev,
  .articles_box_container .owl-prev {
    top: -53px;
    left: 2%;
  }
  .intro_button_group {
    margin: auto;
    text-align: center;
  }
  .register_form_container {
    border-radius: 0px;
  }
  .login_form,
  .resetPassword_form,
  .register_form,
  .newPassword_form {
    width: 95%;
  }
  .login_page .app_icons_group,
  .resetPassword_page .app_icons_group,
  .register_page .app_icons_group {
    float: right;
    width: 100%;
  }
  .loginPage .intro,
  .contact_us_page .intro {
    background-image: none !important;
  }
  .login_page .links,
  .resetPassword_page .links,
  .register_page .links {
    width: 100%;
  }
  .login_page .links a,
  .resetPassword_page .links a,
  .register_page .links a {
    text-align: start;
  }
  .newPassword_page {
    padding-bottom: 1rem;
  }
  .resetSuccess_page {
    padding-bottom: 5rem;
  }
  .how_it_work_section,
  .testimonial_section_container {
    padding: 5rem 2rem;
  }
  .genericPage .end_article_details p {
    font-size: 1rem;
  }
  .forgetPassPage .intro {
    height: initial !important;
  }
  .forgetPassPage .submit-btn {
    width: 100%;
  }
  .forgetPassPage .backToRegister {
    display: block;
    text-align: center;
  }
  footer .social_media_footer li {
    display: inline-block;
    font-size: 1.3rem;
    padding: 1rem 0.3rem;
    margin: auto;
    float: none;
    text-align: center;
  }
  footer .social_media_footer ul {
    width: 100%;
    text-align: center;
  }
  footer .important_links ul {
    padding-top: 0.5rem;
    width: 100%;
    margin: auto;
    float: none;
    display: block;
    text-align: center;
  }
  footer .important_links ul li {
    display: inline-block;
    padding: 1rem 0.5rem;
    margin: auto;
    float: none;
    text-align: center;
    font-size: 1rem;
  }
  /* EDIT PROFILE */
  .editProfile_page .name_img span {
    bottom: 18rem;
    right: 7rem !important;
  }
  .editProfile_page .address_phone h5 {
    line-height: 3.5rem;
  }
  .editProfile_page .profil_links a {
    text-align: center;
    padding: 1rem 0.2rem;
  }
  .editProfile_page #bottom_profile {
    padding: 0 0.5rem;
  }
  /* END OF EDIT PROFILE */
  .profile_page .row a {
    font-size: 1.1rem;
    text-align: center;
    display: block;
    background-color: #eee;
    margin-top: 0.5rem;
    margin: auto;
    float: none;
  }
  .error_404 .error_details h2 {
    font-size: 1rem;
  }
  .error_404 .error_details {
    padding: 0rem;
  }
  .error_404 .error_details h1 {
    font-size: 2rem !important;
  }
  .blogListing .section_title {
    padding: 0.5rem !important;
  }
  .visit_msg a,
  .show_msg a {
    background-color: transparent !important;
  }
  header .logo {
    max-width: 50%;
    padding-top: 16px;
  }
  .basic_details label {
    font-size: 0.8rem !important;
    padding: 0.3rem 0rem;
  }
  .editProfile_page .intl-tel-input {
    margin: 0rem !important;
  }
  .editProfile_page input,
  .editProfile_page select,
  .editProfile_page textarea {
    margin: 0px !important;
  }
  .details_text {
    max-width: 49%;
  }
  .editProfile_page label {
    font-size: 0.9rem;
  }
  #showAddQualificationButton,
  #showAddExperienceButton,
  #showAddSpecialized {
    margin-top: 0rem;
  }
  #qualification_form.show,
  #experiences_form.show,
  #specialized_form.show,
  .experienceEditDiv.show,
  .qualificationEditDiv.show {
    padding: 3rem !important;
    overflow: scroll;
    height: 100%;
  }
  #centeredPath {
    width: 90% !important;
    transform: scaleX(0.98) translateX(14px) !important;
  }
  .how_it_work_tabs .tab_item.active h2 {
    color: #fff !important;
  }
  .how_it_works_page .how_it_work {
    padding: 3rem 0rem;
  }
  .artcle_title {
    font-size: 1rem;
  }
  .how_it_work_doctor .inner_container {
    padding-top: 0rem;
  }
  .svg_container {
    position: relative;
    z-index: 9999;
    width: 100%;
    margin: auto;
    overflow: hidden;
    float: none !important;
    left: 0 !important;
    right: 0 !important;
    display: block;
    opacity: 1 !important;
  }
  .how_it_work .circle_overlay {
    position: relative;
    width: 100%;
    max-height: 252px;
    display: block;
    float: none;
    left: 0;
    right: 0;
    top: 0;
  }
  .how_it_work_doctor #svgCONTAIN_docto {
    height: auto;
    width: 100%;
    position: relative;
  }
  .mobile_frame {
    display: none;
  }
  .how_it_work .section_title {
    padding: 0rem;
  }
  .tab_item {
    padding: 0.5rem 2rem !important;
  }
  .step_title {
    width: 95%;
    position: relative;
    bottom: auto;
    margin: auto;
    float: none !important;
    display: block;
    padding: 0rem !important;
  }
  .step_title h4 {
    color: #fff;
    max-width: 100%;
    text-align: center;
  }
  .step_title h2 {
    padding: 1rem 0rem;
    font-size: 1.5rem;
    text-align: center;
  }
  .steps_bullet {
    margin: auto;
    float: none;
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.5rem !important;
  }
  nav .logedin_header_link.desktop_login_link {
    display: flex !important;
  }
  .how_it_work_user .prev_step,
  .how_it_work_doctor .prev_step {
    top: 21%;
    right: 4%;
  }
  .how_it_work_user .next_step,
  .how_it_work_doctor .next_step {
    top: 21%;
    left: 4%;
  }
  .how_it_work_user .inner_container,
  .how_it_work_doctor .inner_container {
    padding-top: 0rem;
  }
  .how_it_work svg {
    transform: scale(1.3) translateX(-39px);
    -webkit-transform: scale(1.3) translateX(-39px);
    -ms-transform: scale(1.3) translateX(-39px);
  }
  .how_it_work .app_icons_group {
    max-width: 70%;
    text-align: center;
    margin: auto;
  }
  .visit-details_page .main_inner_container {
    padding: 0rem 4rem;
  }
  .msg_history .visit_details_header {
    padding: 0rem 2rem;
  }
  .msg_history_body .inner_container {
    padding: 0rem 3rem;
    padding-bottom: 3rem;
  }
  header .doctor_profile_link {
    display: inline-block !important;
    text-align: start !important;
    float: right !important;
  }
  .top_profile_details .profileLoader {
    margin: auto;
  }
  .top_profile_details .address_phone h5 {
    line-height: 3rem;
  }
  .intro .app_button_container {
    width: 42%;
    margin: auto;
  }
  header .navbar .logedin_header_link.link_back {
    display: none !important;
  }
  .logedin_header_link.desktop_login_link {
    position: relative !important;
    width: 100% !important;
    margin: auto !important;
    padding-left: 3rem !important;
    display: none;
  }
  footer .copy_rights h4 {
    text-align: center;
  }
  .footer-post-heading {
    padding: 1rem;
  }
  .footer-post-heading h2 {
    font-size: 1rem;
  }
}