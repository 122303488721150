.how_it_work {
  background-image: url('../images/bg_01.jpg');
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgb(74, 146, 159)),
    url('../images/bg_01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 300px;
}

.how_it_work_user,
.how_it_work_doctor {
  text-align: center;
  position: relative;
  display: none;
}

.how_it_work .app_icons_group .soon_app_title {
  color: #b8b8b8 !important;
}

.how_it_work_user.active,
.how_it_work_doctor.active {
  display: flex;
}

.how_it_work .how_it_work_tabs {
  margin: auto;
  width: 100%;
  float: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  padding: 0rem;
  /* background-color: rgba(255, 255, 255, 0.050980392156862744); */
  background-color: #478e9e;
  margin-bottom: 2rem;
  justify-content: center;
}

.how_it_work_tabs .tab_item h2 {
  color: #eee;
  font-size: 1rem;
}

.how_it_work_tabs .tab_item h2 {
  color: #969696;
  font-size: 1rem;
}

.how_it_work_tabs .tab_item.active h2 {
  color: $base-1 !important;
}

.how_it_work .doctor_tab,
.how_it_work .user_tab {
  text-align: center;
  padding: 0rem 3rem;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  margin: 0rem 0.5rem;
}

.how_it_work .doctor_tab.active,
.how_it_work .user_tab.active {
  color: $base-1-alt;
  transition: all 200ms ease;
}

.how_it_work .section_title {
  padding: 1rem;
  clear: both;
  color: #fff;
}

.how_it_work .circle_overlay {
  height: 609px;
  width: 502px;
  margin: auto;
  position: absolute;
  top: -61px;
  display: block;
  left: 0;
  right: -110px;
}

.svg_container {
  margin: auto;
  overflow: hidden;
  position: relative;
  z-index: 9999;
  max-width: 356px;
  margin: auto;
  overflow: hidden;
  float: right;
  right: 14%;
}

.how_it_work_user #svgCONTAIN,
.how_it_work_doctor #svgCONTAIN_doctor {
  height: auto;
  width: 100%;
  position: relative;
}

.how_it_work_user #svgCONTAIN svg,
.how_it_work_doctor #svgCONTAIN_doctor svg {
  width: 100%;
  height: 100%;
}

.how_it_work_user #patiant_doc_1,
.how_it_work_user #patiant_doc_2,
.how_it_work_user #patiant_doc_3,
.how_it_work_user #patiant_doc_4,
.how_it_work_user #patiant_doc_5 {
  opacity: 0;
}

.how_it_work_user .prev_step,
.how_it_work_doctor .prev_step {
  display: inline-block;
  position: absolute;
  top: 45%;
  left: auto;
  right: 6%;
  width: 83px;
  height: 50px;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 25px;
  text-align: center;
  color: #fff;
  border: 1px solid #d2d2d2;
  z-index: 9999;
}

.how_it_work_user .prev_step:hover,
.how_it_work_doctor .prev_step:hover,
.how_it_work_user .next_step:hover,
.how_it_work_doctor .next_step:hover {
  transition: all 200ms;
  background-color: transparent;
  color: #d2d2d2;
}

.how_it_work_user .next_step,
.how_it_work_doctor .next_step {
  display: inline-block;
  position: absolute;
  top: 45%;
  right: auto;
  left: 6%;
  width: 86px;
  height: 50px;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 25px;
  text-align: center;
  color: #fff;
  border: 1px solid #d2d2d2;
  z-index: 9999;
}

.next_step i,
.prev_step i {
  line-height: 3rem;
  font-size: 1rem;
}

/* HOW IT WORKS - USER */

#svgCONTAIN #patiant_user,
#svgCONTAIN.step_3 #patiant_user {
  -ms-transform: translate(13px, 2px);
  /* IE 9 */
  -webkit-transform: translate(13px, 2px);
  /* Safari */
  transform: translate(13px, 2px);
  transition: all 300ms ease;
  opacity: 1;
}

#svgCONTAIN #patiant_doc_1 {
  -ms-transform: translate(6px, -30px);
  /* IE 9 */
  -webkit-transform: translate(6px, -30px);
  /* Safari */
  transform: translate(6px, -30px);
  opacity: 0;
  transition: all 300ms ease;
}

#svgCONTAIN.step_2 #patiant_doc_1 {
  -ms-transform: translate(6px, 8px);
  /* IE 9 */
  -webkit-transform: translate(6px, 8px);
  /* Safari */
  transform: translate(6px, 8px);
  transition: all 300ms ease;
  opacity: 1;
}

#svgCONTAIN #patiant_doc_2 {
  -ms-transform: translate(50px, 7px);
  /* IE 9 */
  -webkit-transform: translate(50px, 7px);
  /* Safari */
  transform: translate(50px, 7px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 50ms;
  /* Safari */
  transition-delay: 50ms;
}

#svgCONTAIN.step_2 #patiant_doc_2 {
  -ms-transform: translate(50px, 100px);
  /* IE 9 */
  -webkit-transform: translate(50px, 100px);
  /* Safari */
  transform: translate(50px, 100px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 50ms;
  /* Safari */
  transition-delay: 50ms;
}

#svgCONTAIN #patiant_doc_3 {
  -ms-transform: translate(50px, 7px);
  /* IE 9 */
  -webkit-transform: translate(50px, 7px);
  /* Safari */
  transform: translate(50px, 7px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 100ms;
  /* Safari */
  transition-delay: 100ms;
}

#svgCONTAIN.step_2 #patiant_doc_3 {
  -ms-transform: translate(50px, 100px);
  /* IE 9 */
  -webkit-transform: translate(50px, 100px);
  /* Safari */
  transform: translate(50px, 100px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 100ms;
  /* Safari */
  transition-delay: 100ms;
}

#svgCONTAIN #patiant_doc_4 {
  -ms-transform: translate(50px, 7px);
  /* IE 9 */
  -webkit-transform: translate(50px, 7px);
  /* Safari */
  transform: translate(50px, 7px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 150ms;
  /* Safari */
  transition-delay: 150ms;
}

#svgCONTAIN.step_2 #patiant_doc_4 {
  -ms-transform: translate(50px, 100px);
  /* IE 9 */
  -webkit-transform: translate(50px, 100px);
  /* Safari */
  transform: translate(50px, 100px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 150ms;
  /* Safari */
  transition-delay: 150ms;
}

#svgCONTAIN #patiant_doc_5 {
  -ms-transform: translate(800px, -640px);
  /* IE 9 */
  -webkit-transform: translate(800px, -640px);
  /* Safari */
  transform: translate(800px, -640px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 200ms;
  /* Safari */
  transition-delay: 200ms;
}

#svgCONTAIN.step_2 #patiant_doc_5 {
  -ms-transform: translate(800px, -440px);
  /* IE 9 */
  -webkit-transform: translate(800px, -440px);
  /* Safari */
  transform: translate(800px, -440px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 200ms;
  /* Safari */
  transition-delay: 200ms;
}

#svgCONTAIN #patiant_doc_6 {
  -ms-transform: translate(266px, 50px);
  /* IE 9 */
  -webkit-transform: translate(266px, 50px);
  /* Safari */
  transform: translate(266px, 50px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 250ms;
  /* Safari */
  transition-delay: 250ms;
}

#svgCONTAIN.step_2 #patiant_doc_6 {
  -ms-transform: translate(266px, 224px);
  /* IE 9 */
  -webkit-transform: translate(266px, 224px);
  /* Safari */
  transform: translate(266px, 224px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 250ms;
  /* Safari */
  transition-delay: 250ms;
}

#svgCONTAIN #patiant_doc_7 {
  -ms-transform: translate(50px, 7px);
  /* IE 9 */
  -webkit-transform: translate(50px, 7px);
  /* Safari */
  transform: translate(50px, 7px);
  opacity: 0;
  transition: all 300ms ease;
  -webkit-transition-delay: 300ms;
  /* Safari */
  transition-delay: 300ms;
}

#svgCONTAIN.step_2 #patiant_doc_7 {
  -ms-transform: translate(50px, 100px);
  /* IE 9 */
  -webkit-transform: translate(50px, 100px);
  /* Safari */
  transform: translate(50px, 100px);
  transition: all 300ms ease;
  opacity: 1;
  -webkit-transition-delay: 300ms;
  /* Safari */
  transition-delay: 300ms;
}

#svgCONTAIN.step_3 #patiant_doc_2,
#svgCONTAIN.step_3 #patiant_doc_3,
#svgCONTAIN.step_3 #patiant_doc_4,
#svgCONTAIN.step_3 #patiant_doc_5,
#svgCONTAIN.step_3 #patiant_doc_6,
#svgCONTAIN.step_3 #patiant_doc_7 {
  opacity: 0;
  -webkit-transition-delay: 400ms;
  /* Safari */
  transition-delay: 400ms;
  transition-duration: 1s;
  transition-timing-function: ease;
}

#svgCONTAIN #waypoint {
  transition: 2s;
  transition-delay: 0.2s;
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000 !important;
  opacity: 0;
}

#svgCONTAIN.step_3 #waypoint {
  stroke-dashoffset: 0;
  opacity: 1;
}

/* HOW IT WORKS - DOCTOR */

.how_it_work_doctor #patient,
.how_it_work_doctor #path_2_,
.how_it_work_doctor #download_1_,
.how_it_work_doctor #app_1_ {
  -ms-transform: translateY(-20px);
  /* IE 9 */
  -webkit-transform: translateY(-20px);
  /* Safari */
  transform: translateY(-20px);
  opacity: 0;
  transition: all 300ms ease;
}

#svgCONTAIN_doctor.step_1 #download_app {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  opacity: 1;
  transition: all 300ms ease;
}

#svgCONTAIN_doctor.step_1 #download_1_ {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  opacity: 1;
  transition: all 300ms ease;
  transition-delay: 300ms;
}

#svgCONTAIN_doctor.step_1 #app_1_ {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  opacity: 1;
  transition: all 300ms ease;
  transition-delay: 900ms;
}

#svgCONTAIN_doctor.step_2 #download_1_,
#svgCONTAIN_doctor.step_2 #app_1_,
#svgCONTAIN_doctor.step_2 #download_app {
  opacity: 0;
  transition: all 300ms ease;
}

#svgCONTAIN_doctor.step_2 #map_big {
  opacity: 1;
  transition: all 300ms ease;
}

#svgCONTAIN_doctor.step_1 #not_available_3_ {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  opacity: 1;
  transition: all 300ms ease;
}

#svgCONTAIN_doctor.step_1 #available_3_ {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  transition: all 300ms ease;
  transition-duration: 1s;
  opacity: 1;
  -webkit-transition-delay: 10s;
  transition-delay: 10s;
}

#svgCONTAIN_doctor.step_2 #patient,
#svgCONTAIN_doctor.step_3 #patient {
  -ms-transform: translateY(0px);
  /* IE 9 */
  -webkit-transform: translateY(0px);
  /* Safari */
  transform: translateY(0px);
  opacity: 1;
  transition: all 300ms ease;
  opacity: 1;
}

#svgCONTAIN_doctor #waypoint {
  opacity: 0;
  transition: 2s;
  transition-delay: 0.5s;
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000 !important;
}

#svgCONTAIN_doctor.step_3 #waypoint {
  stroke-dashoffset: 0;
  opacity: 1;
}

.how_it_work_container {
  position: relative;
}

.mobile_container {
  z-index: 9999;
}

.mobile_frame {
  position: relative;
  overflow: hidden;
  z-index: 999;
  width: 302px;
  height: 426px;
}

.step_title {
  width: 60%;
  float: left;
  text-align: start !important;
  bottom: 3rem;
  position: absolute;
  z-index: 999;
  left: 0;
  color: #000;
  font-size: 1.2rem;
}

.step_title h2 {
  padding: 1rem 0rem;
  font-size: 1.6rem;
  color: #fff;
}

.step_title h4 {
  color: #fff;
  max-width: 50%;
}

.steps_bullet {
  padding-top: 4rem;
}

.bullet_item {
  width: 20px;
  height: 20px;
  background-color: #d1d1d1;
  display: inline-block;
  border-radius: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.bullet_item.active {
  background-color: #8f8f8f;
}

.step_title {
  width: 100%;
  float: left;
  float: right;
  right: auto;
  left: 0;
  padding-right: 600px;
}

.how_it_work .app_icons_group {
  width: 39%;
}

/* how it works page */

.how_it_works_page {
  padding-top: 5rem;
}

.webview .how_it_works_page {
  padding-top: 0rem;
}

.how_it_works_page .how_it_work {
  background-color: #fff;
}

#homePage .mobile_container,
.how_it_works_page .mobile_container {
  opacity: 0;
}

.ie10 .how_it_work_user #svgCONTAIN svg,
.ie10 .how_it_work_doctor #svgCONTAIN_doctor svg {
  transform: scale(3.5) translate(0rem, 4rem);
}

.ie11 .how_it_work_user #svgCONTAIN svg,
.ie11 .how_it_work_doctor #svgCONTAIN_doctor svg {
  transform: scale(3.5) translate(1.5rem, 4rem);
}

.ie10 .how_it_work .circle_overlay .ie11 .how_it_work .circle_overlay {
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0px;
  display: block;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.ie9 .articles_section_container .articles_box {
  padding: 1rem;
  float: right;
  width: 33.33%;
}

@-moz-document url-prefix() {
  .how_it_work .circle_overlay {
    top: 10%;
  }
}

.how_it_work_user {
  background-color: transparent;
}

.how_it_work_step {
  display: flex;
  flex-flow: column;
  text-align: center;
  position: relative;
  justify-content: center;
  text-align: center;
}

.how_it_work_step_img {
  width: 50%;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.how_it_work_step h2 {
  bottom: 0;
  top: auto;
  background-color: #e5e5e5;
  width: auto;
  margin: auto;
  text-align: center;
  margin-top: -2.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  color: #176bb1;
  z-index: 999;
  border: 1px solid;
  font-weight: 900;
}

.how_it_work_step_img span {
  position: absolute;
  color: #fff;
  font-size: 2rem;
  height: 54px;
  background-color: #e8308a;
  width: 54px;
  border-radius: 50%;
  z-index: 9999999;
  margin-right: 9%;
}

.how_it_work_user,
.how_it_work_doctor {
  padding: 1rem 0rem;
}
