.review_starts
{
    font-size: 1.2rem;
}
.review_starts .not_fill
{
    color: #d2d2d2;
}

.review_starts .fill
{
    color: #ffb401;
}