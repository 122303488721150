.articles_box_container {
    padding: 2rem;
}

.articles_section_container {
    background-color: #f4f4f4;
}

.articles_section_container .articles_box {
    padding: 1rem;
    direction: rtl;
}

[dir=ltr] .articles_section_container .articles_box {
    direction: ltr;
}

.articles_section_container .articles_box .inner_container {
    min-height: 200px;
    background-color: $white;
    overflow: hidden;
    transition: all 300ms;
    border: 1px solid #ddd;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    position: relative;
    display: block;
}

.artcle_img {
    height: 220px;
    width: auto;
    position: relative;
}

.artcle_img img {
    height: 100%;
    object-fit: cover;
}

.article_details {
    padding: 1rem;
    text-align: start;
}

.article_details p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    line-height: 2rem;
    max-height: 6rem;
}

.article_details p,
.article_details h2 {
    color: $very-dark-gray !important;
}

.artcle_title {
    font-weight: 900;
    text-align: start;
    max-height: 36px;
    overflow: hidden;
    display: block;
}

.articles_box .inner_container:hover .article_img_overlay {
    top: 0%;
    z-index: 99;
    transition: all 500ms ease;
}

.article_img_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    float: none;
    width: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    top: 100%;
    z-index: -99;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.overlay_readMore {
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    bottom: 0;
    padding: 6rem 0rem;
}

.article_img_overlay span {
    color: #fff;
    font-size: 1.2rem;
    padding: 0.2rem 1rem;
}

.read_more_articl {
    font-size: 1.4rem;
    vertical-align: sub;
    padding: 0rem 0.5rem;
    color: #333;
    transition: all 200ms;
}

.read_more_articl:hover {
    color: $base-1;
    transition: all 200ms;
}

.owl-carousel .owl-item img {
    background-repeat: no-repeat;
    background-position: 50%;
    object-fit: contain;
    width: 100%;
    height: 100%;
}