.payment-details-parent-container {
  max-width: 700px;
  margin: 0 auto;
}
.payment-details-parent-container .img-container {
  text-align: right;
  margin-bottom: 50px;
}
.payment-details-container {
  width: 100%;
}

.payment-details-parent-container th,
.payment-details-parent-container td {
  padding: 0.5rem;
  vertical-align: middle;
}
.payment-details-parent-container th {
  background: #478e9e;
  color: #fff;
}
.payment-details-parent-container td {
  padding-left: 20px;
  padding-right: 20px;
}
.payment-details-parent-container header {
  display: block;
}
.payment-details-parent-container h1,
.payment-details-parent-container h2 {
  width: 100%;
}
.payment-details-parent-container h1 {
  text-align: center;
  padding: 30px;
  font-size: 2.3rem;
}
