.reservation-all {
  padding: 3rem 0;
}

.reservation-all .search-reservation-all input {
  border-radius: 100px;
  background-color: #fff !important;
}
.reservation-all .search-reservation-all .search-reservation-btn {
  border-radius: 41px;
  background-color: #236170;
  color: #fff !important;
  border: 2px solid transparent;
  transition: all 150ms;
  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem !important;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.reservation-all .reservation-all-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 7rem;
}

.reservation-all .reservation-all-table thead {
  background-color: #f8f8f8;
}

.reservation-all .reservation-all-table th,
.reservation-all .reservation-all-table td {
  padding: 16px;
  color: #236170;
  font-size: 1.3rem;
}

.reservation-all .reservation-all-table th {
  font-weight: bold;
}

.reservation-all .reservation-all-table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.reservation-all .reservation-all-table .reservation-all-table-timeslot {
  direction: ltr;
  text-align: right;
}

.reservation-all .reservation-all-table .reservation-all-table-timeslot span {
  padding: 0.5rem 1rem;
  border-radius: 25px;
  border: solid 1px #f8f8f8;
  background-color: #f6f6f6;
  font-size: 1.1rem;
}

.reservation-all
  .reservation-all-table
  .reservation-all-table-timeslot.reservation-all-table-timeslot--status
  span.accepted {
  background-color: #46c6cb;
  color: #fff;
}

.reservation-all
  .reservation-all-table
  .reservation-all-table-timeslot.reservation-all-table-timeslot--status
  span.canceled {
  background-color: #ec1b98;
  color: #fff;
}

.reservation-all
  .reservation-all-table
  .reservation-all-table-timeslot.reservation-all-table-timeslot--status
  span.pending {
  background-color: #ffc220;
  color: #4f5151;
}

.reservation-all
  .reservation-all-table
  .reservation-all-table-timeslot.reservation-all-table-timeslot--status
  span.completed {
  background-color: #488f77;
  color: #fff;
}

  .reservation-all .reservation-all-table .reservation-all-table-actions {
    display: flex;
    align-items: center;
  }

    .reservation-all .reservation-all-table .reservation-all-table-actions a {
      padding: 0.2rem 0.7rem;
      border-radius: 24px;
      background-color: #488f77;
      font-size: 0.9rem;
      color: #fff;
      display: block;
      text-align: center;
    }

.reservation-all
  .reservation-all-table
  .reservation-all-table-actions
  a:nth-child(2) {
  background-color: #ec1b98;
}
.reservation-all
  .reservation-all-table
  .reservation-all-table-actions
  a:nth-child(3) {
  background-color: #46c6cb;
}

.reservation-all-modal-class.modal {
  max-width: 70% !important;
  text-align: right;
  background-color: #fff;
  border-radius: 48px;
  box-shadow: 0 3px 30px 0 #000;
  border: solid 1px #dedede;
}

.reservation-all-modal-class .reservation-all-modal-headName-label {
  color: #236170;
  font-size: 1.5rem;
  font-weight: 500;
}

.reservation-all-modal-class .reservation-all-modal-headName-details-label {
  color: #236170;
  font-size: 1.5rem;
  font-weight: bold;
}

.reservation-all-modal-class .reservation-all-modal-headName-p {
  color: #236170;
  font-weight: 300;
  font-size: 1.2rem;
}

.reservation-all-modal-class .reservation-all-modal-rating {
  color: #f1bf4b;
  font-size: 1.5rem;
}

.reservation-all-modal-class .reservation-all-modal-rating-container {
  border-radius: 23px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reservation-all-modal-class .reservation-all-modal-rating-container p {
  font-size: 2rem;
  font-weight: 500;
  color: #236170;
}

.reservation-all-modal-class hr {
  border: solid 1px #dedede;
  width: 100%;
  margin: 2rem 0;
}

.reservation-all-modal-class .reservation-all-table-actions a {
  padding: 0.2rem 0.7rem;
  border-radius: 24px;
  background-color: #488f77;
  font-size: 0.9rem;
  color: #fff;
}

.reservation-all-modal-class .reservation-all-table-actions a:nth-child(2) {
  background-color: #ec1b98;
}

.reservation-all-modal-class .reservation-all-table-actions a:nth-child(3) {
  background-color: #46c6cb;
}

@media screen and (max-width: 1206px) {
  .reservation-all .reservation-all-table caption {
    font-size: 1.3em;
  }

  .reservation-all .reservation-all-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .reservation-all .reservation-all-table tr {
    display: block;
    margin-bottom: 0.625em;
  }

  .reservation-all .reservation-all-table td {
    display: block;
    font-size: 0.8em;
    text-align: center;
  }

  .reservation-all .reservation-all-table .reservation-all-table-actions a {
    display: inline-block;
    margin-top: 0.5rem;
  }

  .reservation-all .reservation-all-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: right;
    font-weight: bold;
    text-transform: uppercase;
  }

  .reservation-all .reservation-all-table td:last-child {
    border-bottom: 0;
  }

  .reservation-all-modal-class.modal {
    max-width: 100% !important;
    text-align: center;
  }

  .reservation-all-modal-class .reservation-all-modal-headName-label {
    font-size: 1.2rem;
  }

  .reservation-all-modal-class .reservation-all-modal-headName-details-label {
    font-size: 1.2rem;
  }

  .reservation-all-modal-class .reservation-all-modal-rating-container {
    margin-top: 1rem;
  }

  .reservation-all-modal-class .reservation-all-table-actions {
    display: flex;
    flex-direction: column;
  }

  .reservation-all-modal-class .reservation-all-table-actions a {
    margin-bottom: 1rem;
  }
}
