.features {
  padding: 1rem 4rem;
}

  .features .section_title {
    padding: 1rem;
    color: #000;
  }

.feature_item {
  text-align: center;
  color: #444;
  background-color: #eee;
  padding: 2rem;
}

  .feature_item p {
    font-size: 1rem;
    min-height: 100px;
  }

.features .section_subtitle {
  text-align: center;
  width: 70%;
  margin: auto;
  font-size: 1.3rem;
  margin: 1rem auto;
  font-weight: 200;
}

.feature_item span {
}

.feature_item img {
  width: auto;
  border-radius: 50%;
}

.feature_item h2 {
  font-size: 1rem;
  padding: 2rem 0;
}

.feature {
  padding: 0rem 0.5rem;
  padding-top: 1rem;
  background-color: transparent;
  transition: all 160ms;
}

  .feature:hover span {
    color: #fff;
    border: 3px solid #6cc4ec;
    background-color: #6cc4ec;
    transition: all 160ms;
  }

/* our doctors */

.ourdoctor-main {
  padding: 2rem 0;
}

  .ourdoctor-main .section_title {
    text-align: right;
    font-size: 3rem !important;
    color: #478e9e;
    margin: 1rem 0;
  }

.our-doctors .item {
  text-align: center;
}

  .our-doctors .item h3 {
    padding-top: 1rem;
    color: #5c5c5c;
  }

.our-doctors .owl-carousel .owl-item img {
  background-repeat: no-repeat;
  background-position: 50%;
  object-fit: cover;
  width: 50%;
  height: 25%;
  border-radius: 50%;
  margin: auto;
}

.our-doctors .owl-carousel .owl-controls .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 40%;
  background-color: #599ea5;
  padding: 1rem;
  color: #fff;
  border-radius: 10px;
}

.our-doctors .owl-carousel .owl-controls .owl-nav .owl-next {
  position: absolute;
  right: 0;
  top: 40%;
  background-color: #7e7e7e !important;
  padding: 1rem;
  color: #fff;
  border-radius: 10px;
}

/* ourdoctors-new */
.our-doctors-new .card-body .card-title {
  text-align: center;
  color: #256a79;
  margin-bottom: 0;
  font-size: 1.3rem;
}

.our-doctors-new .card-body .card-text {
  text-align: center;
  color: #4c4c4c;
  margin: 0;
}

.our-doctors-new .card-body a {
  background-color: #5298a3;
  color: #fff;
  padding: 0.3rem 0.7rem;
}

.our-doctors-new .owl-next .fa {
  position: absolute;
  top: 35%;
  right: -4rem;
  font-size: 2rem;
  color: #e93e91;
}

.our-doctors-new .owl-prev .fa {
  position: absolute;
  top: 35%;
  left: -4rem;
  font-size: 2rem;
  color: #e93e91;
}

#yemen-landing-doctor.our-doctors-new .owl-next .fa {
  right: unset;
  font-size: 1rem;
}
#yemen-landing-doctor.our-doctors-new .owl-prev .fa {
  left: unset;
  font-size: 1rem;
}

/* latest-medical-news */

.latest-midical-news {
  background-color: #f4f4f4;
  padding: 2rem 0;
}

  .latest-midical-news .latest-midical-news-h2 {
    text-align: right !important;
    font-size: 3rem !important;
    color: #478e9e !important;
    margin-bottom: 1rem !important;
  }

  .latest-midical-news h3 {
    font-size: 1.3rem !important;
    color: #4c4c4c !important;
    line-height: 1.3;
  }

  .latest-midical-news p {
    font-size: 1.1rem !important;
    color: #4c4c4c !important;
  }

  .latest-midical-news .card-body a {
    color: #e93e91 !important;
    background-color: unset;
  }

.our-doctors-new .owl-carousel .owl-controls .owl-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.our-doctors-new .owl-carousel .owl-controls .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 4.5px;
  display: inline-block;
  background-color: #919191;
  margin: 0.5rem;
}

.our-doctors-new .owl-carousel .owl-controls .owl-dot.active span {
  background-color: #e82f89;
  width: 10px;
  height: 10px;
  border-radius: 4.5px;
}

/* our people cpounter */
.our-people-counter {
  background: url(../images/2021/layer-18.png) no-repeat center center;
  background-size: cover;
  padding: 2rem 0;
}

  .our-people-counter img {
    width: 180px;
  }

  .our-people-counter h2 {
    font-size: 4rem;
    color: #fff;
    height: 90px;
    font-weight: bold;
  }

  .our-people-counter p {
    font-size: 2rem;
    color: #fff;
  }
/* our testimonials */

.testimonial {
  /* background: url(../images/testimonial/Testimonial_BG.png) no-repeat center
    center;
  background-size: cover;
  height: 100%; */
  color: #478e9e;
}

  .testimonial .section_title {
    text-align: center;
    font-size: 3rem;
    color: #478e9e;
    padding: 3rem 0;
    padding-bottom: 0;
  }

  .testimonial .testimonial-content {
    background-color: #fff;
    padding: 2rem 4rem;
    padding-top: 0;
  }

    .testimonial .testimonial-content .item p {
      color: #478e9e;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
    }

    .testimonial .testimonial-content .owl-controls {
      margin-top: 2rem;
    }

    .testimonial .testimonial-content .owl-carousel .owl-item img {
      background-repeat: unset;
      background-position: unset;
      object-fit: unset;
      width: unset;
      height: unset;
      margin-right: auto;
      width: 2rem;
    }

    .testimonial .testimonial-content .owl-carousel .owl-item .item {
      text-align: left;
    }

      .testimonial
      .testimonial-content
      .owl-carousel
      .owl-item
      .item
      .testimonial-card {
        display: flex;
        justify-content: flex-end;
      }

        .testimonial
        .testimonial-content
        .owl-carousel
        .owl-item
        .item
        .testimonial-card
        img {
          background-repeat: unset;
          background-position: unset;
          object-fit: unset;
          width: unset;
          height: unset;
          margin-right: unset;
          width: 6rem;
          height: 6rem;
          margin-bottom: unset;
          display: inline-block;
          border-radius: 50%;
        }

        .testimonial
        .testimonial-content
        .owl-carousel
        .owl-item
        .item
        .testimonial-card
        span {
          align-self: center;
          color: #343434;
          font-size: 1rem;
          font-weight: bold;
          padding-left: 1rem;
        }

    .testimonial .testimonial-content .owl-carousel .owl-item h4 {
      margin-top: 1rem;
    }

@media (max-width: 700px) {
  .testimonial .testimonial-content .owl-carousel .owl-item h3 {
    height: unset;
  }
}

.testimonial .testimonial-content .owl-carousel .owl-controls .owl-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial .testimonial-content .owl-carousel .owl-controls .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 4.5px;
  display: inline-block;
  background-color: #919191;
  margin: 0.5rem;
}

.testimonial
.testimonial-content
.owl-carousel
.owl-controls
.owl-dot.active
span {
  background-color: #e82f89;
  width: 10px;
  height: 10px;
  border-radius: 4.5px;
}

#testimonial-homepage {
  overflow-x: hidden;
}

  #testimonial-homepage .owl-nav .fa-long-arrow-right {
    position: absolute;
    top: 20%;
    right: -7rem;
    font-size: 2rem;
    color: #e93e91;
  }

  #testimonial-homepage .owl-nav .fa-long-arrow-left {
    position: absolute;
    top: 20%;
    left: -7rem;
    font-size: 2rem;
    color: #e93e91;
  }

/* downloadapp */
.download-apps {
  background-color: #4a929f;
  color: #fff;
  /* margin-top: 3rem; */
  padding: 2rem 0;
}

  .download-apps p {
    font-size: 2rem;
  }

  .download-apps h3 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .download-apps .download-app-img {
    width: 500px;
    margin: -4rem 0;
  }

@media (max-width: 800px) {
  .download-apps .download-app-img {
    width: 100%;
    margin: 2rem 0;
  }

  .home-medical-form {
    margin-top: unset;
  }

  .intro {
    margin-bottom: 0;
  }

  #testimonial-homepage .owl-nav .fa-long-arrow-right {
    right: -4rem;
  }

  #testimonial-homepage .owl-nav .fa-long-arrow-left {
    left: -4rem;
  }

  .justify-content-center-sm {
    justify-content: center !important;
  }

  .ourdoctor-main {
    padding: 1rem 0;
  }

    .ourdoctor-main .section_title {
      margin: 0;
    }

  .latest-midical-news .latest-midical-news-h2 {
    text-align: center;
  }

  .latest-midical-news .my-2 {
    margin: 1rem 0;
  }

  .latest-midical-news .card {
    margin: 2rem;
  }

  .latest-midical-news .fa-chevron-left {
    position: absolute;
    top: 45%;
    left: 0rem;
    font-size: 1.5rem;
    color: #4b4b4b;
  }

  .latest-midical-news .fa-chevron-right {
    position: absolute;
    top: 45%;
    right: 0rem;
    font-size: 1.5rem;
    color: #4b4b4b;
  }

  .our-people-counter img {
    width: 120px;
  }

  .our-people-counter h2 {
    font-size: 2rem;
    height: 45px;
  }

  .our-people-counter p {
    font-size: 1.5rem;
  }

  .testimonial .section_title {
    font-size: 2rem !important;
  }

  .testimonial .testimonial-content .item p {
    font-size: 1.2rem;
  }

  .download-apps p {
    font-size: 1rem;
  }

  .download-apps h3 {
    font-size: 1.3rem;
  }
}

@media (min-width: 999px) {
  .latest-midical-news p {
    height: 195px;
  }
}

@media (max-width: 500px) {
  /* our people cpounter */
  .our-people-counter {
    background: url(../images/2021/layer-18_sm.png) no-repeat center center;
    background-size: cover;
  }
}
