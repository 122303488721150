.doctor-listing-banner {
  background-image: url('../images/doctor-listing-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 170px;
  display: flex;
  align-items: center;
  color: #fff;
}

.doctor-listing-banner h1 {
  font-size: 2.5rem;
}

.doctor-listing {
  background-color: #f4f4f4;
  padding: 3rem 0;
}

.doctor-listing .doctor-list-container {
  padding-bottom: 2rem;
}

.doctor-listing .doctor-list-container .card {
  border: none;
  cursor: pointer;
}

.doctor-listing .doctor-list-container .card:hover {
  box-shadow: 0 0 18.7px 2.3px rgba(14, 48, 55, 0.3);
}

.doctor-listing .doctor-listing-card-image img {
  border-radius: 100px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  object-position: top;
}

.doctor-listing .doctor-details {
  background-color: unset !important;
  padding: unset !important;
}

.doctor-listing .card .doctor-details h3 {
  color: #468e9e;
  font-size: 1rem;
}

.doctor-listing .card .doctor-details p {
  color: #4c4c4c;
  font-size: 1rem;
  padding: 0.5rem 0;
}

.doctor-listing .card .doctor-details .doctor-listing-card-rating .fa-star {
  color: #ffb501;
  font-size: 1.5rem;
}
.doctor-listing .card .doctor-details .doctor-listing-card-rating .fa-star-o {
  color: #d3d3d3;
  font-size: 1.5rem;
}

.our-doctors-new .doctor-listing-card-rating {
  margin-bottom: 0.5rem;
}

.our-doctors-new .doctor-listing-card-rating .fa-star {
  color: #ffb501;
}

.our-doctors-new .doctor-listing-card-rating .fa-star-o {
  color: #d3d3d3;
}

[dir='ltr'] .arrow-all-ourdoctor {
  transform: rotate(180deg);
}

.doctor-filter {
  margin-bottom: 2rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  border-radius: 1rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin: 0.5rem;
}

.btn.btn-outline-doctor-filter {
  background-color: transparent;
  color: #468e9e;
  border: 1px solid #468e9e;
}

.btn.btn-outline-doctor-filter:hover {
  background-color: #468e9e;
  color: #f3f3f3;
}

.btn.btn-doctor-filter {
  background-color: #468e9e;
  color: #f3f3f3;
}