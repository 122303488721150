.resetPassword_page .title{
    color:$black;
    padding: 1rem;
    text-align: start;
    font-size: 1.5rem;
}
.resetPassword_page {
    padding: 5rem 0;
    padding-bottom: 3.9rem;
    width: 100%;
}
.resetPassword_form, 
.resetPassword_page .links {
    float: none;
    margin: auto;
    display: block;
    width: 50%;
}
.resetPassword_page .app_icons_group {
    width: 30%;
    float: none;
    margin: auto;
    display: block;
}
.resetPassword_form form input{
    width: 100%; 
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: #f6f6f6;
    border: none;
    border: 1px solid #e3e3e3;
}
.resetPassword_form form .resetPassword_btn input{
    background-color: $base-6;
    color: $white;
}
.resetPassword_page .links{
    padding: 1rem;
}
.resetPassword_page .links a{
    padding: 0.5rem;
    font-size: 1rem;
    color: $black;
    display: inline-block;
    float: right;
    text-align: center;
}
.resetPassword_page .links a:hover{
    color: $base-1;
    transition: all 150ms;
}
.resetPassword_page .app_icons_group {
    padding: 1rem 0.5rem;

}
/* Reset Success */
.resetSuccess_page {
    padding: 5rem 0;
    padding-bottom: 16.59rem;
    width: 100%;
}
.resetSuccess_page h1 {
    color: $base-1;
    text-align: center;
    padding: 6.2rem 0;
    padding-bottom: 3rem;
}
.resetSuccess_page h3 {
    text-align: center;
}
/* New Password */
.newPassword_page{
    padding: 5rem 0;
    padding-bottom: 4.6rem;
    width: 100%;
}
.newPassword_page .title {
    color: $black;
    padding: 1rem;
    text-align: start;
    font-size: 1.5rem;
}
.newPassword_form {
    float: none;
    margin: auto;
    display: block;
    width: 50%;
}
.newPassword_form b {
    padding: 0.5rem 0;
    display: block;
}
.newPassword_form form input {
    width: 100%;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: #f6f6f6;
    border: none;
    border: 1px solid #e3e3e3;
}
.newPassword_form form .newPassword_btn input {
    background-color: $base-6;
    color: $white;
}

.forgetPassPage  .intro_form_container
{
    left: auto !important;
    right: auto !important;
    float: none !important;
    display: block !important;
    margin: auto !important;
}

.forgetPassPage   .submit-btn 
{
    width: 213px;
    float: left;
    
}

.forgetPassPage .intro
{
    height: 87% !important ;
}
.forgetPassPage  .register_form_container  .form_header
{
    text-align: start;
    padding: 0.5rem;
}
.forgetPassPage   .backToRegister
{
    color: #7f7f7f;
    text-decoration: underline;
    padding: 0rem 0.5rem;
}
.forgetPassPage   .title
{
    display: block;
    float: none;
    margin: auto;
}
