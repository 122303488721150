.register_form_container {
  /* overflow: hidden; */
  border-radius: 12px;
  background-color: $gray;
}

.register_form_container .form_header {
  border-bottom: solid 1px $gray;
  /* text-align: center; */
  font-size: 1.1rem;
}
.register_form_container .form_container {
  padding: 0.5rem 1.5rem;
}
.register_form_container form input,
.register_form_container form textarea,
.register_form_container form select {
  width: 100%;
  margin: 0.2rem 0rem;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  border: none;
  border: 1px solid #e3e3e3;
}
.register_form_container ::-webkit-input-placeholder {
  color: #7f7f7f;
}
.register_form_container ::-moz-placeholder {
  color: #7f7f7f;
}
.register_form_container :-ms-input-placeholder {
  color: #7f7f7f;
}
.register_form_container :-moz-placeholder {
  color: #7f7f7f;
}

.doctor_form_tab {
  border-left: 1px solid $gray;
}
.register_form_container .submit-btn {
  background-color: #478e9e;
  color: $base-6-alt;
  border: 2px solid transparent;
  transition: all 150ms;
  margin: 0rem;
  font-size: 1rem !important;
}
.register_form_container .submit-btn:hover {
  background-color: transparent;
  color: $base-6;
  border-color: $base-6;
  transition: all 150ms;
}
.user_form_tab,
.doctor_form_tab {
  padding: 0.5rem;
  font-weight: 900;
}
.form_tab_header {
  background-color: #e3e3e3;
  cursor: pointer;
}
.form_tab_header.active {
  background-color: $gray;
  color: $base-1;
}

.register_form_container #agree {
  display: inline-block;
  width: auto;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  vertical-align: middle;
  margin-top: 9px;
  float: right;
}
.area_input {
  display: inline-block;
  float: left;
}
form.IsUser .area_input {
  width: 100% !important;
}

form.IsUser .required-highlight {
  display: none;
}
.register_form_container form.IsUser .doctor_only {
  display: none;
}
.user_fb_btn,
.register_form_container form.IsUser .doc_fb_btn {
  display: none;
}
.register_form_container form.IsUser .user_fb_btn {
  display: block;
}
.register_form_container .exper_years_doc_input {
  min-height: 45px;
}

.register_form_container .agree_container {
  font-size: 0.9rem !important;
  position: relative;
  padding: 10px 0px;
}
.IsUser #speciality-error {
  display: none !important;
}

.agreement-link a {
  color: #3398cc;
}
#homePage .register_form_container .fb_btn a {
  margin: 0rem !important;
  border: none !important;
  text-align: start;
}
#homePage .register_form_container .fb_btn a i {
  text-align: center;
}
.fb_btn,
.form_btn_container {
  padding: 10px;
}

#homePage .left_form .form_btn_container {
  width: 100% !important;
}
#homePage .left_form.IsUser .form_btn_container {
  width: 50% !important;
}

#fileuploadPath-homePageForm {
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}
