.reservation-today {
  background-color: #fff !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.reservation-today .containerr-fluid {
  /* margin-top: 50px; */
}

.reservation-today .mt-15 {
  margin-top: 15px;
}

.reservation-today .py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.reservation-today .w-100 {
  width: 100%;
}

.reservation-today .my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.reservation-today .text-center {
  text-align: center;
}

.reservation-today .fs-18 {
  font-size: 18px;
}

.reservation-today .header {
  font-weight: 800 !important;
  font-size: 1.5rem !important;
}

.reservation-today .primary-color {
  color: #236170 !important;
}

.reservation-today .align-items-center {
  align-items: center;
}

.reservation-today .daily-reservation {
  border: solid 1px #dedede;
  background-color: #f4f4f4;
  padding-bottom: 50px;
}

.reservation-today .w-4 {
  width: 4%;
}

.reservation-today .table-container {
  overflow: auto;
}

.reservation-today .table-container .table {
  width: 100%;
  border: none !important;
}

.reservation-today td {
  height: 80px;
}
.reservation-today tr .days {
  background-color: #f6f6f6;
  text-align: center;
  color: #236170;
  border-radius: 20px;
  padding: 5px;
  width: 75%;
  margin: 0 auto;
}
.reservation-today tr:nth-child(odd) {
  background-color: #fff;
  color: #236170;
}
.reservation-today tr:nth-child(even) .days {
  background: #fff;
  text-align: center;
  color: #4f5151;
  border-radius: 20px;
}

/* .reservation-today .reservation-all .reservation-all-table tr:nth-child(even) {
    background-color: #fff;
  } */
.reservation-today .reservation-all .reservation-all-table tr:nth-child(even) {
  background-color: #f8f8f8;
}
.reservation-today .w-6 {
  width: 6%;
}

.reservation-today .reservetion-time {
  background-color: #f6f6f6;
  color: #236170;
  border-radius: 20px;
  padding: 2px 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.reservation-today .btn-delete {
  border: none;
  background-color: transparent;
  color: #236170;
  vertical-align: middle;
  cursor: pointer;
}

.reservation-today .pink {
  color: #ec1b98;
}

.reservation-today .custom-input {
  border: solid 1px #dedede;
  background-color: #f8f8f8;
  border-radius: 7px;
  direction: ltr;
}

.reservation-today .add-btn {
  background-color: #236170;
  border-radius: 20px;
  border: none;
  color: #fff;
  padding: 2px 15px;
  cursor: pointer;
}

.reservation-today .add-icon {
  width: 15px;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 6px;
}

.reservation-today .empty-time {
  width: 100px;
  height: 1px;
  background-color: #707070;
  margin-right: 20px;
}

.reservation-today .reservation-all {
  padding: 1rem 0;
}
.reservation-today .reservation-all-table {
  margin-top: 0;
}

.reservation-today .reservation-all-table-timeslot {
  text-align: center !important;
}

.reservation-today .text-end {
  text-align: end;
}

.reservation-today .pb-15 {
  padding-bottom: 15px;
}

.reservation-today .show-all {
  color: #236170;
  font-size: 24px;
  text-decoration: underline;
  font-weight: 700;
}

@media screen and (max-width: 945px) {
  .reservation-today .table tr {
    display: block;
    margin-bottom: 0.625em;
  }

  .reservation-today .table td {
    display: block;
    font-size: 0.8em;
    text-align: center;
    height: 100%;
    border: solid 1px transparent;
    padding: 5px;
  }

  .reservation-today .table .dates {
    justify-content: center;
  }

  .reservation-today .table .custom-input {
    width: 50%;
  }

  .reservation-today .w-4 {
    width: 100%;
  }

  .reservation-today .table .add-btn {
    width: 50%;
  }

  .reservation-today .text-end {
    text-align: start;
  }

  .reservation-today .empty-time {
    text-align: center;
  }

  .reservation-today .days {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .reservation-today .w-15-sm {
    height: 30px !important;
  }
}
