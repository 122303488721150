.profile_page {
    background-image: url('../images/bg_01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.profile_page .breadCrumbs {
    background-color: rgba(255, 255, 255, 0.3);
}

#top_profile {
    border-bottom: 5px solid transparent;
    background-color: #fff;
    width: 86.9%;
    margin: auto;
    padding: 1rem 2rem;
    padding-bottom: 0rem;
}

.row h2 {
    font-size: 1.2rem;
    font-weight: 200;
}

.edit_profile_link {
    font-size: 1rem;
    float: left;
    padding: 0.5rem 1rem;
    color: $very-dark-gray;
}

.top_profile_details {
    border: none;
}

.profile_reviews {
    border: none;
}

.section_header {
    padding: 1rem 0rem;
    border-bottom: 1px solid $gray;
}

.section_header_title {
    display: inline-block;
    font-size: 1.4rem;
    color: #0d4884;
}

.section_body {
    padding: 1rem;
}

.section_header_link {
    display: inline-block;
    float: left;
    font-size: 1rem;
    background-color: transparent;
    color: $black;
}

.section_header_link:hover {
    border: 1px solid #ddd;
    background-color: #ddd;
    color: $black;
    transition: all 200ms;
}

.profile_visits,
.profile_payments,
.profile_reviews {
    width: 86.9%;
    margin: auto;
    padding: 0rem 1rem;
    border-bottom: 5px solid transparent;
    background-color: #fff;
    margin-top: 0.5rem;
}

.name_img span {
    right: 9rem;
    /* bottom: 23.5rem; */
    width: 35px;
    height: 35px;
    top: 81%;
    bottom: auto;
}

.noBorder {
    border: none !important;
}

.profile_links {
    display: inline-block;
    padding: 1rem 0rem 0 !important;
    margin-top: 0.7rem !important;
    float: left !important;
    clear: left;
}

.profile_link {
    display: inline-block;
    float: left;
    background-color: transparent;
    margin-right: 0.5rem;
    color: #0c0c0c;
    border-radius: 4px;
    padding: 5px 7px;
    border: 1px solid #ddd;
}

.profile_link:hover {
    background-color: #217ad5;
    color: #fff;
    transition: all 150ms;
}

.edit_profile_link a:hover {
    color: $base-6;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
}

.show_msg,
.show_details {
    text-align: end;
}

.profile_page .top_profile_details .fa-pencil {
    display: none !important;
}

#top_profile.basic {
    margin-bottom: 1rem;
}

.grouped_elements {
    display: inline-block;
    overflow: hidden;
    height: 84px;
}

.inline_title {
    display: inline-block;
}