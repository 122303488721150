.pagination li {
    display: inline-block;
    padding: 0rem 0.2rem;
    min-height: 40px;
}

.pagination {
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: center;
}

.pagination li span,
.pagination li a {
    background-color: #fff;
    color: #767676;
    border-radius: 1px;
    padding: 0rem 0.7rem;
    line-height: 20px;
    font-size: 17px;
    border: 1px solid #ddd;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
}

.pagination li span:hover,
.pagination li a:hover,
.pagination li.active a,
.pagination li.active span {
    background-color: $base-4;
    color: #fff;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
    border: 1px solid transparent;
}