h2.paid,
.payment_details.paid,
.green {
    color: forestgreen !important;
}

h2.unPaid,
.payment_details.unPaid,
.red {
    color: red !important;
}

.orange {
    color: orange;
}

.payment_endPage .show_details {
    display: none;
}

.paymentDetails .person_name {
    position: relative;
    padding: 1.45rem 0rem;
}

.paymentDetails .visit_time_payment h2 {
    color: #717171;
    font-weight: 300;
}

.paymentDetails .payment_price {
    text-align: end;
}

.paymentDetails .payment_summery_value {
    text-align: end;
}

.paymentDetails .total_payment_status {
    text-align: center;
    padding: 1rem;
}

.paymentDetails .payment_summary {
    padding: 1rem 4rem;
    color: #4c4c4c;
}

.paymentDetails .payment_summary .inner_container {
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
}

.paymentDetails .payment_summary .inner_container h2 {
    font-size: 1.4rem;
}

.paymentDetails .profile_payments {
    border: none;
}

.paymentDetails.editProfile_page {
    padding-bottom: 0rem;
}

.payment_mainContainer {
    width: 90%;
    margin: auto !important;
    float: none;
    margin-bottom: 1rem !important;
    position: relative;
}

.paymentTable {
    background-color: #fff;
    margin-bottom: 1rem;
}

.paymentTable_heading {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    color: #3399cc;
    font-size: 1rem;
    border-bottom: 1px solid;
}

.paymentTable_container {
    display: inline-block;
    width: 100%;
}

.paymentTable_container h3 {
    font-weight: 200;
    color: #11477f;
    font-size: 1.2rem;
}

.paymentTable_row {
    padding: 0.5rem 1rem;
    width: 100%;
    display: inline-block;
}

.paymentTable_row:nth-child(even) {
    background-color: #e1f7ff;
}

.paymentTable_sum {
    padding: 0.5rem 1rem;
}

.paymentTable_sum h2 {
    font-size: 1.3rem;
    font-weight: 900;
    color: #11477f;
}

.paymentPrice {
    font-weight: 900 !important;
}

.payment_btn {
    padding: 0.5rem;
    background-color: #fefefe !important;
    color: #39c !important;
    border-radius: 5px;
    transition: all 200ms;
    float: left;
    margin-right: 10px;
}

.payment_btn:hover {
    background-color: #ddd !important;
    transition: all 200ms;
}

.hiddenLabel {
    display: none;
}

.hiddenTitle {
    display: none;
}

.payment_title {
    font-size: 1.4rem !important;
    padding-bottom: 0.5rem;
    color: #11477f;
    background-color: #fff;
    margin-bottom: 1rem;
    padding: 10px;
    font-weight: bold !important;
}