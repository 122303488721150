.webview .blogListing {
  padding-top: 0rem;
}

.blogListing .articles_section_container {
  width: 92%;
  margin: auto;
  background-color: transparent;
}

.blogListing .section_title {
  text-align: right;
  color: #000;
  padding-top: 1rem;
}

.blogListing .articles_box_container {
  display: flex;
  padding: 0;
  flex-flow: row wrap;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
  margin-top: 1rem;
}

.blogListing .articles_section_container .articles_box {
  width: 33.3%;
  border-radius: 5px;
  overflow: hidden;
}

.advices_box_container .article_img_overlay {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  float: none;
  width: 100%;
  display: block;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  height: auto;
  font-size: 1.2rem;
  top: calc(100% - 4rem);
  z-index: 1;
  transform: none;
  transition: all 200ms;
}

.advices_box_container .article_img_overlay .h2_hide {
  height: 4rem;
  line-height: 4rem;
  font-size: 1rem;
}

.advices_box_container.articles_box
  .inner_container:hover
  .article_img_overlay {
  top: 0;
  transition: all 200ms;
}

.advices_box_container.articles_box .inner_container:hover .h2_hide {
  display: none;
}

.advices_box_container.articles_box .article_img_overlay_p {
  padding: 1rem 2rem;
  font-size: 0.9rem;
}

.advices_box_container .article_overlay_content h2 {
  font-size: 1rem;
}

.advices_box_container .artcle_img {
  height: 320px;
}

.advices_box_container .article_overlay_content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 320px;
}

[dir='ltr'] .blogListing .section_title {
  text-align: left;
}

/* search filter  */

#blogs-section .dropbtn {
  color: #8b8b8b;
  padding: 0.5rem;
  border: solid 1px #dfdfdf;
  cursor: pointer;
  width: 100%;
}

#blogs-section .dropbtn .categories-blogs {
  color: #333333;
  font-size: 1.3rem;
}

#blogs-section .dropdown {
  position: relative;
  display: block;
}

#blogs-section .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 3rem;
}

#blogs-section .dropdown:hover .dropdown-content {
  display: block !important;
}

#blogs-section .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

#blogs-section .dropdown a:hover {
  background-color: #dfdfdf;
}

#blogs-section .blogs-search-icon {
  padding: 1rem;
  background-color: #478e9e;
  color: #fff;

  display: flex;
}

#blogs-section .blogs-search-icon button {
  border: none;
  background: transparent;
  color: #fff;
}

#blogs-section .blogs-section-search-input {
  width: 100%;
  padding: 0.8rem;
}

#blogs-section .blogs-section-subscribe a {
  color: #478e9e;
  border: solid 1px #478e9e;
  background-color: #f7f5f2;
  font-size: 1.1rem;
  width: 100%;
  display: block;
  padding: 0.6rem;
  text-align: center;
}

#blogs-section .tags {
  /* white-space: nowrap;
  overflow-x: auto; */
  padding: 0 2rem;
  margin-top: 1rem;
}

#blogs-section .tags a {
  color: #5e9aa7;
  font-weight: 400;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
  font-size: 1.22rem;
  padding: 1rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

#blogs-section .tags a:hover {
  background-color: #5e9aa7;
  color: #fff;
}

#blogs-section .dropbtn-mobile {
  color: #8b8b8b;
  padding: 0.5rem;
  border: solid 1px #dfdfdf;
  cursor: pointer;
  width: 100%;
}

#blogs-section .tags.owl-carousel .owl-controls .owl-nav .owl-prev {
  position: absolute;
  left: -1.7rem !important;
  top: 15%;
  color: #808080;
  background: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
}

#blogs-section .tags.owl-carousel .owl-controls .owl-nav .owl-next {
  position: absolute;
  right: -1.7rem !important;
  top: 15%;
  color: #808080;
  background: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
}

/* modelsss */

/* 1. Ensure this sits above everything when visible */
.model-blog-listing {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}

.model-blog-listing.is-visible {
  visibility: visible;
}

.model-blog-listing-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}

.model-blog-listing.is-visible .model-blog-listing-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.model-blog-listing-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.model-blog-listing-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.model-blog-listing.is-visible .model-blog-listing-transition {
  transform: translateY(0);
  opacity: 1;
}

.model-blog-listing-header,
.model-blog-listing-content {
  padding: 1em;
}

.model-blog-listing-header {
  position: relative;
  background-color: #fff;
  border: solid 1px #dfdfdf;
}

.model-blog-listing-close {
  position: absolute;
  top: 8px;
  right: 0;
  padding: 1em;
  color: #4c4c4c;
  background: none;
  border: 0;
}

.model-blog-listing-close img {
  transform: rotate(180deg);
}

.model-blog-listing-close:hover {
  color: #777;
}

.model-blog-listing-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.model-blog-listing-content > *:first-child {
  margin-top: 0;
}

.model-blog-listing-content > *:last-child {
  margin-bottom: 0;
}

.model-blog-listing form {
  text-align: right;
}

.model-blog-listing form label {
  color: #333333;
  font-size: 1.2rem;
}

@media (max-width: 800px) {
  .model-blog-listing .model-blog-listing-card-image-details {
    justify-content: center;
  }

  .model-blog-listing .col-lg-10 {
    text-align: center;
  }

  .model-blog-listing .card .model-blog-listing-titles {
    justify-content: center;
  }

  .model-blog-listing-wrapper {
    left: unset !important;
    width: 100%;
  }

  .handleBlogFormInput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
}

/* modelsss */

/* 1. Ensure this sits above everything when visible */
.model-blog-subscribe {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}

.model-blog-subscribe.is-visible {
  visibility: visible;
}

.model-blog-subscribe-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}

.model-blog-subscribe.is-visible .model-blog-subscribe-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.model-blog-subscribe-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.model-blog-subscribe-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.model-blog-subscribe.is-visible .model-blog-subscribe-transition {
  transform: translateY(0);
  opacity: 1;
}

.model-blog-subscribe-header,
.model-blog-subscribe-content {
  padding: 1em;
}

.model-blog-subscribe-header {
  position: relative;
  background-color: #fff;
}

.model-blog-subscribe-close {
  position: absolute;
  top: 8px;
  right: 0;
  padding: 1em;
  color: #4c4c4c;
  background: none;
  border: 0;
}

.model-blog-subscribe-close img {
  transform: rotate(180deg);
}

.model-blog-subscribe-close:hover {
  color: #777;
}

.model-blog-subscribe-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.model-blog-subscribe-content > *:first-child {
  margin-top: 0;
}

.model-blog-subscribe-content > *:last-child {
  margin-bottom: 0;
}

.model-blog-subscribe form {
  text-align: right;
}

.model-blog-subscribe form .emailField {
  width: 100%;
  margin: 0.2rem 0rem;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  border: none;
  border: 1px solid #e3e3e3;
}

.model-blog-subscribe form .submit-btn {
  background-color: #478e9e;
  color: #fff;
  border: 2px solid transparent;
  transition: all 150ms;
  margin: 0.5rem 0rem;
  font-size: 1rem !important;
  width: 100%;
}

.model-blog-subscribe form .submit-btn:hover {
  background-color: transparent;
  color: #3398cc;
  border-color: #3398cc;
  transition: all 150ms;
}

@media (max-width: 800px) {
  .model-blog-subscribe-wrapper {
    left: unset !important;
    width: 100%;
  }

  [dir='ltr'] .model-blog-listing-wrapper {
    right: 0;
  }
}
