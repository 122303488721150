.clinic-banner {
  position: relative;
}

.clinic-banner h1 {
  position: absolute;
  top: 40%;
  right: 5%;
  color: #478e9e;
  font-size: 2.5rem;
  font-weight: 900;
}

.clinic-login .card {
  border-radius: 46px;
  border: solid 1px #dedede;
  background-color: #f8f8f8;
  margin: 2rem 0;
}

.clinic-login h2 {
  color: #4f5151;
}

.clinic-login .card label {
  text-align: right;
  color: #4f5151;
}

.clinic-login .card input {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 100px !important;
  margin: 0.5rem 0rem !important;
  font-size: 1.2rem !important;
}

.clinic-login .card .sign-in-form-submit {
  border-radius: 34px;
  border: solid 1px #f6f6f6;
  background-color: #468e9e;
  padding: 0.5rem 2.5rem;
  color: #fff;
  margin-top: 1rem;
}
.clinic-login .card .sign-in-form-forgetPassBtn {
  color: #4f5151;
  margin: 1rem 0;
  text-decoration: underline;
}
.clinic-login .card .sign-in-form-registerBtn {
  color: #468e9e;
  font-weight: bold;
  text-decoration: underline;
}

.clinic-login .reg-done-clinic h2,
.clinic-login .reg-done-clinic p {
  font-size: 2rem;
}

@media (max-width: 700px) {
  .clinic-banner h1 {
    top: 5%;
    font-size: 1.5rem;
  }

  .clinic-login .reg-done-clinic h2,
  .clinic-login .reg-done-clinic p {
    font-size: 1.5rem;
  }
}
