.error_404 {
    padding-top: 5rem;
}

.webview .error_404
{
    padding-top: 0rem;
}

.error_404 .doctors {
    width: 86%;
    margin: auto;
}

.error_404 .error_details {
    padding-top: 17%;
}

.error_404 .error_details h1 {
    color: $base-4;
    text-align: center;
    font-size: 3rem !important;
    font-weight: 900;
}

.error_404 .error_details h2 {
    color: $black;
    text-align: center;
    /* padding-top: 29%; */
    padding: 0rem 2rem;
    font-size: 1.6rem;
}

.error_404 .links_404 {
    text-align: center;
    padding: 1rem;
}

.error_404 .links_404 a {
    color: $black;
    padding: 1rem;
}

.error_404 .links_404 a:hover {
    color: $base-4;
    transition: all 150ms;
}

.subscribe_newsletter {
  padding-top: 5rem;
}

.subscribe_newsletter .subscribe_details {
  padding-top: unset;
  padding-bottom: 5rem;
}

.subscribe_newsletter .subscribe_details h1 {
  color: $base-4;
  text-align: center;
  font-size: 3rem !important;
  font-weight: 900;
}

.subscribe_newsletter .subscribe_details h2 {
  color: $black;
  text-align: center;
  padding: 0rem 2rem;
  font-size: 1.6rem;
}