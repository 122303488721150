.header-left {
  margin-left: 3rem;
  margin-right: auto;

  order: 2;
}
.header-left a.header-logo-img {
  display: flex;
}
.header-left span {
  font-weight: 900;
}
.header-right {
  margin-right: 0;
  margin-left: auto;
  order: 1;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}
.header-right a:first-of-type {
  color: #2864ad;
  font-weight: 900;
  font-size: 1.5rem;
}
.header-right a.hotline {
  padding: 0.5rem 1rem 0.5rem 2rem;
  background-color: #2864ad;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: #fff;

  font-weight: 900;
  margin-left: 2rem;
}
.header-right a.hotline img {
  padding: 0.5rem;
}
.landing-intro {
  position: relative;
  padding: 5% 8%;
}
.landing-intro:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    rgba(255, 0, 0, 0),
    rgba(69, 125, 182, 0.83)
  );
}
.landing-intro .landing-intro-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.landing-intro-cont {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row-reverse;
  align-items: flex-end;
}
.register_form_container.landing_intro_form {
  width: 35%;
  margin-left: 0;
  margin-right: auto;
  background-color: #fff;
  border-radius: 2rem;
  padding: 1.5rem;
}
.landing-intro-cont {
  position: relative;
}
.register_form_container.landing_intro_form form input,
.register_form_container.landing_intro_form form textarea,
.register_form_container.landing_intro_form form select {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 2rem;
  margin: 0.5rem 0rem;
}

.landing_intro_form .intl-tel-input {
  margin: 0.5rem 0rem;
}
.register_form_container.landing_intro_form .intl-tel-input .flag-container {
  margin: auto;
}
.register_form_container.landing_intro_form form input.submit-btn {
  background-color: #e8308a;
  margin: 0;
}
.register_form_container.landing_intro_form form input.submit-btn:hover {
  background-color: #fff;
  color: #e8308a;
  border: #e8308a;
}
.landing-intro-content {
  width: 60%;
  color: #fff;
}
.landing-intro-content h1 {
  display: flex;
  flex-flow: column;
  font-size: 4vw;
  line-height: 5vw;
}
.landing-intro-content h1 span {
  font-size: 3rem;
}
.landing-intro-content p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 1rem;
}

.landing-whyOt {
  background: linear-gradient(to right, #478e9e, #599ea5);
  /* padding: 2rem; */
  padding: 1rem;
  text-align: center;
  color: #fff;
  position: relative;
}
.landing-whyOt h2 {
  color: #fff;
  font-size: 2rem;
}
.whyot-item {
  width: 23%;
}
.landing-whyot-grid {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  width: 90%;
  margin: 2rem auto 1rem;
}
.whyot-item img {
  height: 144px;
  width: 170px;
  object-fit: scale-down;
  margin-bottom: 1rem;
}
.whyot-item p {
  width: 64%;
  margin: auto;
  font-size: 1rem;
  line-height: 2rem;
}

.landing-footer {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
  background-color: #3268a8;
  font-size: 1.5rem;
}
.landing-whyOt:after {
  content: '';
  position: absolute;
  width: 27%;
  left: 0;
  right: auto;
  bottom: -5px;
  top: auto;
  height: 9px;
  background-color: #e8308a;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 6px;
}
.landing-whyOt:before {
  content: '';
  position: absolute;
  width: 27%;
  right: 0;
  left: auto;
  top: -5px;
  height: 9px;
  background-color: #e8308a;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 6px;
}

/* start new video landing */
.landing-video-header-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 102% !important;
  width: 100%;
  object-fit: cover;
}

.flex-dir-col-jus-cen {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@font-face {
  font-family: NotoSansArabic-Bold;
  src: url(../fonts/NotoSansArabicUI-ExtraCondensedSemiBold.ttf);
}

.header-container h1 {
  font-size: 3.5vw;
  line-height: 5vw;
  /* font-family: 'NotoSansArabic-Bold', sans-serif !important; */
}

.header-container h3 {
  font-size: 2rem;
  padding-top: 1rem;
  line-height: 1.6;
}

.mt-minus-10 {
  margin-top: -4rem;
}

.best-doctobrs-video .item {
  text-align: center !important;
  padding: 1rem !important;
  background: #fff !important;
  margin: 2rem !important;
  border-radius: 2rem !important;
}

.best-doctobrs-video .best-doctobrs-video-heading {
  text-align: center;
  padding-top: 2rem;
  color: #fff;
  font-size: 2.5rem;
  position: relative;
  /* font-family: 'NotoSansArabic-Bold', sans-serif !important; */
}

.best-doctobrs-video h3 {
  color: #000 !important;
  font-size: 1rem;
}

.best-doctobrs-video .best-doctobrs-video-para-spec {
  color: #000 !important;
  position: relative;
  font-weight: bold;
  padding-bottom: 1rem;
}

.best-doctobrs-video .best-doctobrs-video-para-spec::after {
  /* content: ''; */
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #e8308a;
  bottom: 0.3rem;
  left: 24%;
}

.how-reserve-ourvideo {
  padding: 1rem 0;
}

.how-reserve-ourvideo h3 {
  color: #478e9e;
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 5rem;
}

  .how-reserve-ourvideo h4 {
    color: #478e9e;
    font-size: 2rem;
    text-align: center;
  }

  .how-reserve-ourvideo h4 span {
      border-bottom: 3px solid #e8308a;
  }

  .how-reserve-ourvideo h5 {
    color: #478e9e;
    font-size: 1.5rem;
    text-align: center;
  }

.how-reserve-ourvideo ul li {
  color: #e8308a;
  list-style-type: disc;
}

.how-reserve-ourvideo ul li span {
  color: #000;
}

#our-home-services {
  background-color: #f4f4f4;
}

  #our-home-services h3 {
    color: #478e9e;
    font-size: 2.5rem;
    text-align: center;
    padding-bottom: 2rem;
  }

  #our-home-services h4 {
    color: #478e9e;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 2rem;
  }

.service-icon {
  width: auto;
  height: 150px;
}

#yemen-landing-doctor .best-doctobrs-video-heading {
  margin-bottom: 3rem;
}

#yemen-landing-doctor .owl-nav .owl-prev {
  left: -3rem !important;
}

#yemen-landing-doctor .owl-nav .owl-next {
  right: -3rem !important;
}

#yemen-landing-doctor .owl-nav .owl-prev,
#yemen-landing-doctor .owl-nav .owl-next {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #2d94d4 !important;
  background-color: #fdf9ed !important;
}

#yemen-landing-doctor .owl-next .fa {
  position: absolute;
  top: 30%;
  right: -4rem;
  font-size: 2rem;
  color: #e93e91;
}

#yemen-landing-doctor .owl-prev .fa {
  position: absolute;
  top: 30%;
  left: -4rem;
  font-size: 2rem;
  color: #e93e91;
}

#yemen-landing-doctor.our-doctors-new .owl-next .fa {
  right: unset;
  font-size: 1rem;
}

#yemen-landing-doctor.our-doctors-new .owl-prev .fa {
  left: unset;
  font-size: 1rem;
}

#resDiv.step-form-details {
  margin-top: -6rem;
}

#diabetes-quiz .step-form-details {
  margin-top: -1rem;
}

#pregnancy-quiz #resDiv.step-form-details,
#ovulation-quiz #resDiv.step-form-details {
  margin-top: -4rem;
}

#calories-quiz #emailDiv.step-form-details,
#pregnancy-quiz #emailDiv.step-form-details,
#ovulation-quiz #emailDiv.step-form-details,
#mass-quiz #emailDiv.step-form-details,
#mass-quiz #dynamicDiv.step-form-details {
  margin-top: -2rem;
}

.speciality-card {
  border-radius: 10px;
}

.speciality-card-body {
  padding: 0.5rem;
  padding-top: 1rem;
}

#step1 {
  font-size: 1.5rem;
}

.sectionDev .dr-speciality-icon {
  width: 40px !important;
  height: auto !important;
  border-radius: unset !important;
}

.dr-categories #doctorsList .dr-img {
  width: 100%;
  height: auto !important;
}

.dr-profile-img {
  width: 120px !important;
  height: 120px !important;
}

.dr-categories img.checked-img {
  width: 15px;
  height: 15px !important;
  border-radius: unset;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

  .dr-categories img.checked-img.dr-check-img {
    width: 12px;
    height: 12px !important;
    right: 0.5rem;
  }

.typeName {
  height: 60px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials-ourvideo {
  height: 100%;
}

.testimonials-ourvideo-img-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.testimonials-ourvideo .testimonials-ourvideo-heading {
  text-align: center;
  padding-top: 2rem;
  color: #fff;
  font-size: 2.5rem;
  position: relative;
  margin-bottom: 3rem;
  /* font-family: 'NotoSansArabic-Bold', sans-serif !important; */
}

.testimonials-ourvideo .testimonials-ourvideo-heading::after {
  content: '';
  left: 41%;
  width: 19%;
  height: 3px;
  background: #fff;
  position: absolute;
  bottom: -10px;
}

.testimonials-ourvideo .item {
  background-color: #fff;
  padding: 4rem 1rem;
  border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  position: relative;
}

.testimonials-ourvideo .w-img-quotes {
  width: 50px !important;
  position: absolute;
}

.testimonials-ourvideo .w-img-quotes-right-top {
  top: -7rem;
}

.w-img-quotes-left-bottom {
  bottom: -6rem;
  left: 1rem;
  transform: rotate(180deg);
}

.testimonials-ourvideo-details {
  justify-content: flex-end;
}

.testimonials-ourvideo .testimonials-ourvideo-details img {
  width: 70px;
  height: 70px;
  border: 3px solid;
  border-radius: 50%;
}

.why-reserve-video h3 {
  color: #478e9e;
  font-size: 2.5rem;
}

.why-reserve-video h4 {
  font-size: 1.5rem;
  color: #478e9e;
}

.bg-footer {
  background-color: #262729;
}

.testimonials-ourvideo {
  height: 80vh;
}

.testimonials-ourvideo-img-bg {
  height: 80vh;
}

.our-doctors.best-doctobrs-video
  .owl-carousel
  .owl-controls
  .owl-nav
  .owl-prev {
  color: #ee568c;
}

.testimonials-ourvideo .item {
  height: 278px;
}

.yemen-copywrite {
  font-size: 1.3rem !important;
}

.fw-bold {
  font-weight: bold;
}

.open-modal {
  cursor: pointer;
}

.modal-confirm {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
}

.modal-confirm-2 {
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
}

.modalContent-confirm {
  font-size: 20px;
  background-color: #F4F4F4;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 75%;
  border-radius: 3rem;
}

.close-confirm {
  color: rgb(255, 65, 65);
  float: right;
  font-size: 40px;
  font-weight: bold;
}

.close-modal {
  color: #333;
  margin: 0;
  padding: 0;
  position: relative;
  left: 48.5%;
  top: -20px;
  float: unset;
}

.yemen-copywrite a {
  margin-left: 1rem;
}

.close-confirm:hover,
  .close-confirm:focus {
    color: #ff1010;
    cursor: pointer
}

.border-radius-lg {
    border-radius: 1rem
}

.bg-gray {
    background-color: #eee
}

.bg-main {
    background-color: #478e9e
}

.btn-main {
    background-color: #478e9e;
    border-radius: 0.5rem;
    border: 1px solid #478e9e;
    color: #ffffff;
    cursor: pointer
}

.btn-outline-main {
    color: #478e9e;
    border-radius: 0.5rem;
    border: 1px solid #478e9e;
    background-color: #ffffff
}

.active-card, #home-visits-modal .sectionDev.actived-card {
    border-radius: 1rem;
    border: solid 1px #ff3399
}

.active-card .checked-img {
    width: 16px;
    position: absolute;
    top: 16px;
    right: 16px
}

#lab-page-1 .active-card .checked-img {
    right: 32px
}

.confimation-check-icon {
    width: 100px
}

#lab-page-1 .typeName {
    height: unset
}

#home-visits-modal .checked-img {
    right: 1.75rem;
    top: 2rem
}

#pharmacy-modal .hotline {
    color: #fff;
    background: #478e9e;
    padding: 0.5rem 1rem;
    border-radius: 6rem;
    display: inline-block
}

.why-reserve-video .reserve-icons {
    width: 150px
}

footer.yemen-copywrite .facebook-icon {
    width: 30px
}

.yemen-input {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    width: 100%;
    direction: rtl
}

.method, .appointment {
    color: #478e9e;
    border: unset;
    background: unset;
    border-radius: 0.5rem;
    display: block
}

.method .fa-whatsapp {
    padding-block: 0.65rem;
    font-size: 1.5rem
}

.method .fa-paper-plane {
    font-size: 1rem;
    line-height: 2.5
}

.method .fa-phone {
    font-size: 1.25rem;
    line-height: 2
}

.method-selected, .appointment-selected {
    background-color: #478e9e;
    color: #ffffff
}

.confimation-check-icon {
  width: 100px;
}

@media (min-width: 1600px) {
  .why-reserve-video {
    margin-top: 5rem;
  }

  .our-doctors .owl-carousel .owl-item img {
    width: 150px;
    height: 150px;
  }

  .h-100 {
    height: 100% !important;
  }

  .register_form_container.landing_intro_form {
    width: 80% !important;
  }

  .landing-video-bestdoctor-img {
    height: 100%;
  }
}

.error {
  color: #ff0000
}

.iti__flag {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/img/flags.png);
}

.sectionDev {
  cursor: pointer;
}

.docDev {
  cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/img/flags@2x.png);
  }
}

.iti__country-list {
  left: 0
}

.iti {
  width: 100%;
  margin: .5rem 0;
}

.iti__selected-flag {
  padding: 0 6px 0 25px;
}

.register_form_container.landing_intro_form {
  width: 65%;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-label {
  display: inline-block;
  padding-right: 2rem;
}

[dir='rtl'] [name='phone_number_contact'] {
  direction: rtl;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check label.error {
  position: absolute;
  bottom: -1rem;
}

#yemen-landing-doctor.our-doctors .owl-carousel .owl-item img {
  width: 150px !important;
  height: 150px !important;
  margin: 1rem auto;
}

.register_form_container.landing_intro_form form input.submit-btn {
  border: none;
}

  .register_form_container.landing_intro_form form input.submit-btn:hover {
    background-color: #e73188;
    color: #fff;
    border: #e73188;
    border: none;
  }

.testimonials-ourvideo .owl-carousel .owl-controls .owl-nav .owl-prev {
  position: absolute;
  left: -3.7rem !important;
  top: 35%;
  color: #2d94d4;
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.testimonials-ourvideo .owl-carousel .owl-controls .owl-nav .owl-next {
  position: absolute;
  right: -3.7rem !important;
  top: 35%;
  color: #2d94d4;
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.yemen-slider-homepage {
  background-color: #F4F4F4;
}

  .yemen-slider-homepage .owl-carousel .owl-item img {
    max-height: 580px;
    object-fit: cover;
  }

  .yemen-slider-homepage .owl-prev {
    position: absolute;
    top: 40%;
    left: 0;
    padding: 3rem 1rem;
    color: #333;
  }

  .yemen-slider-homepage .owl-next {
    position: absolute;
    top: 40%;
    right: 0;
    padding: 3rem 1rem;
    color: #333;
  }

.yemen-testimonials-homepage,
.yemen-testimonials-homepage .testimonial .testimonial-content {
  background-color: #F4F4F4;
}

.best-doctobrs-video .item {
  height: 550px;
  display: flex;
  flex-direction: column;
}

.best-doctobrs-video .reserve-bestdr {
  border-radius: 5px;
  background-color: #f4f4f4;
  color: #ff3399;
  font-weight: bold;
  padding: 1rem 2rem;
  margin-top: auto;
}

.header-yemen {
  background-image: url("../images/videolanding/header-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 45%;
}

.dr-categories-btn {
  color: #ffffff !important;
  border-radius: 5px;
  background-color: #478e9e;
  padding: .5rem 2rem;
  border: none;
}

.queNum {
  border: unset;
  font-weight: 700;
  outline: 0;
}

.queNum:first-child {
  z-index: 3;
}

.queNum:nth-child(2) {
  z-index: 2;
}

.queNum:nth-child(3) {
  z-index: 1;
}

.step-form-details .form-actions-forward-back {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
}

.step-form-details .queNum:after {
  width: 18rem;
  top: 0.9rem;
  left: 2rem;
}

/*.queNum:nth-child(2):after, .queNum:last-child:after {
  width: 4.6rem !important;
}*/

.step-form-details .queNum:last-child::before {
  content: '';
  position: absolute;
  background: #e5e5e5;
  display: block;
  height: 5px;
  top: 0.9rem;
  left: -17.6rem;
  width: 18rem;
}

.step-form-yemen-3 .queNum:last-child::before {
  background-color: #e8308a !important;
}

.step-form-yemen-1 .queNum,
.step-form-yemen-1 .queNum::after,
.step-form-yemen-2 .queNum:last-child,
.step-form-yemen-2 .queNum:last-child::after {
  background-color: #e5e5e5 !important;
}

  .step-form-yemen-1 .queNum:first-child,
  .step-form-yemen-1 .queNum:first-child::after {
    background-color: #e8308a !important;
    color: #ffffff !important;
  }

.open-modal p {
  color: #535353;
}

.fa-angle-left {
  padding-left: 0.5rem;
}

.fa-angle-right {
  padding-right: 0.5rem;
}

#ovulation-quiz #resDiv {
  margin-bottom: -4rem;
}

.fs-1 {
  font-size: 3rem;
}

.fs-2 {
  font-size: 2.5rem;
}

#booking-appointment-modal .close-modal {
  left: unset;
}

.pregnancy-table th {
  border-left: 2px solid #e5e5e5;
  width: 60%;
}

.pregnancy-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

input[disabled], button[disabled] {
  cursor: not-allowed;
  background-color: #b3b3b3 !important;
}

.input--group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input--group > .input-field {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border-radius: 0.25rem;
}

.input--group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input--group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fa.fa-angle-left,
.fa.fa-angle-right {
  vertical-align: middle;
}

.yemen-hotline {
  width: 150px !important;
}

.webview #privacy-form {
  display: none !important;
}

[dir='ltr'] .fa-angle-left,
[dir='ltr'] .fa-angle-right {
  transform: scale(-1);
  -moz-transform: scale(-1);
}

[dir='ltr'] .step-form-details .form-actions-forward-back .queNum:after {
  right: 2rem;
  left: unset;
}

[dir='ltr'] .step-form-details .form-actions-forward-back .queNum:last-child::before {
  right: -6.6rem;
  left: unset;
}

[dir='ltr'] .dr-categories img.checked-img {
  right: unset;
  left: 0.5rem;
}

[dir='ltr'] .dr-categories img.checked-img.dr-check-img {
  right: 1rem;
}

[dir='ltr'] .fa-angle-left {
  padding-right: 0.5rem;
}

[dir='ltr'] .fa-angle-right {
  padding-left: 0.5rem;
}

[dir='ltr'] .yemen-input {
  direction: ltr;
}

[dir='ltr'] .close-modal {
  left: unset;
  right: 48.5%;
}

[dir='ltr'] #booking-appointment-modal .close-modal {
  left: 0.75rem;
}

[dir='ltr'] .form-check-label {
  padding-right: unset;
  padding-left: 2rem;
}

[dir='ltr'] .calories-table th {
  text-align: left;
}

[dir='ltr'] .calories-table tbody td {
  border-left: unset;
  border-right: 2px solid #d8d8d8;
}

[dir='ltr'] .mass-bar {
  transform: scale(-1);
  -moz-transform: scale(-1);
}

[dir='ltr'] .border-end {
  border-left: unset;
  border-right: 2px solid #d8d8d8;
}

[dir='ltr'] .text-ar-start {
  text-align: left;
}

[dir='ltr'] .pregnancy-table th {
  border-left: unset;
  border-right: 2px solid #e5e5e5;
}

[dir='ltr'] .input--group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

[dir='ltr'] .input--group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  margin-right: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

@media screen and (min-width:1599px) {
  .step-form-details .queNum:after {
    width: 21.5rem;
  }

  .step-form-details .queNum:last-child::before {
    left: -21.1rem;
    width: 21.5rem;
  }
}

@media screen and (min-width:1349px) {
  [dir='ltr'] .step-form-details .queNum:after {
    width: 6.5rem;
    z-index: 1;
  }

  [dir='ltr'] .step-form-details .queNum:last-child::before {
    right: -6.5rem;
    width: 6.5rem;
  }
}

@media(min-width:999px) and (max-width:1400px) {
  .best-doctobrs-video .item {
    height: 475px;
  }

  .best-doctobrs-video .best-doctobrs-video-para-spec {
    padding-bottom: 0;
  }

  .font-06-meduim {
    font-size: .6rem
  }
}

@media(min-width:992px) {
  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  [dir='ltr'] .text-lg-right {
    text-align: left !important;
  }

  [dir='ltr'] .text-lg-left {
    text-align: right !important;
  }
}

@media (max-width: 700px) {
  .testimonials-ourvideo .item {
    height: unset;
  }

  .header-container h1 {
    font-size: 1.5rem;
    line-height: unset;
    text-align: center;
  }

  .header-container h3 {
    text-align: center;
    font-size: 1.1rem;
    padding: 1rem 0;
  }

  .landing-video-header-img {
    height: 171vh !important;
  }

  .mt-minus-10 {
    margin-top: -1rem;
  }

  .our-doctors .owl-carousel .owl-item img {
    width: 80% !important;
  }

  .how-reserve-ourvideo .px-5 {
    padding-inline: 2rem
  }

  .how-reserve-ourvideo h3 {
    padding-block: 1rem;
    font-size: 2rem;
    line-height: 1.3;
  }

  .how-reserve-ourvideo h4 {
    font-size: 1.75rem;
  }

  .how-reserve-ourvideo ul.px-4 {
    padding-right: 1.5rem;
    padding-left: unset;
  }

  .how-reserve-ourvideo ul li {
    padding-bottom: 0.5rem;
  }

  .text-center-sm {
    text-align: center;
  }

  .dr-categories-btn {
    margin-bottom: 2rem;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }

  #our-home-services.px-5 {
    padding-inline: 1rem;
  }

  .service-icon {
    height: 90px;
  }

  .modal-confirm {
    padding-top: 3rem;
  }

  .modalContent-confirm {
    padding-inline: 1rem;
    width: 90%;
  }

  .why-reserve-video .reserve-icons {
    width: 100px;
  }

  footer.yemen-copywrite a {
    padding-inline: 0.25rem;
  }

  footer.yemen-copywrite .fs-5 {
    font-size: 0.8rem !important;
  }

  footer.yemen-copywrite .facebook-icon {
    width: 18px;
  }

  footer.yemen-copywrite .copyrights {
    font-size: 0.8rem !important;
  }

  .yemen-header-container .containerr-fluid {
    width: 100%;
    padding-inline: 0.75rem;
  }

  .yemen-header-container header {
    padding-block: 0.5rem;
  }

    .yemen-header-container header .toogle_navbar_icon {
      color: #478e9e !important;
      margin-right: 0.75rem;
      padding-inline: 0rem;
    }

  .yemen-header-container .header-logo {
    -ms-flex-order: unset !important;
    order: unset !important;
    padding-right: unset;
    padding-left: 2rem;
  }

  .yemen-header-container header .navbar {
    background-color: #478e9e;
  }

  .register_form_container.landing_intro_form {
    width: 100% !important;
  }

  #yemen-landing-doctor .best-doctobrs-video-heading {
    margin-bottom: 1rem;
  }

  .our-doctors.best-doctobrs-video .owl-carousel .owl-controls .owl-nav .owl-prev {
    left: -0.5rem !important;
  }

  .our-doctors.best-doctobrs-video .owl-carousel .owl-controls .owl-nav .owl-next {
    right: -.5rem !important;
  }

  .testimonials-ourvideo .owl-carousel .owl-controls .owl-nav .owl-prev {
    position: absolute;
    left: -0.7rem !important;
    top: 35%;
    color: #2d94d4;
    background: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .testimonials-ourvideo .owl-carousel .owl-controls .owl-nav .owl-next {
    position: absolute;
    right: -.7rem !important;
    top: 35%;
    color: #2d94d4;
    background: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .m-1-sm {
    margin: 1rem
  }

  .best-doctobrs-video .item {
    height: unset;
  }

  .header-yemen {
    height: fit-content;
    padding-block: 2rem;
  }

  .step-form-details .queNum:after {
    width: 6.5rem;
    top: 0.9rem;
    left: 1.6rem;
  }

  .step-form-details .queNum:last-child::before {
    top: 0.9rem;
    left: -6.5rem;
    width: 6.5rem;
  }

  #questionsNav .queNum:after {
    width: 1.5rem;
    top: 0.9rem;
    left: 1.6rem;
  }

  #questionsNav .queNum:last-child::before {
    top: 0.9rem;
    left: -1.5rem;
    width: 1.5rem;
  }

  #resDiv.step-form-details {
    margin-top: -7rem;
  }

  #ovulation-quiz #resDiv.step-form-details {
    margin-top: -6rem;
  }

  #pregnancy-quiz #resDiv.step-form-details {
    margin-top: -4rem;
  }

  #diabetes-quiz #resDiv.step-form-details {
    margin-top: -2rem;
  }

  .calories-select {
    font-size: 0.8rem;
  }

  [dir='ltr'] .how-reserve-ourvideo ul.px-4 {
    padding-inline: 2rem;
  }

  [dir='ltr'] .step-form-yemen-1 .queNum {
    padding: 0.3rem 0.8rem !important;
  }

  [dir='ltr'] .header-container h3 {
    font-size: 1rem;
    padding: 0
  }

  [dir='ltr'] .yemen-header-container .header-logo {
    padding-right: 2rem;
    padding-left: unset;
  }

  [dir='ltr'] .yemen-header-container header .toogle_navbar_icon {
    margin-right: unset;
    margin-left: 0.75rem;
  }
}

@media (max-width: 480px) {
  .best-doctobrs-video {
    height: 780px;
  }

  .landing-video-bestdoctor-img {
    height: 123vh;
  }

  .landing-video-header-img {
    height: 159vh !important;
  }

  .best-doctobrs-video .best-doctobrs-video-heading {
    font-size: 1.5rem;
  }

  .landing-video-bestdoctor-img {
    height: 100%;
  }

  .how-reserve-ourvideo .px-5 {
    padding-inline: 1.5rem
  }

  .how-reserve-ourvideo h3 {
    font-size: 1.5rem;
  }

  .testimonials-ourvideo .testimonials-ourvideo-heading {
    font-size: 1.5rem;
  }

  .f-01-sm {
    font-size: 1rem !important;
  }

  .testimonials-ourvideo {
    height: 530px;
  }

  .testimonials-ourvideo-img-bg {
    height: 100%;
  }

  .yemen-copywrite {
    font-size: 0.7rem !important;
  }

  .yemen-slider-homepage .owl-carousel .owl-item img {
    max-height: 400px;
  }
}

@media(max-width:360px) {
  .how-reserve-ourvideo .px-5 {
    padding-inline: 0.5rem !important;
  }

  .step-form-details .queNum:after {
    width: 3.4rem;
    top: 0.9rem;
    left: 1.6rem;
  }

  .step-form-details .queNum:last-child::before {
    top: 0.9rem;
    left: -3.4rem;
    width: 3.4rem;
  }

  #step1 .px-4 {
    padding-inline: 0.5rem !important;
  }

  .yemen-slider-homepage .owl-carousel .owl-item img {
    max-height: 300px;
  }

  [dir='ltr'] .step-form-details .form-actions-forward-back .queNum:last-child::before {
    right: -3.4rem;
    left: unset;
  }
}