.intro {
  padding: 0rem;
  position: relative;
  -webkit-transition-property: all;
  -webkit-transition-duration: 2s;
  -webkit-transition-timing-function: ease;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: ease;

  margin: 0rem 0rem 1.5rem 0rem;
  transition: background 1s linear;
}

.intro .owl-carousel .owl-item img {
  max-height: 580px;
  height: 100% !important;
}

#rotating-item-wrapper {
  position: relative;
  width: 100%;
}

.rotating-item {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

#homepage {
  width: 100%;
  margin: auto;
  padding: 0rem;
}

#homePage.main_container.loggedIn {
  padding-top: 5rem;
}

.hiddenLayer {
  padding: 0rem;
  z-index: -1111;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.hiddenLayer img {
  width: 100%;
  height: 100%;
}

.intro .app_icons_group {
  width: 20%;
  float: left;
  padding: 1rem 0rem;
  bottom: 0rem;
  top: auto;
  position: absolute;
  left: 2%;
  right: auto;
}

.intro .app_icons_group .app_button {
  padding: 0rem 0.3rem;
}

.intro .intro_button_group .intro_button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  color: #000;
  border-radius: 4px;
  display: inline-block;
  font-weight: 900;
}

.intro .intro_button_group .btn_with_bg {
  background-color: $base-1;
  color: $base-1-alt;
}

.intro .intro_button_group .intro_button:hover {
  background-color: $base-1-alt;
  color: $base-1;
  transition: all 150ms;
}

.intro .intro_heading_right {
  width: 58%;
  display: inline-block;
  padding: 1rem 0rem;
}

.intro .headign_h1 {
  display: block;
  font-weight: bolder;
  font-size: 2.9rem;
  padding: 0rem;
  line-height: 3rem;
  padding-bottom: 1rem;
  color: #fff;
}

.intro .headign_h1 span {
  font-size: 1.5rem;
  font-weight: 300;
  display: block;
  width: 60%;
  line-height: 2rem;
  padding: 0.5rem 0rem;
}

.intro .intro_heading {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(71, 120, 177, 0.7);
}

.intro .heading_inner_container {
  width: 90%;
  margin: auto;
  float: none;
}

.intro .intro_form_container {
  position: relative;
  z-index: 99;
  right: 3%;
  margin: 0 0 2rem;
}

.app_icons_group .soon_app_title {
  padding: 0.5rem;
  color: #fff;
}

.intro .intro_mobile_div {
  display: none;
}

.intro .app_button_container {
  width: 100%;
  margin: auto;
}

.intro.loggedin .heading_inner_container {
  width: 100%;
  padding: 0 2rem;
  float: none;
}

.hiddenLoader {
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  background-size: cover;
  resize: none;
  background-repeat: no-repeat;
  z-index: -11;
}

.intro-slider-nav a {
  width: 30px;
  height: 30px;
  display: inline;
  cursor: pointer;
}

.intro-slider-nav a img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* .intro .owl-controls .owl-nav {
    display: none;
} */

.intro .owl-carousel .owl-controls .owl-nav .owl-prev {
  position: absolute;
  top: 40%;
  left: 0;
  /* background: #494949; */
  padding: 3rem 1rem;
  color: #fff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.intro .owl-carousel .owl-controls .owl-nav .owl-next {
  position: absolute;
  top: 40%;
  right: 0;
  /* background: #599ea5; */
  padding: 3rem 1rem;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

@media (max-width: 700px) {
  .intro .owl-carousel .owl-controls .owl-nav .owl-prev {
    padding: 1rem;
  }
  .intro .owl-carousel .owl-controls .owl-nav .owl-next {
    padding: 1rem;
  }

  .stores-icons-hp-slider {
    width: 100px !important;
  }
}

.intro .owl-controls .owl-dots {
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro .owl-controls .owl-dot span {
  width: 30px;
  height: 9px;
  border-radius: 4.5px;
  display: inline-block;
  background-color: #dfdfdf;
  margin: 0.5rem;
}

.intro .owl-controls .owl-dot.active span {
  background-color: #599ea5;
  width: 30px;
  height: 9px;
  border-radius: 4.5px;
}

.intro .intro-slider {
  position: absolute;
  bottom: auto;
  top: 69%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  z-index: 3;
  right: 0;
  margin: auto;
  width: 60%;
  text-align: center;
}

.intro-slider h2 {
  padding: 0rem 3rem;
  color: #478e9e;
}

.intro-slider-nav {
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  top: 31%;
  width: 90%;
  margin: auto;
}

.intro-slider-nav-next {
  position: absolute;
  right: 0;
}

.intro-slider-nav-prev {
  position: absolute;
  left: 0;
}

.homepage .intro ul {
  position: relative;
  padding: 2rem 0px;
  width: auto;
  display: inline-block;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
}

.homepage .intro ul li {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  position: relative;
}

.homepage .intro ul li:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 96%;
  height: 1px;
  background-color: #e6e3e3;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0.7;
}

.homepage .intro ul li:last-of-type:after {
  content: none;
}

.homepage .intro ul li.active a {
  opacity: 1;
}

.homepage .intro ul li.active {
  border-right: 7px solid #e8308a;
  color: #e8308a;
}

.homepage .intro .slider-words {
  position: absolute;
  top: 20%;
  right: 15%;
  font-size: 2.7rem;
  color: #fff;
  font-weight: bold;
  line-height: 1.5;
}

[dir='ltr'] .homepage .intro .slider-words {
  left: 15%;
  right: unset;
  text-align: left;
  direction: ltr;
}

.ver-hor-center-flex-homepage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1000px) {
  .intro .owl-controls .owl-dots {
    top: 90%;
  }
  .home-medical-form .form_container {
    /* position: absolute; */
    width: 80%;
    z-index: 99;
    /* bottom: -6rem; */
  }

  .homepage .intro .slider-words {
    right: 10%;
  }

  [dir='ltr'] .homepage .intro .slider-words {
    left: 10%;
  }

  .landing-whyOt:before {
    z-index: 9;
  }
}
