/**/
/*pallete*/

$white: #fff;
$gray: #eee;
$gray_border: #ddd;
$black: #000;
$dark-gray: #bcbcbc;
$dark2-gray: #7e7e7e;
$dark3-gray: #f4f4f4;
$very-dark-gray: #333;
$border: #e3e3e3;

$base-1: #3366ff;
$base-1-alt: #fff;
$base-2: #33ccff;
$base-2-alt: #fff;
$base-3: #6699ff;
$base-3-alt: #fff;
$base-4: #e8308a;
$base-4-alt: #333;
$base-5: #ff6699;
$base-5-alt: #e7e6e1;
$base-6: #3398cc;
$base-6-alt: #fff;
$base-7: #f4f4f4;
$base-fb: #3b5998;
