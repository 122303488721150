.help {
    padding-top: 5rem;
    background-color: rgb(248, 248, 248);
}
.webview .help
{
    padding-top: 0rem;
}

.help .section_title {
    text-align: start;
}

.common_question_link {
    display: block;
    padding: 0.5rem;
    font-size: 1.1rem;
    color: #333;
    transition: all 200ms;
}

.questions_container.active .common_question_link {
    color:#3f9fcf;
}

.common_question_link:hover {
    color: #3f9fcf;
    transition: all 200ms;
}

.common_question_link:hover .question_mark_icon i {
    background-color: #3f9fcf;
    transition: all 200ms;
}

.help .user_common_questions {
    border-bottom: 1px solid #ddd;
}

.help .common_questions {
    padding: 1rem 0rem;
}

.help .help_questions_inner_container {
    padding: 1rem 7rem;
}

.help .help_questions_inner_container:first-of-type {
    border-bottom: 13px solid #eee;
}

.help .question_mark_icon {
    float: right;
    display: inline-block;
    padding: 0.5rem 0rem;
}

.help .question {
    float: right;
    display: inline-block;
    padding: 0.5rem;
    font-weight: 900;
}

.help .question_mark_icon i {
    font-size: 1.7rem;
    background-color: #333;
    padding: 0.3rem 0.5rem;
    vertical-align: middle;
    color: #fff;
}

.help .question_div {
    border-bottom: 1px solid #eee;
}

.help .question_div:last-of-type {
    border-bottom: none;
}

.help .answer_container {
    padding: 0.5rem 2rem;
    box-shadow: 0px 2px 4px #dadada;
    height: auto;
    background-color: #f1f1f1;
}