.step-form {
  background: #f5f5f5;
  color: #333;
  height: auto;
  padding: 1rem 0;
}

.test-icon {
  width: 44px;
  height: auto;
}

.step-form__container {
  border-radius: 25px;
  border: solid 2px #e5e5e5;
  background-color: #ffffff;
  padding: 3rem;
}

.step-form--mainTitle {
  font-size: 1.5rem;
  margin-right: 1rem;
  font-weight: bold;
}

.gender-icon {
  width: 80px;
  height: auto;
}

.step-form .align-center-hor-ver {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-form .start-test,
.result-table-form .result-submit-email button,
.calc-again {
  background-color: #599ea5;
  padding: 0.5rem 2rem;
  color: #fff;
  border: navajowhite;
  font-weight: bold;
  border-radius: 25px;
  outline: none;
}

.step-form .step-form-details .step-form-number div {
  height: 40px;
  display: inline-block;
  width: calc((100% - 18.1px) / 10);
  background: rgba(0, 0, 0, 0.15);
  margin-left: 2px;
  text-align: center;
  font-size: 1rem;
  color: #445a67;
  line-height: 2.2;
}

.step-form .step-form-details .step-form-number div.active {
  background: #599ea5;
}

.step-form .step-form-details .question-title {
  margin: 2rem 0;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

.step-form .step-form-details .question-answer {
  display: flex;
  justify-content: center;
}

.step-form .step-form-details .question-answer div {
  display: inline-block;
  height: 46px;
  margin: 0 0 10px 10px;
  background: #e5e5e5;
  color: #333;
  text-align: center;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 2.8;
  width: 25%;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
}

.step-form .step-form-details .question-answer div:hover {
  background: #599ea5;
  color: #fff;
}

.step-form .step-form-details .checkmark-stepform-icon {
  width: 20px;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.step-form .step-form-details .slide {
  background: #fff;
  padding: 2rem;
  border-radius: 25px;
  border: solid 2px #e5e5e5;
}

.step-form .step-form-details .form-actions-forward-back {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.step-form .step-form-details .form-actions-forward-back button {
  padding: 0.5rem 3.2rem;
  border: navajowhite;
  font-weight: bold;
  background-color: #599ea5;
  color: #fff;
  border-radius: 25px;
  outline: none;
}

.result-table-form .average-result {
  /* padding: 1rem 0; */
  padding-bottom: 0;
}

.average-result p {
  font-size: 1.5rem;
}

.result-table-form .average-result span {
  font-size: 1.5rem;
  color: #599ea5;
}

.sugary-details .red-alert-table {
  background-color: #d74e6a;
}
.sugary-details .green-alert-table {
  background-color: #009732;
}
.sugary-details .orange-alert-table {
  background-color: #ecab14;
}

.red-alert-text {
  color: #d74e6a !important;
}
.green-alert-text {
  color: #009732 !important;
}
.orange-alert-text {
  color: #ecab14 !important;
}

.sugary-details .others-th {
  color: #fff;
  font-size: 1.5rem;
}

.result-table-form .result-submit-email {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.result-table-form .form-group {
  margin: 1rem 0;
}

.result-table-form .form-check {
  margin-bottom: 1rem;
}

#form-actions-forward-back-id {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#form-actions-forward-back-id button {
  margin: 0 2rem;
  position: relative;
  cursor: pointer;
  outline: none;
}

#form-actions-forward-back-id button img {
  position: absolute;
}

.donwload-banner-quiz {
  display: flex;
  justify-content: space-between;
}

.queNum {
  padding: 0.25rem 0.85rem !important;
  border-radius: 50% !important;
  background-color: #e8308a !important;
  position: relative;
}

.queNum:after {
  content: '';
  position: absolute;
  background: #e8308a;
  display: block;
  width: 6rem;
  height: 5px;
  top: 1.3rem;
  left: 2.4rem;
}

.result-table-form {
  border-radius: 25px;
  border: solid 2px #e5e5e5;
  background-color: #ffffff;
  padding: 2rem;
}

#sentMsg {
  font-size: 1.5rem;
}

#email {
  border-radius: 5px;
  border: solid 1px #d8d8d8;
  background-color: #f5f5f5;
  padding: 0.5rem 2rem;
}

.test-result-container {
  /* margin: 0.5rem; */
  padding: 0.5rem 2rem;
  border: solid 1px #d8d8d8;
}

@media (min-width: 768px) and (max-width: 999px) {
  .queNum:after {
    width: 2.8rem;
  }
}

@media (max-width: 767px) {
  .step-form .step-form-details .form-actions-forward-back button {
    padding: 0.5rem 2.5rem;
  }
  .step-form .step-form-details .question-answer div {
    display: block;
    height: unset;
    width: 100%;
    margin: unset;
    margin-bottom: 10px;
  }

  .donwload-banner-quiz {
    flex-direction: column;
    align-items: center;
  }

  .step-form .step-form-details .slide {
    padding: 1rem;
  }

  .queNum {
    padding: 0.1rem 0.6rem !important;
  }

  .queNum:after {
    width: 1rem;
    height: 5px;
    top: 0.8rem;
    left: 1.6rem;
  }

  .flex-dir-clumn-sm {
    flex-direction: column;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.calories-input {
  padding: 0.5rem;
  height: 50px;
  margin-inline: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #d8d8d8;
  background-color: #f5f5f5;
  width: 80px;
  font-size: 1.2rem;
}

.calories-radio-input {
  height: 20px;
  width: 20px;
}

.calories-select {
  padding: 0.5rem 1rem;
  height: 50px;
  margin-inline: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #d8d8d8;
  background-color: #f5f5f5;
  font-size: 1.2rem;
}

#email.calories-email {
  padding: 0.5rem 1rem;
  height: 50px;
  margin-inline: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #d8d8d8;
  background-color: #f5f5f5;
  font-size: 1.2rem;
}

.fs-3 {
  font-size: 2rem;
}

.fs-4 {
  font-size: 1.5rem;
}

.error-msg {
  color: #ce0a0a;
  font-size: 1rem;
}

#emailSubscribeMsg {
  color: forestgreen;
}

#caloriesResult {
  color: #599ea5;
}

#download-app .play-store-icon,
#download-app .app-store-icon {
  width: 160px;
  height: 50px;
}

.calories-table {
  border-spacing: unset;
}

.calories-table thead {
  background-color: #599ea5;
  color: #fff;
}

.calories-table th {
  text-align: right;
}

.calories-table th, .calories-table td {
  padding: 1rem 1.5rem;
}

.calories-table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.calories-table tbody td {
  border-left: 2px solid #d8d8d8;
}

.calories-table tbody td:last-child {
  border-left: unset;
}

.border-end {
  border-left: 2px solid #d8d8d8;
}

#mass-pointer {
  position: relative;
}

#mass-pointer-data {
  color: #f3f3f3;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-30%, -65%);
  -moz-transform: translate(-40%, -65%);
}

#mass-pointer-icon {
  width: 60px;
  height: auto;
}

.mass-bar {
  height: 20px;
  padding-inline: 0px;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  border-left: 2px solid #dddddd;
}

#thin {
  background-image: linear-gradient(to right, #159986, #3299fb);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#normal {
  background-image: linear-gradient(to right, #33a533, #159986);
}

#fat-1 {
  background-image: linear-gradient(to right, #d3cc33, #33a533);
}

#fat-2 {
  background-image: linear-gradient(to right, #ef8b20, #d3cc33);
}

#fat-3 {
  background-image: linear-gradient(to right, #d92106, #ef8b20);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: unset;
}

.date-select {
  padding: 0.5rem;
  height: 50px;
  margin-inline: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #d8d8d8;
  background-color: #f5f5f5;
  width: 150px;
  font-size: 1.2rem;
}

.text-gray {
  color: #7f7f7f;
}

.text-female {
  color: #de609e;
}

.bg-female {
  background-color: #de609e !important;
}

.date-card {
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
}

#menstruation-date {
  border-top: 3px solid #b36b8a;
}

#menstruation-day {
  color: #b36b8a;
}

#fertility-start-date, #fertility-end-date {
  border-top: 3px solid #37c0be;
}

#fertility-start-day, #fertility-end-day {
  color: #37c0be;
}

#ovulation-date {
  border-top: 3px solid #197d7c;
}

#ovulation-day {
  color: #197d7c;
}

#pregnancy-test-date {
  border-top: 3px solid #742044;
}

#pregnancy-test-day {
  color: #742044;
}

#birth-date {
  border-top: 3px solid #092c40;
}

#birth-day {
  color: #092c40;
}

#menstruation-day, #fertility-start-day, #fertility-end-day,
#ovulation-day, #pregnancy-test-day, #birth-day {
  margin-bottom: -1rem;
}

.e-icon-xxl {
  width: 150px;
}

.e-icon-xl {
  width: 125px;
  transform: rotate(180deg);
}

.e-icon-l {
  width: 100px;
  transform: rotate(-90deg);
}

.e-icon-md {
  width: 75px;
  transform: rotate(90deg);
}

.e-icon-sm {
  width: 50px;
  transform: rotate(180deg);
}

.e-icon-xs {
  width: 25px;
  transform: rotate(90deg);
}

.arrow-block-icon {
  width: 25px;
}

.arrow-inline-icon {
  width: 30px;
}

.arrow-icon-btn {
  background-color: #599ea5;
  border: unset;
  padding: 0.5rem;
}

.main-text {
  color: #599ea5; 
}
.step-form-yemen {
  border-radius: 10px;
  background-color: #f4f4f4;
}

  .step-form-yemen .actived-card {
    border: solid 1px #ff3399;
    border-radius: 10px;
  }

.rounded-circle {
  border-radius: 50%;
}

@media (max-width: 500px) {
  .step-form__container {
    padding: 1rem;
  }

  .calories-select {
    width: 100%;
  }

  .result-table-form {
    padding: 0.5rem;
  }

  #calories-result-container li {
    text-align: right !important;
  }

  #calories-result-container li,
  .calories-table {
    font-size: 0.8rem !important;
  }

  .calories-table th, .calories-table td {
    padding: 0.5rem;
  }

  #download-app .fs-3 {
    font-size: 1.5rem;
  }

  #download-app .play-store-icon,
  #download-app .app-store-icon {
    width: 120px;
    height: 37.5px;
  }

  #mass-results {
    font-size: 0.8rem;
  }

  #mass-pointer-icon {
    width: 40px;
  }

  #mass-pointer-data {
    font-size: 0.8rem;
    transform: translate(-30%, -35%);
    -moz-transform: translate(-55%, -35%);
  }

  .arrow-block-icon {
    width: 15px;
  }

  .arrow-inline-icon {
    width: 18px;
  }

  .step-form .step-form-details .question-answer {
    display: block;
  }
}

.webview .mobile-version {
  display: none !important;
}