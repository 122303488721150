.clinics-listing .submit-clinic-search {
  background-color: #599ea5;
  color: #fff;
  padding: 0.5rem 6rem;
  border-radius: 5px;
}

.listing-clinic-container {
  background-color: #f4f4f4;
  padding: 3rem 0;
}

.clinics-listing form label {
  color: #343434;
}

.listing-clinic-container .clinics-sorting-paginate select {
  width: 7rem;
  padding: 0.3rem;
}

.listing-clinic-container .card {
  border-radius: 20px;
  border: solid 1px #dedede;
  margin-top: 2rem;
}

.listing-clinic-container .clinic-image-details .clinic-img {
  border-radius: 100px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  object-position: top;
}

.listing-clinic-container .clinic-image-details h3 {
  font-weight: bold;
  color: #236170;
  font-size: 2rem;
}

.listing-clinic-container .clinic-image-details .rating-clinic-stars {
  color: #ffb501;
  font-size: 1.5rem;
}

.listing-clinic-container .clinic-image-details .clinic-spec span {
  border-radius: 30px;
  border: solid 1px #7f7f7f;
  background-color: #fff;
  padding: 0.3rem 1rem;
  display: inline-block;
  margin-top: 0.5rem;
}

.listing-clinic-container .clinic-image-details .clinic-details-btn {
  border-radius: 30px;
  background-color: #478e9e;
  padding: 0.5rem 3rem;
  color: #fff;
}

.mr-3rem {
  margin-right: 3rem;
}

@media (max-width: 800px) {
  .listing-clinic-container .clinics-sorting-paginate {
    flex-direction: column;
    align-items: center;
  }

  .listing-clinic-container .clinic-image-details .cost-waiting {
    margin: 1rem 0;
  }

  .listing-clinic-container .card {
    text-align: center;
  }

  .listing-clinic-container .clinic-image-details .d-flex {
    flex-direction: column;
  }

  .listing-clinic-container .clinic-image-details .mr-3rem {
    margin-right: unset;
  }
}
