.joinus-container-forms {
  padding: 3rem 0;
}

.joinus-container-forms .tab {
  padding: 14px 20px;
  border: 1px solid #f7f7f7;
  margin-left: 1.5rem;
}

.joinus-container-forms .tab:hover,
.joinus-container-forms .tab.active {
  background-color: #478e9e;
  color: #fff;
}

.joinus-container-forms .tab-content input,
.joinus-container-forms .tab-content select {
  border-radius: 100px;
  margin: 0.5rem 0;
  font-size: 1.3rem;
}

#joinus-page .tab-content-6 h2 {
  color: #4f5151;
  font-size: 2rem;
}

#joinus-page .tab-content-6 label {
  display: block;
}

#joinus-page .tab-content-6 select {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 100px !important;
  margin: 0.5rem 0rem !important;
  font-size: 1.2rem !important;
}

.joinus-container-forms .tab {
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  background-color: #f4f4f4;
  padding: 0.5rem 1rem;
}

#joinus-page #map-canvas {
  height: 100%;
  width: 100%;
}

#joinus-page .clinic-reg-submitBtn {
  border-radius: 34px;
  border: solid 1px #f6f6f6;
  background-color: #236170;
  padding: 0.1rem 3.5rem;
  font-size: 1.5rem;
  margin-top: 2rem;
  color: #fff;
}

#joinus-page .intl-tel-input .flag-container {
  top: 1rem;
}

@media (max-width: 800px) {
  .joinus-container-forms .tab {
    padding: 0.5rem 0.3rem;
    font-size: 0.8rem;
    display: block;
    margin: 1rem 0.5rem;
    text-align: center;
  }

  #joinus-page #map-canvas {
    height: 500px;
  }
}
