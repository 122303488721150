.visit-details_page {
    padding-top: 5rem;
    background-image: url('../images/bg_01.jpg');
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgb(28, 119, 175)), url('../images/bg_01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.visit-details_page .section_title {
    text-align: start;
    padding: 0.5rem 0rem 1rem;
    border-bottom: 1px solid #eee;
    margin: 0 0 1rem;
}

.visit-details_page .main_inner_container {
    width: 80%;
    margin: auto;
    display: block;
    padding: 0rem 10px;
    background-color: #fff;
}

.visit-details_page .row {
    border-bottom: none;
}

.visit_details_header {
    padding: 1rem 0rem;
    border-bottom: 1px solid #eee;
}

.visit_user_details h2,
.brief_item h3 {
    font-size: inherit;
    font-weight: 300;
    display: inline-table;
}

.visit_user_details h2 span,
.brief_item h2 {
    padding-left: 1rem;
    font-weight: 900;
    color: #1774d0;
    display: inline-block;
    width: 230px;
    font-size: 0.9em;
}

.visit_user_details h2 span {
    width: auto !important;
}

.brief_item {
    border-bottom: 1px solid #eee;
    line-height: 3rem;
}

.brief_item:last-of-type {
    border: none;
}

.download_perscription {
    padding: 2rem;
}

.download_perscription a {
    margin: auto;
    display: block;
    float: none;
    max-width: 255px;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: $base-1;
    color: $base-1-alt;
    border: 3px solid $base-1;
    text-align: center;
}

.download_perscription a:hover {
    color: $base-1;
    background-color: $base-1-alt;
    transition: all 200ms;
}

.download_perscription a i {
    vertical-align: middle;
    padding: 0rem 0.5rem;
    margin-top: 4px;
}

.visit-details_page .visit_brief.active,
.visit-details_page .msg_history_body.active {
    display: block;
}

.visit-details_page .visit_brief,
.visit-details_page .msg_history_body {
    display: none;
}

.visit_details_header.removeBorder {
    border: none;
}

.visit-details_page .visit_user_details h2 {
    font-size: 1rem !important;
    display: block;
}

.visit-details_page .visit_details {
    padding: 1rem 0rem;
}

.visit-details_page .visit_msg {
    padding: 1.5rem 0rem;
}

.visit-details_page .visit_msg a {
    text-align: center;
}

.visit-details_page .visit_brief_body {
    padding: 1rem;
}

.visit-details_page .visit_brief_body p {
    color: #7f7f7f;
}

.visit-details_page .visit_brief_body p span {
    float: right;
    font-weight: 900;
    padding-left: 0.3rem;
}

.profile_links.wrap {
    padding: 0rem !important;
}

.closeChat {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.8rem;
    font-size: 19px;
    background-color: #fff;
    color: #3398cc;
}