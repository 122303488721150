.person_details {
  position: relative;
  margin: 0 0 1rem;
}

.visits_section .person_img_container,
.visit_details_header .person_img_container {
  width: 78px !important;
  height: 78px !important;
  border-radius: 50px !important;
  overflow: hidden !important;
  display: inline-block !important;
  position: relative !important;
  margin: 0rem !important;
  border: 1px solid #9099a3;
}
.visits_section .person_img_container img {
  height: 100%;
  object-fit: cover;
}
.person_name {
  display: inline-block;
  position: absolute;
  top: 23%;
  padding-right: 1rem;
}

.visit_details {
  padding: 1.45rem 0rem;
}

.row .person_name {
  top: 1rem;
}
.row .person_name h2 {
  font-size: 1.3rem !important;
  line-height: 2rem;
}

.row .visit_start_time {
  display: inline-block;
  position: absolute;
  top: 3rem;
  padding-right: 1rem;
  font-size: 0.8em;
  color: #999;
}

.row .visit_start_time span {
  width: 100%;
  display: block;
}

.visit_details .cancledVisit {
  color: #f00 !important;
}

.visit_details .doneVisit {
  color: #29c34a;
}

.show_msg {
  padding: 1.45rem 0rem;
}

.section_header_link {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

/* .row {
    padding: 0.5rem 0rem;
    border-bottom: 1px solid #eee;
} */

.visit_msg {
  padding: 1.45rem 0rem;
  text-align: center;
}

.visit_msg.active a {
  color: #f00;
}
