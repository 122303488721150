@media (max-width: 768px) {
  .header-left {
    margin-left: 0rem;
    margin-right: auto;
  }
  .header-left span {
    font-size: 0.8rem;
  }
  .header-right {
    flex-flow: column;
    justify-content: stretch;
    align-items: end;
  }
  .landing-intro-cont {
    flex-flow: column;
  }
  .register_form_container.landing_intro_form {
    width: 100%;
    order: 2;
  }
  .landing-intro-content {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    order: 1;
  }
  .landing-intro-content h1 {
    font-size: 2rem;
    line-height: 3.5rem;
  }
  .landing-intro-content h1 span {
    font-size: inherit;
  }
  .landing-intro-content p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-top: 1rem;
  }
  .landing-whyOt {
    padding: 0;
  }
  .landing-whyOt h2 {
    font-size: 2rem;
    margin: 2rem 0;
  }
  .landing-whyot-grid {
    flex-flow: column;
  }
  .whyot-item {
    width: 100%;
    margin-bottom: 2rem;
  }
  .whyot-item p {
    width: 100%;
    margin: auto;
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .landing-footer {
    font-size: 1rem;
  }
  .ourdoctor-main.landing-doctors h2.section_title {
    font-size: 2rem !important;
  }
  .webview[dir='ltr'] .contact_us_page .title {
    text-align: right;
  }
  .row .visit_start_time {
    position: relative;
    top: 0;
    padding: 0rem;
    padding-top: 52px;
    padding-right: 7px;
  }
  .home-medical-form {
    padding: 0;
    border: none !important;
    border-radius: 0 !important;
    margin: 0;
  }
  .home-medical-form .bg-img {
    display: none;
  }
  .home-medical-form .contact_form_header {
    width: 100%;
    font-size: 1.5rem;
    color: #000 !important;
  }
  .home-medical-form .form_container {
    width: 100% !important;
    border-radius: 0 !important;
  }
  .visit-details_page .visit_start_time {
    display: block !important;
    padding: 0rem !important;
  }
  .features {
    padding: 1rem;
  }
  .intro-slider h2 {
    padding: 0rem 2rem;
    font-size: 1rem;
  }
  .features .section_title {
    padding: 0rem;
  }
  .feature {
    padding: 1rem 0rem;
  }
  .feature_item span {
    font-size: 2.5rem;
    width: 73px;
    height: 73px;
  }
  .feature_item span i {
    line-height: 4.5rem;
  }
  .feature_item h2 {
    font-size: 1rem;
    padding: 1rem 0rem;
  }
  .noDegree_notify h2 {
    width: 100%;
    display: block;
    text-align: center;
    float: none;
  }
  .noDegree_notify a {
    display: inherit;
    text-align: center;
    margin: auto;
    float: none;
    width: 87%;
  }
  header .filled:after {
    content: none;
    display: none;
  }
  .paymentTable:first-of-type {
    margin-top: 0rem;
  }
  .profile_page .profileName {
    line-height: 4.5rem;
  }
  .specialized_details {
    padding: 1rem 0rem;
  }
  .tabletGrid {
    max-height: 341px;
  }
  .specialityName .remove span {
    padding-top: 5px !important;
  }
  .table_row_container {
    padding: 0;
  }
  .table_row {
    display: block;
    margin: auto;
    float: none !important;
  }
  .hiddenTitle {
    display: block;
    font-size: 1.4rem !important;
    color: #fff;
    padding: 0.5rem;
  }
  .paymentTable_heading {
    display: none;
  }
  .paymentTable_container h3 {
    font-size: 1rem;
    padding: 0.3rem 0.1rem;
  }
  .chatImg {
    width: 40px;
    height: 40px;
  }
  .user_msg .msg_container,
  .doctor_msg .msg_container {
    max-width: 82%;
  }
  .msg_container:after {
    top: 15% !important;
  }
  .payment_btn {
    width: 100% !important;
    margin: 10px 0rem !important;
  }
  .hiddenLabel {
    display: inline;
  }
  .blockDiv {
    background-color: transparent !important;
  }
  .actions {
    padding: 0px !important;
  }
  .details .remove span,
  .details .add span {
    margin: 0px 15px;
  }
  .table_row .notification_item h3 {
    width: 100%;
    text-align: center;
    line-height: initial;
  }
  .table_row .notification_item i {
    line-height: inherit;
    vertical-align: middle;
    float: none;
  }
  .blockDiv .table_row {
    display: inline-block !important;
    float: right !important;
    margin: 0 !important;
  }
  .blockDiv .table_row:last-of-type {
    padding: 10px !important;
    background-color: #eee;
  }
  #notification_list {
    width: 100% !important;
  }
  .step_title {
    padding-top: 3rem !important;
  }
  .top_profile_details .name_img i {
    right: 13rem;
  }
  .articles_section_container .articles_box {
    padding: 2rem 0rem 1rem 0rem;
  }
  .how_it_work_user .prev_step,
  .how_it_work_doctor .prev_step {
    margin: 0.5rem 0rem;
    width: 45%;
    display: inline-block;
    float: right;
    display: inline-block;
    position: relative;
    border-radius: 35px;
    right: 2%;
    height: 39px;
  }
  .how_it_work_user .next_step,
  .how_it_work_doctor .next_step {
    margin: 0.5rem 0rem;
    position: relative;
    display: inline-block;
    float: left;
    width: 45%;
    left: 2%;
    border-radius: 35px;
    height: 39px;
  }
  .next_step i,
  .prev_step i {
    line-height: 2.5rem;
    font-size: 0.8rem;
  }
  .profile_link {
    padding: 0.5rem 1rem;
    float: none;
    text-align: center;
    width: 45%;
  }
  span.profile_link {
    margin-top: 10px;
    width: 94%;
  }
  .header_inner_container {
    padding: 0rem 0.5rem;
  }
  #qualification_form.show,
  #experiences_form.show,
  #specialized_form.show,
  .experienceEditDiv.show,
  .qualificationEditDiv.show {
    padding: 1rem !important;
    overflow: scroll;
    height: 100%;
  }
  .modal_title {
    font-size: 1.3rem !important;
  }
  .paymentDetails.profile_page .visit_details {
    text-align: center;
    display: block;
    margin: auto;
    float: none;
  }
  .paymentDetails .person_name {
    padding: 0rem;
    display: block;
  }
  .paymentDetails .payment_summary {
    padding: 1rem;
  }
  .paymentDetails .payment_summary .inner_container h2 {
    font-size: 1rem;
  }
  .has-children li {
    padding: 0.2rem 0rem;
  }
  .cd-accordion-menu label,
  .cd-accordion-menu a {
    font-size: 0.9rem;
  }
  .help .help_questions_inner_container {
    padding: 1rem;
  }
  header {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: none;
    padding: 0rem;
  }
  .intro .intro_button_group .intro_button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .testimo_box_container .owl-next {
    top: 24%;
    right: 0%;
  }
  .testimo_box_container .owl-prev {
    top: 24%;
    left: 0%;
  }
  .header-contact {
    font-size: 1rem;
  }
  .how_it_work_section,
  .testimonial_section_container,
  .articles_section_container {
    padding: 1rem !important;
  }
  .blogListing .articles_section_container .articles_box {
    width: 100%;
  }
  header .toogle_navbar_icon {
    font-size: 1.5rem;
    order: 3;
  }
  [dir='rtl'] header .toogle_navbar_icon {
    order: 3;
  }
  [dir='rtl'] .header-logo {
    order: 1;
  }
  [dir='rtl'] .header-contact {
    order: 2;
  }
  .articles_box_container {
    padding: 0rem;
  }
  .section_title {
    padding: 1rem 0rem;
    font-size: 1.3rem !important;
  }
  .testimo_box_container .owl-prev,
  .articles_box_container .owl-prev,
  .testimo_box_container .owl-next,
  .articles_box_container .owl-next {
    top: -23px;
  }
  .articles_section_container .section_title {
    padding: 1rem 0rem 2rem 1rem;
  }
  .login_form,
  .resetPassword_form,
  .register_form,
  .newPassword_form {
    width: 95%;
  }
  .login_page,
  .resetPassword_page,
  .register_page {
    padding: 1rem;
  }
  .login_page .title,
  .resetPassword_page .title,
  .register_page .title {
    font-size: 1.2rem;
  }
  .login_page .links a,
  .resetPassword_page .links a,
  .register_page .links a {
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: start;
  }
  .login_page .links,
  .resetPassword_page .links,
  .register_page .links {
    width: 100%;
    padding: 0;
  }
  .login_page .app_icons_group,
  .resetPassword_page .app_icons_group,
  .register_page .app_icons_group {
    padding: 0;
  }
  .login_page .app_icons_group,
  .resetPassword_page .app_icons_group,
  .register_page .app_icons_group {
    width: 100%;
  }
  .login_page .app_icons_group img,
  .register_page .app_icons_group img,
  .resetPassword_page .app_icons_group img {
    width: 100%;
  }
  .resetSuccess_page h1 {
    font-size: 1.2rem;
  }
  .resetSuccess_page h3 {
    font-size: 1rem;
  }
  .help .common_questions .grid {
    padding: 0.5rem 0.1rem;
  }
  .help .question_mark_icon {
    padding: 0.5rem 0rem;
    max-width: 10%;
  }
  .help .question_mark_icon i {
    font-size: 1.2rem;
    padding: 0.2rem 0.4rem;
  }
  .help .question {
    padding: 0rem 0.5rem;
    max-width: 90%;
    font-size: 0.8rem;
  }
  .help .answer_container {
    font-size: 0.8rem;
  }
  .error_404 .error_details {
    padding-top: 0%;
  }
  .error_404 .error_details h2 {
    font-size: 1.2rem;
  }
  .genericPage .article_title h1 {
    font-size: 1.4rem;
    padding: 0.5rem;
  }
  /* EDIT PROFILE */
  #top_profile .name_img span {
    bottom: 19.5rem;
    right: 6rem;
    width: 30px;
    height: 30px;
  }
  .editProfile_page .top_profile_details .name_img label a {
    display: inline-block !important;
    top: 17.9rem;
    position: absolute;
    right: 14rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .editProfile_page #top_profile .name_img span {
    bottom: 17.5rem;
    right: 6rem !important;
    width: 30px;
    height: 30px;
  }
  #top_profile .name_img i {
    font-size: 1rem;
  }
  #top_profile .name_img h4 {
    font-size: 1rem;
    line-height: 2rem !important;
    padding: 0.5rem 0rem !important;
  }
  #top_profile .name_img h5 {
    font-size: 0.8rem;
    line-height: 2rem !important;
  }
  #top_profile .address_phone h5 {
    font-size: 1rem;
    line-height: 3rem;
    float: right;
    color: #7e7e7e;
  }
  .paymentDetails .profile_payments {
    margin-bottom: 0.5rem;
  }
  .profile_page #top_profile {
    width: 91%;
  }
  .editProfile_page #bottom_profile,
  #top_profile,
  #certificates_profile,
  #experience_profile,
  #specializedIn_profile,
  .editProfile_page #top_profile {
    padding: 0 0.5rem;
    margin-top: 1rem;
    margin: 0 auto !important;
    width: 100% !important;
  }
  .editProfile_page #bottom_profile .title_btn,
  .editProfile_page #bottom_profile .accordion {
    padding: 1rem;
  }
  .editProfile_page #bottom_profile .save_edits {
    margin-top: 0.3rem;
  }
  .editProfile_page .profil_links a {
    text-align: start;
    padding: 0.5rem;
  }
  /* END OF EDIT PROFILE */
  .profile_page .row h2 {
    font-size: 1rem;
    text-align: center;
  }
  .profile_page .row .person_name {
    padding-right: 0.2rem;
  }
  .profile_page .row a {
    font-size: 1.1rem;
    text-align: center;
    display: block;
    background-color: #eee;
  }
  .profile_page .section_header_title {
    font-size: 1rem;
  }
  .no_data_container {
    font-size: 1rem;
  }
  .profile_page .section_header_link {
    font-size: 0.8rem;
  }
  .profile_page .show_msg {
    padding: 0.1rem 0rem;
    text-align: center;
  }
  .profile_page .visit_details {
    padding: 0.1rem 0rem;
    text-align: center;
  }
  .profile_visits,
  .profile_payments,
  .profile_reviews {
    width: 95%;
  }
  #top_profile .title,
  #certificates_profile .certificates_header .title,
  #experience_profile .experience_header .title,
  #specializedIn_profile .specializedIn_header .title {
    font-size: 1.4rem;
    padding: 0.6rem 0;
    text-align: center;
  }
  .editProfile_page #specializedIn_profile .save_cancel {
    padding: 1rem 0rem;
  }
  .imageHolder .removeAttachment {
    top: 2.4rem;
    right: 0;
    padding: 0.2rem;
  }
  .details {
    padding: 1rem;
  }
  .experience_title,
  .editProfile_page .qulaification_name,
  #specializedIn_profile .experience_details .details h4,
  .qualificationName {
    font-size: 1.1rem;
  }
  .specialized_details .details {
    padding: 0rem !important;
  }
  .editProfile_page .person_img_container {
    display: block;
    float: none;
  }
  .details .remove span {
    font-size: 1rem !important;
    padding: 0.5rem !important;
  }
  #experience_profile .experience_details .add span i,
  #certificates_profile .certificates_details .add span i,
  .specialityName {
    font-size: 0.8rem !important;
  }
  .details_inner_container h3 {
    font-size: 1rem;
  }
  .editProfile_page label {
    padding: 0.2rem 0rem;
  }
  .imageHolder {
    width: auto !important;
    height: auto !important;
  }
  .imageHolder img {
    max-width: 54px;
    max-height: 55px;
  }
  .top_profile_details .name_img {
    width: 100%;
    margin: auto;
  }
  .top_profile_details .name_img img {
    width: 60px;
    height: 60px;
    margin: auto;
    display: block;
    float: none;
  }
  #showAddQualificationButton,
  #showAddExperienceButton,
  #showAddSpecialized {
    padding: 0.4rem 0.5rem !important;
    font-size: 0.8rem;
    margin-top: 8px;
  }
  .profile_page .edit_profile_link {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
  .visits_section .visit_msg {
    margin: auto;
    float: none !important;
    height: auto;
    display: block;
    clear: both;
    background-color: #eee;
    padding: 0rem;
    margin-bottom: 0.3rem;
  }
  .genericPage .img_container {
    max-height: 231px;
  }
  .profile_page .profile_header_container {
    padding: 0rem 0.5rem;
  }
  .visit_msg {
    padding: 0.6rem 0rem;
    text-align: center;
  }
  .person_img_container {
    width: 62px;
    height: 62px;
    border-radius: 50px;
  }
  .person_details {
    margin: auto;
    display: block;
    float: none !important;
    width: 100% !important;
  }
  .show_msg a {
    width: 100% !important;
  }
  .visit-details_page .main_inner_container {
    padding: 0rem 2rem;
  }
  .download_perscription a {
    font-size: 1rem;
  }
  .download_perscription a i {
    margin-top: 6px;
  }
  .user_msg .msg_container,
  .doctor_msg .msg_container {
    padding: 0.5rem;
  }
  .user_msg,
  .doctor_msg {
    font-size: 0.9rem;
  }
  .msg_history_body .download_perscription a {
    max-width: 194px;
    font-size: 1rem;
    padding: 0.1rem;
  }
  .visit_user_details h2,
  .brief_item h3 {
    font-size: 1.1rem;
  }
  .visit_user_details h2 span,
  .brief_item h2 {
    font-size: 1rem;
    padding-left: 0.5rem;
  }
  .visit-details_page .visit_msg {
    padding: 1.2rem 0rem;
  }
  .person_name h2 {
    font-size: 1.3rem;
  }
  .testimo_box .testo_details {
    text-align: center;
  }
  .intro .headign_h1 span {
    width: 100%;
  }
  .how_it_work .app_icons_group .soon_app_title {
    font-size: 0.8rem;
  }
  .how_it_work .app_icons_group {
    width: 100%;
  }
  footer .important_links ul {
    width: 100%;
  }
  footer .important_links ul li {
    width: 100%;
    float: none;
    margin: auto;
    display: block;
    padding: 0.1rem 0rem;
    border: none;
  }
  footer .social_media_footer ul {
    float: none;
  }
  .how_it_work_user .prev_step,
  .how_it_work_doctor .prev_step,
  .how_it_work_user .next_step,
  .how_it_work_doctor .next_step {
    border-color: #fff;
    background-color: #fff;
    color: #000;
  }
  .how_it_work_user .prev_step:hover,
  .how_it_work_doctor .prev_step:hover,
  .how_it_work_user .next_step:hover,
  .how_it_work_doctor .next_step:hover {
    border-color: #fff;
    background-color: #fff;
    color: #000;
  }
  .msg_history_body .inner_container {
    padding: 0rem 1rem;
    padding-bottom: 1rem;
  }
  .visit_details {
    font-size: 0.8rem;
  }
  .intro .app_button_container {
    width: 90%;
  }
  .details_text {
    width: 100%;
    max-width: 100%;
  }
  .imgs_row {
    max-width: 100% !important;
    width: 100%;
  }
  .details_inner_container .img_container {
    width: 100px;
    height: 100px;
    padding: 0.2rem !important;
  }
  .sitemap-inner-container {
    padding: 1rem 0.5rem;
  }
  .resetPassword_btn input {
    margin: auto;
    float: none;
    display: block;
  }
  .backToRegister {
    position: relative;
    width: 100%;
    float: none;
  }
  .genericPage .inner_container {
    padding-bottom: 0rem;
  }
  .genericPage .end_article_details {
    padding: 1.3rem;
  }
  .artcle_title {
    font-size: 1rem;
    max-height: 37px;
  }
  .article_details p {
    max-height: 74px;
    font-size: 0.8rem;
  }
  .step_title h4 {
    font-size: 0.8rem;
  }
  .contact_us_title {
    text-align: start;
    padding: 0rem;
  }
  .submit-btn {
    margin: auto;
    float: none;
  }
  .how_it_works_page .how_it_work {
    padding: 1.5rem 0rem;
  }
  .help .common_questions {
    padding: 0rem;
  }
  .breadCrumbs {
    display: none;
  }
  header .logo {
    padding-top: 22px;
  }
  .notification_general {
    font-size: 13px;
    text-align: center;
    width: 85%;
    padding-right: 17px;
  }
  .profile_notifiacation span {
    font-size: 1.3rem;
  }
  .notification_general a {
    width: 100%;
    display: block;
    text-align: center;
  }
  #homePage .left_form.IsUser .form_btn_container {
    width: 100% !important;
  }

  .homepage .intro .slider-words {
    font-size: 1rem;
  }

  .header-contact .hotline {
    background: #fff;
  }
}

@media (max-width: 350px) {
  .editProfile_page #bottom_profile .save_edits {
    margin-top: 0rem;
  }
  #bottom_profile h2 {
    font-size: 1rem;
  }
  .resetSuccess_page h1 {
    padding: 2rem 0;
    padding-bottom: 1rem;
  }
  .login_form form .fb_btn a i {
    font-size: 1.8rem;
    height: 2.5rem;
  }
  .login_form form .fb_btn a {
    font-size: 0.8rem;
  }
  .register_form form .fb_btn a i {
    width: 2rem;
    height: 2.3rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .register_form form .fb_btn a {
    font-size: 0.7rem;
  }
  #homePage .intro .headign_h1 {
    font-size: 9vw;
  }
}
