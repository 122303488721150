@media print
{
    * , h2 , h3
    {
        color: #000; 
    }
    body{
        border: 2px solid #000;
    }
 .breadCrumbs  , footer , .printPayment , .paymentTable_heading , .navbar , .notification
{
    display: none !important;
}
header .logo
{
    width: 335px;
    max-width: 100%;
}
.paymentTable_row
{
    padding: 0.5rem;
    border-bottom: 1px solid #000;
}

.hiddenLabel
{
    display: inline;
}
.hiddenTitle 
{
    display: block;
    color: #000;
    font-size: 1.7rem !important;
    text-align: center;
    text-decoration: underline;
}

.payment_mainContainer   
{
    display: block;
}
header{
    height: auto;
    max-width: 99%;
}
.profile_page
{
    padding-top: 7rem;
}
.visit_msg 
{
    display: none;
}

}