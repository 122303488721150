.register_page .title{
    color:$black;
    padding: 1rem;
    text-align: start;
    font-size: 1.5rem;
}
.register_page {
    padding: 0rem;    

    width: 100%;
}
.register_form {
    width: 40%;
    float: none;
    margin: auto;
    display: block;
}
.register_page .links {
    float: none;
    margin: auto;
    display: block;
    width: 50%;
}
.register_page .app_icons_group {
    width: 30%;
    float: none;
    margin: auto;
    display: block;
}
.register_form form input{
    width: 100%; 
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: #f6f6f6;
    border: none;
    border: 1px solid #e3e3e3;
}
.register_form form .login_btn input{
    background-color: $base-6;
    color: $white;
}
.register_page .links{
    padding: 1rem;
}
.register_page .links a{
    padding: 0.5rem;
    font-size: 1rem;
    color: $black;
    display: inline-block;
    float: right;
    text-align: center;
}
.register_page .links a:hover{
    color: $base-1;
    transition: all 150ms;
}
.register_page .app_icons_group {
    padding: 1rem 0.5rem;

}
.register_form form .fb_btn a {
    display: block;
    background-color: $base-fb;
    color: $white;
    width: 100%;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e3e3e3;
    text-align: center;
    position: relative;
}
.register_form form .fb_btn a i {
    color: $white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 2.9rem;
    background: rgba(0,0,0,.25);
    line-height: 3rem;
    font-size: 2rem;
}
