.reservations-listing {
  padding: 3rem 0;
  background-color: #f4f4f4;
}

.reservations-listing .search-reservationList input {
  border-radius: 100px;
  background-color: #fff !important;
}
.reservations-listing .search-reservationList .search-reservation-btn {
  border-radius: 41px;
  background-color: #236170;
  color: #fff !important;
  border: 2px solid transparent;
  transition: all 150ms;
  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem !important;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.reservations-listing .card {
  border-radius: 20px;
  border: solid 1px #dedede;
  margin-top: 2rem;
}

.reservations-listing .reservation-img {
  border-radius: 100px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  object-position: top;
}

.reservations-listing .reservation-more-details h3 {
  color: #236170;
  font-size: 2rem;
}

.reservations-listing .reservation-more-details h4 {
  color: #4f5151;
  font-size: 1.3rem;
}

.reservations-listing .rating-clinicDetails-stars {
  color: #ffb501;
  font-size: 1.5rem;
}

.reservations-listing .reservation-more-details .reservation-listing-date i {
  color: #236170;
  font-size: 1.5rem;
}

.reservations-listing .reservation-more-details .reservation-listing-date span,
.reservations-listing .reservation-more-details .reservation-listing-time {
  color: #4f5151;
  font-size: 1.3rem;
}

.reservations-listing .btns-reservation-listing-action {
  border-radius: 41px;
  background-color: #236170;
  color: #4f5151;
  border: 2px solid transparent;
  transition: all 150ms;
  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
}

.reservations-listing .btns-reservation-listing-action.pending {
  background-color: #ffc220;
}

.reservations-listing .btns-reservation-listing-action.confirmed {
  background-color: #94f3e0;
}

.reservations-listing .btns-reservation-listing-action.detailed {
  background-color: #478e9e;
  color: #fff;
}

#reservation-details-model.modal {
  max-width: 60%;
  text-align: right;
  background-color: #fff;
  border-radius: 48px;
  box-shadow: 0 3px 30px 0 #000;
  border: solid 1px #dedede;
}

#reservation-details-model .blue-color-modal {
  color: #236170;
}

#reservation-details-model .btn-upload-contactus {
  margin-top: 1rem;
  padding: 0.3rem 3rem;
}

/* rating vote reservation */
.reservation-details-model-rating .fa-star {
  color: #d3d3d3;
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.reservation-details-model-rating .fa-star.active,
.reservation-details-model-rating .fa-star.active-hover {
  color: #ffb501;
  font-size: 1.5rem;
}

.reservation-details-model-rating .fa-star-o {
  color: #d3d3d3;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .reservations-listing .reservation-more-details h3 {
    font-size: 1.5rem;
  }

  .reservation-image-details .d-flex {
    flex-direction: column;
  }

  .reservation-image-details .mr-3rem {
    margin-right: 0;
  }

  .modal-profile {
    margin: unset;
  }
}

@-moz-document url-prefix() {
  [dir='rtl'] .reservations-listing [class*='col'] {
    float: unset !important;
  }
}
