section#customers {
    padding-bottom: 5rem;
}
section#customers h2 {
    padding: 5rem 0rem 3rem;
}
section#customers .our-customers {
    max-width: 1200;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
section#customers .our-customers .customer {
    flex: 0 0 25%;
    margin-bottom: 1rem;
}