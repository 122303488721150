.clinic-profile {
  background-color: #f4f4f4;
  padding-top: 20px;
}

.clinic-profile .header {
  font-weight: 800 !important;
  font-size: 1.5rem !important;
}

.clinic-profile .medium-f {
  font-weight: 800 !important;
  font-size: 1rem !important;
}

.clinic-profile .small-f {
  font-size: 0.75rem !important;
}

.clinic-profile .mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.clinic-profile .my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.clinic-profile .my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.clinic-profile .my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.clinic-profile .mt-15 {
  margin-top: 15px;
}

.clinic-profile .align-items-center {
  align-items: center;
}

.clinic-profile .mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.clinic-profile .px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.clinic-profile .py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.clinic-profile .py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.clinic-profile .no-padding {
  padding: 0px;
}
.clinic-profile .text-center {
  text-align: center;
}

.clinic-profile .text-end {
  text-align: end;
}
.clinic-profile .primary-color {
  color: #236170;
}
.clinic-profile .booking-dates {
  background-color: #ec1b98;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
}

.clinic-profile .rounded {
  border-radius: 50%;
  border: solid 1px #dedede;
  height: 200px;
}

.clinic-profile .dark-grey {
  color: #4f5151;
}

.clinic-profile .light-grey {
  color: #dedede;
}

.clinic-profile .ltr {
  direction: ltr;
}

.clinic-profile .clinic-card {
  background-color: #fff;
  border-radius: 15px;
  border: solid 1px #dedede;
  padding: 30px;
}
.clinic-profile .main-img {
  position: relative;
}
.clinic-profile #map {
  width: 100% !important;
  height: 260px !important;
}

.clinic-profile .location-img {
  width: 15px;
}

.clinic-profile .clinic-details {
  background-color: #236170;
  color: #fff;
  border-radius: 5px;
  padding: 5px 2px;
  white-space: nowrap;
}

.clinic-profile .edit-btn {
  color: #fff;
  border: solid 1px #ec1b98 !important;
  border-radius: 5px;
  background-color: #ec1b98;
  padding: 0 1rem;
  text-align: end;
}

.clinic-profile .add-new-service {
  background-color: #236170;
  color: #fff;
  padding: 4px 12px;
  border-radius: 20px;
}

.clinic-profile .tag-btn {
  border: solid 1px #236170;
  color: #236170;
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 3px 20px;
  margin: 5px 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.clinic-profile .delete-btn {
  border: none;
  background-color: #f8f8f8;
  cursor: pointer;
}

.clinic-profile .pink-color {
  color: #ec1b98;
}

.modal-edit .rounded {
  border-radius: 50%;
  border: solid 1px #dedede;
  height: 140px;
  cursor: auto;
}

.modal-edit .upload-img {
  width: 50%;
  margin: 0 auto;
}

.modal-edit .img-to-upload {
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.modal-edit .img-upload-button {
  position: absolute;
  bottom: 23px;
  right: 11px;
  color: #fff;
  font-size: 16px;
  background: #236170;
  display: block;
  border-radius: 50%;
  padding: 9px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.modal-edit .my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal-edit .mb-10 {
  margin-bottom: 10px;
}

.modal-edit .label_modal {
  display: block;
}

.modal-edit .input_modal {
  border: solid 1px #ababab !important;
  width: 100%;
  border-radius: 5px !important;
  margin: 5px 0;
}

.modal-edit .text-area {
  border: solid 1px #ababab !important;
  width: 100%;
  resize: none !important;
  border-radius: 5px !important;
  margin: 5px 0;
}

.modal-edit .edit-btn {
  background-color: #236170;
  color: #fff;
  border-radius: 5px;
  border: solid 1px #236170;
}
.modal-edit .w-100 {
  width: 100%;
}

.modal-edit .add-service-btn {
  background-color: #236170;
  color: #fff;
  border-radius: 20px;
  border: solid 1px #236170;
  padding: 2px 10px;
}
.modal-edit .mt-30 {
  margin-top: 30px;
}

.modal-edit .text-end {
  text-align: end;
}

.swiper-button-prev,
.swiper-button-next {
  background: #f1f1f1;
  font-size: 11px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.swiper-button-prev {
  left: -42px;
}
.swiper-button-next {
  right: -42px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 18px !important;
  color: #236170 !important;
  font-weight: 900 !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 18px !important;
  color: #236170 !important;
  font-weight: 900 !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide-thumb-active {
  border: solid 3px #236170 !important;
  /* border-radius: 32px !important; */
}

.swiper-slide img {
  display: block;
  width: 70%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 28px; */
}
.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  border-radius: 30px !important;
}

.mySwiper2 {
  height: 60%;
  width: 95%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.choose_file {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  border: solid 1px #236170;
  padding: 3px 70px 3px 70px;
  color: #fff;
  margin-top: 2px;
  background: #236170;
}
.choose_file input[type='file'] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.delete-clinic-img {
  position: relative;
  background-color: unset !important;
  top: 1rem;
  right: 2rem;
  z-index: 999;
}

[dir="ltr"] .delete-clinic-img {
  right: unset !important;
  left: 2rem;
}

@media only screen and (max-width: 575px) {
  .text-center-small {
    text-align: center !important;
  }
  .modal-edit .img-to-upload {
    width: 100%;
  }
  .half-img {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

  .mySwiper2 {
    width: 100% !important;
  }

  .choose_file {
    padding-inline: 1.5rem !important;
  }

  .choose_file:first-child {
    margin-left: 0.5rem;
  }
}
