.new-profile {
  background-color: #f4f4f4;
  padding-bottom: 20px;
}
.new-profile .primary-color {
  color: #236170;
}
.new-profile .py {
  padding: 0.5rem 0;
}
.new-profile .px {
  padding: 0 0.5rem;
}
.new-profile .subTitle {
  color: #4f5151;
}

.new-profile .header {
  padding: 1rem 0;
  color: #343434;
  font-size: 1.5rem;
  font-weight: 800;
}
.new-profile .icons {
  width: 16px;
  height: 22px;
}

.new-profile .sm-icon {
  width: 8px;
  height: 12px;
}
.new-profile .person-detail {
  background-color: #fff;
  padding: 3rem 0;
}

.new-profile .profile-picture {
  border-radius: 50%;
}

.new-profile .person-name {
  font-weight: 800;
  font-size: 25px;
}

.new-profile .edit-btn {
  color: #478e9e;
  border: solid 1px #478e9e !important;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.25rem 1rem;
  text-align: end;
}

.new-profile .show-all {
  text-align: end;
}

.new-profile .show-all a {
  white-space: nowrap;
}
.new-profile .show-all a span {
  margin: 0 10px;
}

.new-profile .card {
  background-color: #ffff;
  border: solid 1px #dedede;
  margin: 10px 0;
  padding: 25px 20px;
}

.new-profile .img {
  border-radius: 50%;
}
.new-profile .confirm-btn {
  background-color: #ffc220;
  color: #4f5151;
  border-radius: 30px;
  padding: 0px 35px;
}

.new-profile .details-btn {
  background-color: #478e9e;
  padding: 0px 21px;
  color: #fff;
  border-radius: 30px;
}

.new-profile .attend-btn {
  background-color: #94f3e0;
  border-radius: 30px;
  color: #4f5151;
  padding: 0px 26px;
}

.new-profile .notattend-btn {
  background-color: #858585;
  color: #ffffff;
  padding: 0px 42px;
  border-radius: 30px;
}

.new-profile .fa-calendar-o {
  color: #236170;
}
/* MODAL */
.blocker {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.modal-profile {
  max-width: 670px !important;
  padding: 20px 50px;
  margin: 50px;
}
.modal-profile a.close-modal {
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/profile/Icon_ionic-ios-close-circle-outline.svg');
}

.modal-profile .img {
  border-radius: 50%;
}

.modal-profile .primary-color {
  color: #236170;
}

.modal-profile .header {
  font-weight: 800;
  font-size: 1.5rem;
}

.modal-profile .icons {
  width: 16px;
  height: 22px;
}

.modal-profile .subTitle {
  color: #4f5151;
}

.modal-profile .done-btn {
  background-color: #94f3e0;
  color: #4f5151;
  border-radius: 30px;
  padding: 0 30px;
  border: solid 1px #94f3e0;
}

.modal-profile .border-bottom {
  border-bottom: solid 1px #dedede;
  width: 100%;
  padding: 10px 0;
}

.modal-profile .pb {
  padding-bottom: 20px;
}

.modal-profile .fa-calendar-o {
  color: #236170;
}

.modal-profile .px {
  padding: 0 0.5rem;
}
.modal-profile .padding {
  padding: 30px;
}
.modal-profile .medium {
  font-size: 20px;
}

.modal-profile .text-area {
  border: solid 1px #ababab !important;
  width: 100%;
  resize: none !important;
  border-radius: 5px !important;
}
.modal-profile .comment-btn {
  color: #fff;
  background-color: #478e9e;
  border-radius: 5px;
  padding: 2px 35px;
  border: solid 1px #478e9e;
}
.modal-profile .confirm-btn {
  background-color: #ffc220;
  color: #4f5151;
  border-radius: 30px;
  padding: 0px 35px;
}

::placeholder {
  color: #a2a2a2;
  padding: 2px 5px;
}
/* GLOBAL CLASSES */
.w-100 {
  width: 100%;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.mt-11 {
  margin-top: 11px;
}

.m-5 {
  margin: 5px;
}

.marked-star {
  color: #ffc220;
  margin-left: 2px;
  margin-right: 2px;
}
.unmarked-star {
  color: #ababab;
  margin-left: 2px;
  margin-right: 2px;
}
.margin-y {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-small-y {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .text-end {
    text-align: start;
  }
}

@-moz-document url-prefix() {
  [dir='rtl'] .person-detail [class*='col'] {
    float: unset !important;
  }
}
