.top_profile_details {
  padding: 1rem 0.5rem;
}

.top_profile_details .name_img h4,
.top_profile_details .name_img h5 {
  display: block;
  position: relative;
}

.top_profile_details .name_img h4 {
  color: $black;
  line-height: 6rem;
  font-size: 1.1rem;
}

.top_profile_details .name_img h5 {
  color: $dark2-gray;
  line-height: 0;
  font-size: 1.1rem;
}

.top_profile_details .name_img img {
  float: right;
  height: 120px;
  width: 120px;
  margin: 0 0 0 1rem;
  border-radius: 100px;
  overflow: hidden;
  object-fit: cover;
}

.top_profile_details .name_img i {
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem;
  position: absolute;
  left: -5rem;
  right: 0;
  bottom: 0;
  width: 2rem;
  height: 2rem;
  margin: auto;
  z-index: 999;
  background: #e8308a;
  border-radius: 50%;
}

.top_profile_details .address_phone h5 {
  font-size: 1.1rem;
  line-height: 3em;
  float: right;
  color: $dark2-gray;
}

.top_profile_details .address_phone h5 i {
  padding: 0 0.5rem;
  color: $dark-gray;
}

.top_profile_details .address_phone .phone span {
  display: inline-block;
  direction: ltr;
}

.top_profile_details .name_img {
  position: relative;
}

.top_profile_details .visit_details {
  padding: 0rem !important;
}

.top_profile_details .profileName {
  font-size: 1.3rem;
}
