.editProfile_page {
  padding: 5rem 0;
  padding-bottom: 2rem;
  width: 100%;
  /* background-image: url('../images/bg_01.jpg'); */
  background-color: #478e9e;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.editProfile_page .details .img_container img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border: 1px solid #eee;
}

.editProfile_page .person_img_container {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ddd;
  margin: 1rem auto 0;
  display: block;
  float: right;
}

.editProfile_page .person_img_container img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  cursor: pointer;
}

.editProfile_page form {
  margin-bottom: 0rem;
}

#top_profile,
#certificates_profile,
#experience_profile,
#specializedIn_profile {
  padding-bottom: 0;
  background-color: transparent;
  width: 86.9%;
  margin: auto;
}

#top_profile .title,
#certificates_profile .certificates_header .title,
#experience_profile .experience_header .title,
#specializedIn_profile .specializedIn_header .title {
  font-weight: 900;
  color: #0d4884;
}

#certificates_profile .certificates_header .title,
#experience_profile .experience_header .title,
#specializedIn_profile .specializedIn_header .title {
  color: #fff;
}

.profil_links a {
  text-align: center;
  padding: 1rem;
  float: right;
  color: $dark2-gray;
}

.profil_links a.active {
  border-bottom: 3px solid $base-4;
  color: $very-dark-gray;
}

#bottom_profile {
  padding: 0 3.5rem;
  background: $base-7;
}

#bottom_profile .title_btn,
#bottom_profile .accordion {
  padding: 1rem 2rem;
}

#bottom_profile .save_edits {
  padding: 0.1rem 1rem;
  background: $base-1;
  color: $white;
  border: 1px solid $base-1;
  border-radius: 4px;
}

#bottom_profile .save_edits:hover {
  background: $base-3;
  border: 1px solid $base-3;
  cursor: pointer;
}

#bottom_profile h2 {
  color: $black;
}

#bottom_profile h3.accordion_container_header {
  position: relative;
  background: $white;
  display: inline-block;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

#bottom_profile h3.accordion_container_header i.fa-chevron-down {
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: $dark-gray;
}

#bottom_profile h3.accordion_container_header i.fa-check {
  line-height: 2.2rem;
  color: $base-1;
}

.accordion_container div.content {
  background-color: #ccc;
  padding: 10px;
  display: none;
}

#bottom_profile .accordion_container_header span.title {
  color: $very-dark-gray;
  padding: 0 1rem;
}

.accordion .content {
  display: block;
}

.accordion .content .content_container {
  display: block;
  clear: both;
  padding: 1rem;
  background-color: $gray;
}

.accordion .content .content_container .title {
  color: $very-dark-gray;
  padding: 0.5rem 0;
}

.accordion .content form input {
  width: 97%;
  margin: 0.5rem 0rem;
  padding: 0.2rem 1rem;
  background-color: $white;
  border: none;
  border: 1px solid #e3e3e3;
}

.content_container .subSpeciality_header {
  border-bottom: 1px solid #e3e3e3;
}

.content_container .subSpeciality_header span {
  color: $dark2-gray;
}

.content_container .subSpeciality_header h4 {
  display: inline-block;
  padding: 0 0.5rem;
  color: $black;
}

.subSpeciality_header_img {
  display: block;
  clear: both;
  overflow: hidden;
  padding: 0.5rem 0;
}

.subSpeciality_header_img img {
  float: right;
  height: 70px;
  width: 70px;
  margin: 0 0 0 1rem;
  overflow: hidden;
}

.subSpeciality_header_img h5 {
  line-height: 2rem;
  display: block;
  color: $very-dark-gray;
}

.subSpeciality_footer .thumbnail .fa-file-image-o {
  font-size: 3rem;
  color: $dark-gray;
}

.subSpeciality_footer label {
  display: block;
  overflow: hidden;
}

.subSpeciality_footer .thumbnail span {
  padding: 1rem;
  display: inline-block;
  color: $dark2-gray;
}

.subSpeciality_footer .thumbnail input.choose {
  position: relative;
  left: 9rem;
  visibility: hidden;
}

.subSpeciality_footer .thumbnail input.chooseNew {
  position: relative;
  left: 7rem;
  visibility: hidden;
}

.subSpeciality_footer .thumbnail #blah {
  display: inline-block;
  float: right;
  height: 60px;
  width: 60px;
  object-fit: cover;
  z-index: 1;
}

.subSpeciality_footer .thumbnail #blahNew {
  display: inline-block;
  float: right;
  height: 60px;
  width: 60px;
  object-fit: cover;
  z-index: 1;
}

.subSpeciality_footer .thumbnail .upload_new_file {
  display: block;
  padding: 1rem 0;
  width: 100%;
  color: $base-1;
}

.subSpeciality_footer .thumbnail .upload_new_file i {
  padding: 0 0.5rem;
}

.subSpeciality_footer .thumbnail .upload_new_file:hover {
  text-decoration: underline;
  cursor: pointer;
  color: $base-2;
}

.subSpeciality_footer .thumbnail .remove {
  display: none;
}

.subSpeciality_footer .thumbnail .upload_btn {
  padding: 0rem 2rem;
  border-radius: 4px;
  background: $base-6;
  border: 1px solid $base-6;
  color: #fff;
  margin: 0 0 0 2rem;
}

.subSpeciality_footer .thumbnail .upload_btn:hover {
  background: $base-1;
  border: 1px solid $base-1;
  cursor: pointer;
}

/*form .datepicker {
    width:48.4% !important;
    float: right;
    display: block;
}*/

form .dateTo {
  margin-left: 3rem;
}

.subSpeciality_header.specializedIn {
  border-bottom: none;
}

.specializedIn div {
  border-bottom: 1px solid #e3e3e3;
  padding: 0.5rem 0;
}

.addSpecialty {
  position: absolute;
  left: 10rem;
  padding: 0.8rem 0;
  color: $dark2-gray;
}

.addSpecialty:hover {
  text-decoration: underline;
  color: $base-1;
}

.addSpecialty i {
  padding: 0 0.5rem;
}

.intl-tel-input.allow-dropdown .flag-container {
  right: auto;
  left: 0rem !important;
}

.intl-tel-input .selected-flag .iti-flag {
  left: 0.5rem;
}

.intl-tel-input .country-list {
  left: 0;
}

.editProfile_page .picker__holder {
  max-width: 300px;
  top: 0rem;
  right: -3rem;
  left: 0rem;
}

.editProfile_page .top_profile_details .name_img label a {
  display: none !important;
  color: $dark2-gray;
}

.editProfile_page .top_profile_details .name_img label h5,
.editProfile_page .top_profile_details .name_img label a {
  display: inline-block;
  position: relative;
  right: 11rem;
  top: -1rem;
}

.editProfile_page .top_profile_details .name_img span {
  right: 11.5rem;
}

.editProfile_page .top_profile_details .thumbnail input.choose {
  top: 0rem;
  position: absolute;
  height: 100%;
  right: 0;
  opacity: 0;
  left: 0;
  z-index: 999999999999999;
  margin: auto;
  width: 100%;
  height: 148px;
}

.editProfile_page .top_profile_details .content {
  padding: 1rem 0;
}

.editProfile_page input,
.editProfile_page select,
.editProfile_page textarea {
  width: 100%;
  margin: 0.5rem 0rem;
  padding: 0.4rem 1rem;
  background-color: #f3f3f3;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
}

.editProfile_page select {
  margin-bottom: 0;
}

.editProfile_page .intl-tel-input {
  margin: 0.5rem 0rem;
}

.editProfile_page .qulaification_name {
  float: right;
}

.editProfile_page .qualification_ref_number {
  padding: 0rem 3rem;
}

.editProfile_page input:focus,
.editProfile_page textarea:focus,
.editProfile_page select:focus {
  background-color: $gray;
  border: 1px solid $border;
}

.editProfile_page label {
  width: 100%;
  display: block;
}

.editProfile_page .save_cancel {
  text-align: end;
}

.editProfile_page .save_cancel .save {
  padding: 0.2rem 1.5rem;
  background: #3366ff;
  color: #fff;
  border: 1px solid #3366ff;
  border-radius: 4px;
}

.editProfile_page .save_cancel .save[disabled] {
  background: #444 !important;
}

.editProfile_page .top_profile_details .save_cancel .save:hover {
  background: #6699ff;
  border: 1px solid #6699ff;
  cursor: pointer;
}

.editProfile_page .top_profile_details .choose {
  height: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.editProfile_page .top_profile_details .save_cancel .cancel {
  padding: 0.2rem 1.5rem;
  background: $white;
  color: $black;
  border: 1px solid $border;
  border-radius: 4px;
  margin-right: 1rem;
}

.editProfile_page .top_profile_details {
  border-bottom: none;
  position: relative;
  padding: 0rem !important;
  margin: auto;
}

/*SECTION 2*/

#certificates_profile .certificates_header {
  padding: 1rem 0rem;
}

#certificates_profile .certificates_header .title {
  display: inline-block;
}

#certificates_profile .certificates_header .add_certificate {
  float: left;
  padding: 0.4rem 1.5rem;
  background: $white;
  color: $black;
  border: 1px solid $border;
  border-radius: 4px;
}

.actions span i {
  color: $white;
  border-radius: 5px;
  background: $dark2-gray;
  padding: 0.6rem;
  font-size: 1rem;
  transition: all 200ms;
  cursor: pointer;
}

.actions span i:hover {
  background-color: #b1b1b1;
  transition: all 200ms;
}

#certificates_profile .certificates_details .details h4,
#certificates_profile .certificates_details .details h5 {
  padding: 0.5rem 0;
}

#certificates_profile .certificates_details .details h4 span {
  padding: 0 2rem;
}

#certificates_profile .certificates_details .details h5 {
  color: $dark2-gray;
}

#certificates_profile .certificates_details .details h4 {
  color: $very-dark-gray;
}

.editProfile_page #certificates_profile .content label {
  width: 100%;
  display: block;
  color: #7e7e7e;
}

.editProfile_page #certificates_profile .save_cancel {
  text-align: end;
}

.editProfile_page #certificates_profile .save_cancel .save {
  padding: 0.2rem 1.5rem;
  background: #3366ff;
  color: #fff;
  border: 1px solid #3366ff;
  border-radius: 4px;
}

.editProfile_page #certificates_profile .save_cancel .save:hover {
  background: #6699ff;
  border: 1px solid #6699ff;
  cursor: pointer;
}

.editProfile_page #certificates_profile .save_cancel .cancel {
  padding: 0.2rem 1.5rem;
  background: #fff;
  color: #000;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;
}

.editProfile_page #certificates_profile .certificates_form {
  padding: 1.2rem 0;
}

/*SECTION 3*/

#experience_profile .experience_header {
  padding: 1rem 0rem;
}

#experience_profile .experience_header .title {
  display: inline-block;
}

#experience_profile .experience_header .add_experience {
  float: left;
  padding: 0.4rem 1.5rem;
  background: $white;
  color: $black;
  border: 1px solid $border;
  border-radius: 4px;
}

.editProfile_page #experience_profile .experience_form {
  padding: 1.2rem 0;
}

.editProfile_page #experience_profile .content label {
  width: 100%;
  display: block;
}

.editProfile_page #experience_profile .save_cancel {
  text-align: end;
}

.editProfile_page #experience_profile .save_cancel .save {
  padding: 0.2rem 1.5rem;
  background: #3366ff;
  color: #fff;
  border: 1px solid #3366ff;
  border-radius: 4px;
}

.editProfile_page #experience_profile .save_cancel .save:hover {
  background: #6699ff;
  border: 1px solid #6699ff;
  cursor: pointer;
}

.editProfile_page #experience_profile .save_cancel .cancel {
  padding: 0.2rem 1.5rem;
  background: #fff;
  color: #000;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;
}

#experience_profile .experience_details {
  border-bottom: none;
}

.certificates_details .note,
.experience_details .note {
  border: 1px solid rgba(0, 0, 0, 0.18);
  padding: 1rem;
  background: #ffff95;
  margin: 0;
}

.certificates_details .note.highlight,
.experience_details .note.highlight {
  background: transparent;
  color: #666;
  font-size: 0.7rem;
}

#experience_profile .experience_details .details h4,
#experience_profile .experience_details .details h5 {
  padding: 0.5rem 0;
}

#experience_profile .experience_details .details h4 span {
  padding: 0 2rem;
}

#experience_profile .experience_details .details h5 {
  color: $dark2-gray;
}

#experience_profile .experience_details .details h4 {
  color: $very-dark-gray;
}

/* SECTION 4*/

#specializedIn_profile .specializedIn_header {
  padding: 1rem 0rem;
}

#specializedIn_profile .specializedIn_header .title {
  display: inline-block;
}

#specializedIn_profile .specializedIn_header .add_specializedIn {
  float: left;
  padding: 0.4rem 1.5rem;
  background: $white;
  color: $black;
  border: 1px solid $border;
  border-radius: 4px;
}

.editProfile_page #specializedIn_profile .experience_form {
  padding: 1.2rem 0;
}

.editProfile_page #specializedIn_profile .content label {
  width: 100%;
  display: block;
}

.editProfile_page #specializedIn_profile .save_cancel {
  text-align: end;
}

.editProfile_page #specializedIn_profile .save_cancel .save {
  padding: 0.2rem 1.5rem;
  background: #3366ff;
  color: #fff;
  border: 1px solid #3366ff;
  border-radius: 4px;
}

.editProfile_page #specializedIn_profile .save_cancel .save:hover {
  background: #6699ff;
  border: 1px solid #6699ff;
  cursor: pointer;
}

.editProfile_page #specializedIn_profile .save_cancel .cancel {
  padding: 0.2rem 1.5rem;
  background: #fff;
  color: #000;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;
}

#specializedIn_profile .experience_details {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: none !important;
}

#specializedIn_profile .experience_details .details h4,
#specializedIn_profile .experience_details .details h5 {
  padding: 0.5rem 0;
}

#specializedIn_profile .experience_details .details h4 span {
  padding: 0 2rem;
}

#specializedIn_profile .experience_details .details h5 {
  color: $dark2-gray;
}

#specializedIn_profile .experience_details .details h4 {
  color: $very-dark-gray;
}

/**/

.editProfile_page section .add .uploadImg {
  padding: 1rem 0;
  display: block;
}

.editProfile_page section .add img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.editProfile_page section .add i.fa-camera {
  position: relative;
  top: 3rem;
  right: 29rem;
  font-size: 1.5rem;
  color: $white;
}

.editProfile_page section .add input.upload {
  position: relative;
  top: 3rem;
  right: 25rem;
  opacity: 0;
}

.editProfile_page .certificates_form .add img {
  float: right;
}

.editProfile_page section .certificates_form .add i.fa-camera {
  position: relative;
  top: 3rem;
  right: -4.5rem;
  font-size: 1.5rem;
  color: #fff;
}

.editProfile_page section .certificates_form .add input.upload {
  position: relative;
  top: 3rem;
  right: -8rem;
  opacity: 0;
}

.editProfile_page .experience_form .add img {
  float: right;
}

.editProfile_page section .experience_form .add i.fa-camera {
  position: relative;
  top: 3rem;
  right: -4.5rem;
  font-size: 1.5rem;
  color: #fff;
}

.editProfile_page section .experience_form .add input.upload {
  position: relative;
  top: 3rem;
  right: -8rem;
  opacity: 0;
}

.extra_padding {
  padding: 0rem 0.5rem;
}

input.save {
  width: auto !important;
}

.left_padding {
  padding-left: 0.5rem;
}

/* noran styles */

.details_inner_container h3 {
  color: #7a7a7a;
  font-weight: 200 !important;
}

.qualification_ref_number {
  font-weight: 200 !important;
}

.details_inner_container .imgs_row {
  max-width: 73%;
  float: left;
}

.details_inner_container .img_container {
  width: 150px;
  height: 150px;
  float: left;
  padding: 1rem 0.2rem;
}

.details_text {
  max-width: 36%;
  display: inline-block;
  float: right;
}

.details {
  border-bottom: 1px solid #eee;
  padding: 1rem 0rem;
}

.details .remove span,
.details .add span,
.experience_details .remove span,
.experience_details .add span {
  margin: 0rem 0.1rem;
}

.details .remove span {
  float: left;
  background-color: #7e7e7e;
  border-radius: 5px;
  padding: 0.6rem;
  color: #fff;
  transition: all 200ms;
  font-size: 1.12rem !important;
}

.details .remove span:hover,
#certificates_profile .certificates_details .add span i:hover,
#experience_profile .experience_details .add span i:hover,
#specializedIn_profile .experience_details .add span i:hover {
  transition: all 200ms;
  background-color: #aeabab;
}

.qualificationEditDiv {
  display: none;
}

.details:last-of-type {
  border-bottom: none !important;
  padding-bottom: 0;
}

#qualification_form,
#experiences_form,
#specialized_form,
.qualificationEditDiv,
.specialityEditDiv,
.qualificationEditDiv {
  display: none;
}

#qualification_form.show,
#experiences_form.show,
#specialized_form.show,
.experienceEditDiv.show,
.qualificationEditDiv.show {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  padding: 0rem 3rem;
  background-color: rgba(0, 0, 0, 0.9);
  display: block;
  top: 0;
  bottom: 0;
  z-index: 999997;
  padding: 1rem !important;
}

#qualification_form.show .form_con,
#experiences_form.show .form_con,
#specialized_form.show .form_con,
.experienceEditDiv.show .form_cont,
.qualificationEditDiv.show .form_cont {
  display: inline-block;
  background-color: #fcfcfc;
  padding: 1rem !important;
  border: 1px solid #ddd;
  border-radius: 7px;
  margin: 1rem 0;
  z-index: 9999;
  width: 100%;
}

#showAddQualificationButton,
#showAddExperienceButton,
#showAddSpecialized,
#cancelQualificationAddForm,
#cancelExperienceEditButton,
#cancelSpecializedAdd,
.cancelSpecializedEdit,
#cancelExperienceAddForm {
  cursor: pointer;
  background: #06c;
  color: #fff;
}

.qualificationEditDiv.show {
  display: block !important;
}

.experienceefitForm.show {
  display: block !important;
}

.specialityEditDiv.show {
  display: block !important;
}

#files {
  visibility: hidden;
  display: none;
}

.add_image_qualification {
  padding: 1rem 0rem;
}

.label_qualification_upImg {
  width: 10% !important;
  display: inline-block !important;
  max-height: 115px;
  float: right !important;
}

.qualification_upload_img_container {
  float: left;
  max-width: 10%;
  max-height: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.qualification_upload_img_container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.1rem;
}

.images_uploaded {
  display: inline-block;
  width: 80%;
  float: left;
}

.experience_title,
.editProfile_page .qulaification_name,
#specializedIn_profile .experience_details .details h4 {
}

.noty_type_alert {
  background-color: #fe0000;
}

.noty_type_success {
  background: #4caf50;
  color: #fff;
}

.noty_type_alert .noty_message {
  text-align: end;
  padding: 10px;
  width: auto;
  position: relative;
  display: inline-block;
}

.noty_type_alert .noty_text {
  float: right;
  display: block;
  width: 100%;
  text-align: start;
  color: #fff;
}

.noty_topCenter_layout_container {
  direction: ltr;
}

.noty_buttons {
  text-align: center !important;
}

.noty_buttons .btn {
  border: 1px solid #eee;
  display: inline-block;
  margin: 0.5rem;
  text-align: center;
  padding: 0.25rem 1.5rem;
  border-radius: 4px;
  background: #36f;
  color: #fff;
  transition: all 200ms;
  cursor: pointer;
}

.noty_buttons .btn.btn-primary {
  background: #36f;
}

.noty_buttons .btn.btn-danger {
  background: #fe0000;
}

.noty_buttons .btn:hover {
  background-color: #444;
}

.padding_left {
  padding-left: 0.5rem;
}

#example {
  display: none !important;
}

.experienceEditDiv {
  display: none !important;
}

.experienceEditDiv.show {
  display: block !important;
}

.imageHolder {
  width: 160px !important;
  height: 160px !important;
  float: right;
  margin: 0.5rem;
  position: relative;
}

.imageHolder img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.imageHolder input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}

.imageHolder .removeAttachment {
  position: absolute;
  top: 2rem;
  right: 0;
  padding: 1rem;
  background: #fe0000;
  color: #fff;
  cursor: pointer;
}

.remove span,
.add span,
.editSpecialized,
.submit-btn {
  cursor: pointer;
  -webkit-appearance: none;
}

form img {
  cursor: pointer;
}

.details_text h3 span {
  color: #000;
  padding-left: 0.8rem;
  float: right;
}

.specialityName {
  display: inline-block;
  padding: 0.5rem 1rem !important;
  border: 1px solid #ccc;
  border-radius: 35px;
}

.specialityName .remove,
.specialityName .remove span {
  padding: 0rem !important;
}

.specialityName .remove span {
  padding-top: 9px !important;
  float: left;
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 0rem;
  color: #333;
  font-size: 1rem !important;
  padding-right: 1rem !important;
}

.specialized_details .details {
  border: none !important;
}

.modal_title {
  padding: 0rem 0.5rem;
  padding-bottom: 1rem;
  color: #16327f;
}

form label {
  color: #1056ac;
}

#noty_topCenter_layout_container {
  top: 43% !important;
}

#noty_topCenter_layout_container li {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.22) !important;
  border: 1px solid rgba(0, 0, 0, 0.21) !important;
}

.noty_topCenter_layout_container:before {
  content: '';
}

.toogleSection {
  float: right;
  padding-left: 17px;
  cursor: pointer;
  color: #000;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
  display: list-item;
  list-style-type: none;
}

.toogleSection i {
  font-style: normal !important;
}

.toogleSection:hover {
  color: $base-1;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}

.add_certificate:hover,
.add_experience:hover,
.add_specializedIn:hover {
  background: #e3e3e3 !important;
  color: #000 !important;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}

.profile_password .top_profile_details,
.basic_details {
  display: block;
}

.experience_details,
.specialized_details,
.certificates_details,
.profile_password .top_profile_details.hideDetails,
.basic_details.hideDetails {
  display: none;
}

.editProfile_page #top_profile {
  padding: 1rem;
}

.experience_details,
.specialized_details,
.certificates_details {
  display: block;
  background-color: #fff;
}

.table_heading {
  border-bottom: 1px solid #ddd;
  padding: 1rem;
}

.qualificationImgContainer {
  display: inline-block;
  float: right;
  /* padding: 0.5rem; */
  position: relative;
  margin: 0rem 1rem;
  max-height: 88px;
  margin-right: 0rem;
}

.qualificationImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fancybox-overlay {
  z-index: 999999999999999999999;
}

#experience_profile .qualificationImgContainer .grouped_elements:after {
  content: attr(attr-length);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 17%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: normal;
  font-size: 1.6rem;
  bottom: 0;
  cursor: pointer;
}

.fancybox-nav span {
  visibility: visible !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.table_row h3 {
  display: block;
  font-size: 1rem;
}

.table_row .notification_item i {
  padding: 0rem 0.5rem;
  float: right;
  line-height: 4rem;
}

.table_row .notification_item h3 {
  line-height: 4rem;
}

.table_row_container {
  padding: 0.5rem 1rem;
}

.actions {
  display: inline-block;
  padding-top: 1rem;
}

.experience_details .grouped_elements {
  display: none;
}

.experience_details .grouped_elements:first-of-type {
  display: block;
}

.table_heading_title:first-of-type {
  width: 46% !important;
}

.container {
  position: relative;
  width: 90px;
  padding: 0rem !important;
}

.filled {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 171px;
  height: attr(attr-content);
  background-color: #e8308a;
  right: 0;
}

img.im {
  z-index: 1;
  width: 139px;
  height: 136px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 00;
  margin: auto;
  top: 0;
  bottom: 0;
  object-fit: cover;
}

.profileLoader {
  position: relative;
  width: 147px;
  padding: 0rem !important;
  height: 147px;
}

.circlie:after {
  content: attr(attr-content);
  position: absolute;
  z-index: 999;
  color: #e8308a;
  top: auto;
  bottom: 69%;
  margin-right: -1.5rem;
}

.circlie {
  border-radius: 50%;
  overflow: hidden;
  height: 147px;
  width: 147px;
  margin: auto;
  background: #ccc;
}

.top_profile_details .thumbnail {
  position: absolute;
  top: 0;
  padding-top: 22%;
  z-index: 99999;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  width: 148px;
  height: 148px;

  margin: auto;

  right: 0;
  font-size: 22px;
  text-align: center;
  opacity: 0;
  transition: all 200ms;
}

.top_profile_details .thumbnail:hover {
  opacity: 1;
  transition: all 200ms;
}

.noDegree {
  text-align: center;
  padding: 1rem;
  color: #0d4893;
  background-color: #fff;
}

.noDegree h2 {
  padding-bottom: 1rem;
}

.noDegree a {
  padding: 0.5rem 2rem;
  border-radius: 3px;
  margin: 1rem 0;
  display: inline-block;
}

.ProfileStatus {
  background-color: #f4f4f4;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px dashed #ccc;
}

.ProfileStatus h2 {
  text-align: center;
  font-size: 0.8rem;
  font-weight: normal;
}

.ProfileStatus h2 span {
  color: #38c327;
  font-weight: 900;
}

.changePassword {
  margin: 0rem 1rem;
  text-decoration: none;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  padding: 1px 0.5rem;
  border-radius: 4px;
  transition: all 200ms;
}

.changePassword:hover {
  background-color: #ddd;
  transition: all 200ms;
}

.password_forms {
  display: none;
}

.password_forms.showForm {
  display: block;
}

.msg_container {
  background-color: #fff;
  text-align: center;
  height: auto;
  position: relative;
}

.noDegree_notify {
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 10px;
  height: auto;
  display: none;
  width: 100%;
  margin: auto;
  float: none;
  padding: 11px 4rem;
  background: #0267cc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.noDegree_notify.show-noty {
  display: inline-block;
}

.noDegree_notify h2 {
  display: inline-block;
  padding: 0rem 23px;
  float: right;
}

.noDegree_notify a {
  border: 1px dashed;
  padding: 5px 20px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  float: left;
  min-width: 17%;
}

.noDegree_notify a:hover {
  color: #0267cc;
  background-color: #fff !important;
  border: 1px solid #000;
}

.specialized_details .details {
  padding: 0rem !important;
}

.specialized_details {
  padding: 10px 5px;
}

.top_profile_img {
  width: 148px;
}

.hint_img {
  font-size: 13px;
  color: #08196e;
}
