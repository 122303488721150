.webview .genericPage {
  padding-top: 0rem;
}

.genericPage .img_container {
  position: absolute;
  overflow: hidden;
  max-height: 100%;
  height: 350px;
  width: 100%;
}

.genericPage .img_container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.genericPage .article_title h1 {
  font-size: 2.1rem;
  color: #000;
  padding-bottom: 0.5rem;
}

.genericPage .end_article_details {
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  padding: 2rem 3rem;
  box-shadow: 0px 1px 6px #7f7f7f;
  float: none;
}

.genericPage .end_article_details p {
  color: $black;
  font-size: 1rem;
}

.genericPage .inner_container {
  position: relative;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.defaultPage .img_container {
  display: none;
}

.defaultPage .inner_container {
  padding-top: 0rem !important;
}
.genericPage .end_article_details .playstore {
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row;
  flex-flow: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0rem 1rem;
  -ms-flex-pack: distribute;
  flex-wrap: wrap;
  border-top: 1px solid #ddd;
  margin-top: 1rem;
  justify-content: center;
  text-align: center;
}
.genericPage .end_article_details .playstore h3 {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0rem 0.5em;
}
.genericPage .end_article_details .playstore li {
  margin: 0;
}
.end_article_details ul {
  list-style-type: disc;
}

.end_article_details ul li {
  margin-right: 2rem;
}

.webview .playstore,
.webview #tawkchat-minified-box,
.webview .donwload-banner-quiz .d-flex {
  display: none !important;
}
