.testimo_box_container {
  padding: 2rem;
  text-align: center;
}

.testimonial_section_container {
  padding: 2rem;
  background-color: transparent;
}

.testimonial_section_container .testimo_box {
  padding: 1rem;
}

.testimonial_section_container .section_title {
  color: #3c3c3c;
  font-size: 1.8rem;
}

.testimo_box_container .owl-nav {
  top: 35%;
  position: absolute;
  left: 0;
  right: 0;
}

.testo_stars {
  position: relative;
}

.testo_stars_rate {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  margin: auto;
}

.testimo_box_container .owl-prev,
.articles_box_container .owl-prev {
  position: absolute;
  top: 0;
  left: 2%;
  right: auto;
  font-size: 1rem;
  height: 42px;
  width: 42px;
  border-radius: 25px;
  text-align: center;
  color: $base-6-alt;
  background-color: #d2d2d2;
}

.testimo_box_container {
}

.testimo_box_container .owl-next,
.articles_box_container .owl-next {
  position: absolute;
  top: 0;
  right: 2%;
  left: auto;
  font-size: 16px;
  height: 42px;
  width: 42px;
  border-radius: 25px;
  text-align: center;
  color: $base-6-alt;
  background-color: #d2d2d2;
}

.testimo_box_container .owl-next:hover,
.testimo_box_container .owl-prev:hover,
.articles_box_container .owl-next:hover,
.articles_box_container .owl-prev:hover {
  color: $base-6-alt;
  background-color: #e8308a;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  transition: all 150ms;
}

.testimo_box_container .owl-next i,
.testimo_box_container .owl-prev i,
.articles_box_container .owl-next i,
.articles_box_container .owl-prev i {
  margin-top: 14px;
}

.testimo_box .testo_img {
  height: 200px;
  width: 200px;
  margin: auto;
  border-radius: 100px;
  overflow: hidden;
}

.testimo_box .testo_img img {
  overflow: hidden;
}

.testimo_box .testo_name {
  color: #3c3c3c;
  padding: 0.5rem 0rem;
  font-size: 1.3rem;
}

.testimo_box label,
.testimo_box small {
  display: block;
  font-weight: initial;
}

.testo_name-rating {
  display: inline-block !important;
  width: 24px !important;
  height: 24px !important;
}

.testimo_box .testo_details {
  color: #000000;
  text-align: justify;
  padding: 0.5rem 0rem;
  opacity: 1;
}

.testimo_box strong i {
  color: #d2d2d2;
  font-size: 2rem;
  vertical-align: middle;
  margin-left: 0.2rem;
}
