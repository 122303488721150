body {
  overflow-x: hidden;
  display: block;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  font-family: 'Cairo', sans-serif !important;
  width: 100%;
}

.webview header,
.webview footer,
.webview .breadCrumbs {
  display: none !important;
}

[dir='rtl'] [class*='col'] {
  float: right;
}

* {
  font-family: 'Cairo', sans-serif, 'FontAwesome' !important;
}

.grid:after,
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
 
/* start commented backslash hack \*/

* html .grid,
* html .clearfix {
  height: 1%;
}

.grid,
.clearfix {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0px;
  margin: 0px;
}

img {
  height: auto;
  width: 100%;
  border-color: transparent;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:active,
a:visited {
  outline: none;
}

.main_container {
  width: 100%;
  margin: auto;
  display: block;
  float: none;
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.webview .intro {
  padding-top: 0rem !important;
}

.main_container .how_it_work_section,
.main_container .testimonial_section_container,
.main_container .articles_section_container {
  padding: 5rem 3rem;
}

label.error {
  display: block !important;
  width: 100% !important;
  color: #f00 !important;
  font-size: 0.7rem !important;
}

input.error,
textarea.error,
select.error {
  border: dashed 1px #f00 !important;
}

.intl-tel-input.allow-dropdown .flag-container {
  left: 0rem !important;
  height: 2.5rem;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type='text'],
.intl-tel-input.allow-dropdown input[type='tel'] {
  padding-right: 16px;
  direction: ltr !important;
}

.section_title {
  text-align: center;
  font-size: 1.5rem;
  color: #16518c;
}

.main_container .section_title {
  font-size: 2rem;
}

.contact_us_page .title {
  color: #000;
  padding: 1rem;
  text-align: start;
  font-size: 1.5rem;
}

.iti-flag {
  background-image: url('../../assets/images/flags.png');
}

.intl-tel-input .country-list {
  direction: ltr;
  text-align: start;
}

.no_data_container {
  text-align: center;
  font-size: 1rem;
  width: 100%;
  padding: 3rem 0rem;
}

.picker {
  width: inherit !important;
}

.picker__weekday {
  font-size: 0.6em !important;
}

.picker__select--year,
.picker__select--month {
  width: 37% !important;
  float: none !important;
  padding: 0rem 1rem !important;
  margin: 0rem 5px !important;
  overflow-y: scroll !important;
}

.emptyList {
  margin: 1rem 0;
  padding: 2rem;
  text-align: center;
  border: 2px dashed #ddd;
}

/* ie style */

.ie9 .register_form form .fb_btn a i,
.ie10 .register_form form .fb_btn a i,
.ie11 .register_form form .fb_btn a i {
  font-size: 1.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
  height: 2.2rem;
}

.ie9 .login_form form .fb_btn a i,
.ie10 .login_form form .fb_btn a i,
.ie11 .login_form form .fb_btn a i {
  font-size: 1.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
  height: 2.2rem;
}

.ie9 .resetSuccess_page h1,
.ie10 .resetSuccess_page h1,
.ie11 .resetSuccess_page h1 {
  padding: 9.5rem 0;
  padding-bottom: 3rem;
}

.ie9 .newPassword_page {
  padding-bottom: 7.6rem;
}

.ie10 .newPassword_page,
.ie11 .newPassword_page {
  padding-bottom: 6.6rem;
}

.ie9 .newPassword_page .title,
.ie10 .newPassword_page .title,
.ie11 .newPassword_page .title {
  padding-top: 6rem;
}

.ie9 .top_profile_details .name_img span,
.ie10 .top_profile_details .name_img span,
.ie11 .top_profile_details .name_img span {
  bottom: 24.5rem;
}

.ie9 .accordion .content form input,
.ie10 .accordion .content form input,
.ie11 .accordion .content form input {
  padding: 0.5rem 1rem;
}

.ie9 .subSpeciality_footer .thumbnail .upload_btn,
.ie10 .subSpeciality_footer .thumbnail .upload_btn,
.ie11 .subSpeciality_footer .thumbnail .upload_btn {
  padding: 0.2rem 2rem;
}

.ie9 #bottom_profile .save_edits,
.ie10 #bottom_profile .save_edits,
.ie11 #bottom_profile .save_edits {
  padding: 0.3rem 1rem;
}

.ie9 .addSpecialty,
.ie10 .addSpecialty,
.ie11 .addSpecialty {
  padding: 1rem 0;
}

.ie11 .register_form_container .exper_years_doc_input,
.ie10 .register_form_container .exper_years_doc_input,
.ie9 .register_form_container .exper_years_doc_input {
  min-height: 37px;
}

.ie9 .resetPassword_page {
  padding-bottom: 10rem;
}

.ie10 .resetPassword_page,
.ie11 .resetPassword_page {
  padding-bottom: 9.3rem;
}

.ie9 .login_page {
  padding-bottom: 6.7rem;
}

.ie10 .login_page,
.ie11 .login_page {
  padding-bottom: 6rem;
}

.ie11 .error_heading {
  width: 55%;
}

.ie11 .doctor_svg {
  width: 40%;
}

.ie11 .genericPage .article_title,
.ie9 .genericPage .article_title {
  padding: 0.5rem !important;
}

.ie11 .genericPage .img_container,
.ie10 .genericPage .img_container,
.ie9 .genericPage .img_container {
  max-height: 280px;
  width: 100%;
}

.ie11 .artcle_img,
.ie9 .artcle_img {
  height: 220px;
  overflow: hidden;
}

.ie11 .artcle_img img,
.ie9 .artcle_img img {
  height: 100%;
  width: auto;
  margin: auto;
  display: block;
  object-fit: cover;
}

.ie11 .article_details p,
.ie9 .article_details p {
  font-size: 1.4rem !important;
  padding: 0.5rem 0rem;
}

.ie11 .genericPage .img_container img,
.ie10 .genericPage .img_container img {
  height: auto;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999;
}

input[type='submit'],
button {
  -webkit-appearance: none;
}

/*.loader.on:before {
    content: 'من فضلك انتظر';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: -1;
    color: #000;
    font-size: 2rem;
    text-align: center;
    padding: 10rem 0;
    font-weight: 900;
    color: #36f;
}*/

.loader.on .bar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: #36f;
  transform: 200ms;
}

.loader.on .circle {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #36f;
  border-bottom: 1px;
  border-right: 2px;
  animation: circle infinite 1s;
}

.webview .hotlineContact {
  display: none !important;
}

.webview_hotlineContact {
  display: none;
}

@keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1000px) {
  .pt-7-xl {
    padding-top: 6rem !important;
  }
}

.pr-05 {
  padding-right: 0.5rem;
}

.pl-05 {
  padding-left: 0.5rem;
}

.px-05 {
  padding: 0 0.5rem;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.d-block {
  display: block;
}

.pos-relative {
  position: relative;
}

.font-weight-bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}

@media (max-width: 1000px) {
  .d-mobile {
    display: block !important;
  }

  .d-desk {
    display: none !important;
  }
}

@media (min-width: 1000px) {
  .d-mobile {
    display: none !important;
  }

  .d-desk {
    display: block !important;
  }
}

/* textaligns */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hide {
  display: none !important;
}

.align-items-center {
  align-items: center;
}

/* confirm model homepage */

.openModal {
  background-color: #478e9e;
  color: #fff !important;
  border: 2px solid transparent;
  transition: all 150ms;
  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0rem !important;
  border-radius: 100px !important;
  cursor: pointer;
  display: block;
  text-align: center;
}

.modal-confirm-homepage {
  text-align: center;
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modalContent-confirm-homepage {
  font-size: 20px;
  font-weight: bold;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  height: 300px;
}
.close-confirm-homepage {
  color: rgb(255, 65, 65);
  float: right;
  font-size: 40px;
  font-weight: bold;
}
.close-confirm-homepage:hover,
.close-confirm-homepage:focus {
  color: #ff1010;
  cursor: pointer;
}
.modalContent-confirm-homepage button {
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.ltr {
  direction: ltr !important;
}

button,
input {
  cursor: pointer;
}

@media (max-width: 800px) {
  .modalContent-confirm-homepage {
    width: 80%;
  }

  .object-fit-contain-sm {
    object-fit: contain !important;
  }
}

/* tabssss */

.tab-content {
  display: none;
}
.tab-content.active {
  display: block;
}
/* cards css */

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  height: 100%;
}

.card-img-top {
  width: 100%;
  height: 175px;
  filter: sepia(0.4);
}

.card-img-bottom {
  width: 100%;
}

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}
.card-deck .card img.secondImg {
  height: 4rem;
  object-fit: contain;
}
.card-md-deck {
  margin: auto;
}

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    margin-bottom: 0;
  }
}

.card-md-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-md-deck .card {
  margin-bottom: 15px;
}
.card-type.btn {
  height: 2rem;
  font-size: 1rem;
  opacity: 0.81 !important;
  width: auto;
  min-width: 6rem !important;
  padding: 0rem 1rem !important;
}
.heart-label {
  z-index: 9;
}
.card-md-deck {
  margin-bottom: 3rem;
}
.arrow-circle-down {
  height: 20px !important;
  width: 20px !important;
  vertical-align: middle;
}
.mobile-doc {
  width: 230px !important;
}
@media (min-width: 769px) {
  .card-md-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-md-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 15px;
  }
}

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.count-container {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.line-height-3 {
  line-height: 3;
}

.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 2rem;
}

.m-5 {
  margin: 3rem;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mb-6 {
  margin-top: 5rem;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 5rem;
}

.my-0 {
  margin-block: 0rem !important;
}

.my-1 {
  margin-block: 0.25rem;
}

.my-2 {
  margin-block: 0.5rem;
}

.my-3 {
  margin-block: 1rem;
}

.my-4 {
  margin-block: 2rem;
}

.my-5 {
  margin-block: 3rem;
}

.my-6 {
  margin-block: 5rem;
}

.mx-0 {
  margin-inline: 0rem !important;
}

.mx-1 {
  margin-inline: 0.25rem;
}

.mx-2 {
  margin-inline: 0.5rem;
}

.mx-3 {
  margin-inline: 1rem;
}

.mx-4 {
  margin-inline: 2rem;
}

.mx-5 {
  margin-inline: 3rem;
}

.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 3rem;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.py-0 {
  padding-block: 0rem !important;
}

.py-1 {
  padding-block: 0.25rem;
}

.py-2 {
  padding-block: 0.5rem;
}

.py-3 {
  padding-block: 1rem;
}

.py-4 {
  padding-block: 2rem;
}

.py-5 {
  padding-block: 3rem;
}

.px-0 {
  padding-inline: 0rem !important;
}

.px-1 {
  padding-inline: 0.25rem;
}

.px-2 {
  padding-inline: 0.5rem;
}

.px-3 {
  padding-inline: 1rem;
}

.px-4 {
  padding-inline: 2rem;
}

.px-5 {
  padding-inline: 3rem;
}

.ps-0 {
  padding-left: 0rem !important;
}

.ps-1 {
  padding-left: 0.25rem;
}

.ps-2 {
  padding-left: 0.5rem;
}

.ps-3 {
  padding-left: 1rem;
}

.ps-4 {
  padding-left: 2rem;
}

.ps-5 {
  padding-left: 3rem;
}

.pe-0 {
  padding-right: 0rem !important;
}

.pe-1 {
  padding-right: 0.25rem;
}

.pe-2 {
  padding-right: 0.5rem;
}

.pe-3 {
  padding-right: 1rem;
}

.pe-4 {
  padding-right: 2rem;
}

.pe-5 {
  padding-right: 3rem;
}

.ms-0 {
  margin-left: 0rem !important;
}

.ms-1 {
  margin-left: 0.25rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-5 {
  margin-left: 3rem;
}

.me-0 {
  margin-right: 0rem !important;
}

.me-1 {
  margin-right: 0.25rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.me-3 {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 2rem;
}

.me-5 {
  margin-right: 3rem;
}

.fw-bold {
  font-weight: bold;
}

.open-modal {
  cursor: pointer;
}

.justify-content-between {
  justify-content: space-between;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.fs-8 {
  font-size: 0.65rem !important;
}

.text-ar-start {
  text-align: right;
}

.text-ar-end {
  text-align: left;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-pink {
  background-color: #e8308a !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-main {
  color: #478e9e !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.border-main {
  border-color: #478e9e !important;
}

.border-2 {
  border: 2px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.rounded-pill {
  border-radius: 10rem;
}

[dir='ltr'] .ps-0 {
  padding-right: 0rem !important;
}

[dir='ltr'] .ps-1 {
  padding-right: 0.25rem;
}

[dir='ltr'] .ps-2 {
  padding-right: 0.5rem;
}

[dir='ltr'] .ps-3 {
  padding-right: 1rem;
}

[dir='ltr'] .ps-4 {
  padding-right: 2rem;
}

[dir='ltr'] .ps-5 {
  padding-right: 3rem;
}

[dir='ltr'] .pe-0 {
  padding-left: 0rem !important;
}

[dir='ltr'] .pe-1 {
  padding-left: 0.25rem;
}

[dir='ltr'] .pe-2 {
  padding-left: 0.5rem;
}

[dir='ltr'] .pe-3 {
  padding-left: 1rem;
}

[dir='ltr'] .pe-4 {
  padding-left: 2rem;
}

[dir='ltr'] .pe-5 {
  padding-left: 3rem;
}

[dir='ltr'] .ms-0 {
  margin-right: 0rem !important;
}

[dir='ltr'] .ms-1 {
  margin-right: 0.25rem;
}

[dir='ltr'] .ms-2 {
  margin-right: 0.5rem;
}

[dir='ltr'] .ms-3 {
  margin-right: 1rem;
}

[dir='ltr'] .ms-4 {
  margin-right: 2rem;
}

[dir='ltr'] .ms-5 {
  margin-right: 3rem;
}

[dir='ltr'] .me-0 {
  margin-left: 0rem !important;
}

[dir='ltr'] .me-1 {
  margin-left: 0.25rem;
}

[dir='ltr'] .me-2 {
  margin-left: 0.5rem;
}

[dir='ltr'] .me-3 {
  margin-left: 1rem;
}

[dir='ltr'] .me-4 {
  margin-left: 2rem;
}

[dir='ltr'] .me-5 {
  margin-left: 3rem;
}

@media screen and (max-width:500px) {
  .line-height-3 {
    line-height: 2;
  }

  .count-container {
    margin-top: 0.25rem;
  }
}