header {
  display: flex;
  padding: 0.5rem 0rem;
  height: auto;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
}

.header-logo {
  margin-left: 0;
  margin-right: 0;
  width: auto;
  display: flex;
  flex-flow: column;
  padding: 0rem 1rem;
}

.header-logo img {
  max-width: 300px;
}

  .header-logo span {
    font-weight: 900;
    margin-top: auto;
    font-size: 1rem;
    color: #7f7f7f;
    line-height: 1;
    margin-bottom: 1rem;
  }

.header-social {
  display: flex;
  flex-flow: column;
}

.social-media {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse wrap;
}

.social-media li {
  padding: 0.2rem;
}

.playstore {
  display: flex;
}

.playstore a {
  display: inline-block;
  max-width: 127px;
}

.header-actions {
  display: flex;
  flex-flow: column;
}

.header-actions ul {
  display: flex;
  flex-flow: row wrap;
}

.header-actions ul li {
  padding: 0rem 1.5rem;
  border-right: 1px solid #377cc1;
}

.highlight-language {
  width: 100%;
  margin-top: 15%;
  margin-bottom: auto;
  text-align: left;
}

.highlight-language a {
  display: flex;
  flex-flow: row-reverse;
  opacity: 0.6;
  align-items: center;
}

.social-media a {
  padding: 0 0.5rem;
  display: inline-block;
}

.header-contact {
  display: flex;
  flex-flow: column;
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c64a6;
}

.contact_us_title a {
  display: none;
}

.webview .contact_us_title a {
  display: inline-block;
  background-color: #478e9e;
  float: left;
  padding: 0.5rem;
  border-radius: 24px;
}

.header-contact div {
  padding: 0.5rem 1rem;
}

.header-contact .hotline {
  color: #fff;
  background: #478e9e;
  border-radius: 6rem;
  width: auto;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.yemen-header-container .header-contact .hotline {
  padding: 0.15rem;
}

.yemen-header-container .header-contact .hotline img {
  width: 220px;
  align-self: center;
}

.header-contact a {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
}

header .toogle_navbar_icon {
  display: none;
}

header .hotline span img {
  max-width: 17px;
}

header .navbar {
  display: none;
}

.profile_notifiacation {
  width: 75%;
  text-align: start;
  font-size: 1rem;
  color: #fff;
  padding: 13px 79px 13px 10px;
  position: relative;
  box-shadow: 0px 1px 4px #333;
  margin: auto;
  float: none;
  display: table;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 7px;
}

.profile_progress_red {
  background-color: #e21809;
}

.profile_progress_green {
  background-color: #429a29;
}

.profile_progress_orange {
  background-color: #e25e09;
}

.profile_notifiacation span {
  float: right;
  right: 3%;
  position: absolute;
  top: 23%;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
  text-shadow: none;
  transition: all 200ms;
}

/*.profile_progress_green span:hover,
.profile_progress_red span:hover {
    text-shadow: 1px 1px 4px #333;
    transition: all 200ms;
}*/

.profile_notifiacation h3 {
  display: inline-block;
  font-weight: 200;
}

.profile_notifiacation.removeDiv {
  display: none;
}

.profile_img_container span {
  font-size: 1vw;
}

header .hello_span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1vw;
}

.notification {
  float: left;
  padding-left: 0.5rem !important;
  padding-bottom: 0rem !important;
  position: relative;
  min-width: 200px;
}

.notification a {
  font-size: 1.5rem !important;
  color: #2b69a8;
}

.notification_item {
  padding: 5px !important;
}

.notification_item h3 {
  font-size: 0.7rem;
  text-align: start;
  direction: rtl;
  font-weight: 200;
}

.arrow_header {
  display: inline-block;
  width: 8px;
  margin: 0 0.2rem;
}

.notification_list .link_back {
  display: none;
}

.notification ul {
  max-width: 273px;
  background-color: #fff;
  /* height: initial !important; */
  border: 1px solid #ddd;
  float: left;
  /* border-top: none; */
  margin-top: -3px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  display: none;
  direction: ltr;
  text-align: start;
  position: absolute;
  top: 4.9rem;
  z-index: 999;
  max-height: 169px !important;
  top: 100%;
  left: 0;
  z-index: 99999999999;
}

.notification.show_notification ul {
  display: block !important;
  padding: 0.5rem 0px;
  box-shadow: 0px 1px 3px #333;
}

.notification.show_notification .notification_icon i {
  border: 1px solid #ddd;
  border-bottom: none;
}

.notification_green i {
  color: #03cb03;
}

.notification_red i {
  color: #f00;
}

.notification_orange i {
  color: #f89d2c;
}

.notification a {
  display: block;
  float: left;
  width: 100%;
  text-align: end;
  padding: 0rem;
  /* margin-top: 10px; */
  z-index: 99999;
  border-bottom: none;
  /* position: absolute; */
  /* left: 0; */
  position: relative;
  padding: 0rem 0rem 0rem 0.6rem !important;
  display: block;
  z-index: 999999999;
  background-color: #fff;
}

.notification_item {
  border-bottom: 1px solid #eee;
  display: inline-block;
  width: 100%;
  transition: all 200ms;
}

header .notification_item:hover {
  background-color: #ececec;
  transition: all 200ms;
}

.notification_item:last-of-type {
  border-bottom: none;
}

.notification_item i {
  line-height: 3rem;
  font-size: 1.5rem;
}

.notification a i {
  z-index: 999999;
  padding: 1rem 1.5rem;
}

.notification_item a i {
  padding: 0.35rem !important;
  line-height: 1rem;
  float: right;
  font-size: 1.2rem;
}

.notification_item a {
  background-color: transparent;
}

.notification ul li span {
  vertical-align: bottom;
}

.notification .link_back {
  display: none;
}

.doctor_profile_link {
  padding: 0rem !important;
  display: inline-block;
  width: 100%;
  margin-right: 5rem;
}

header .logout_header_link {
  position: absolute;
  left: 0;
  right: auto;
  width: auto;
  right: 2rem;
  top: 95%;
  border: 1px solid #ddd;
  padding: 0rem !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 4px #acacac;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: none !important;
  z-index: 99;
  flex-flow: column;
  transition: all 300ms ease;
}

.desktop_login_link:hover li {
  display: flex !important;
}

header .header_profile_container {
  z-index: 9999;
}

.login_header_link {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  align-items: center;
}

header .container {
  width: 90px;
}

header .filled {
  width: 60px;
  height: 45px;
}

header .filled:after {
  content: attr(attr-content);
  position: fixed;
  z-index: 999;
  margin-right: -1.5rem;
  color: #e8308a;
  font-size: 0.6em;
}

header img.im {
  width: 54px;
  height: 54px;
}

header .profileLoader {
  width: 60px;
  height: 60px;
  display: inline-block;
  float: right;
  border-radius: 50%;
  overflow: hidden;
  margin: -0.25rem 0 0 1rem;
}

.header-fixed {
  /* box-shadow: 1px 2px 1px #e0e0e0; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #478e9e;
  color: #fff;
  font-size: 1rem;
  padding: 0 3rem;
  transition: all 200ms;
}

.header-fixed .header-nav {
  display: flex;
}

.header-fixed .header-social .social-media li a img {
  width: 10px;
  height: 20px;
}

.header-nav-2 {
  display: flex;
  color: #7f7f7f;
}

.header-nav-2 .main_nav_links {
  padding: 0 0.8rem;
  color: #7f7f7f;
}

.header-fixed .header-nav li {
  padding: 0rem 0.5rem;
}

.header-fixed .header-actions {
  display: flex;
  align-items: center;
}

.header-fixed .header-actions a {
  display: flex;
  align-items: center;
  /* padding: 1rem 0; */
}

.main_nav_links:hover {
  color: #3197cb;
  transition: all 200ms;
}

.header-fixed.fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  font-size: 1.2rem;
  /* padding: 1.5rem; */
  transition: all 200ms;
}

.homepage .intro ul {
  display: none !important;
}
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* dropdown menu */
ul.header-nav .drop-link {
  color: #7f7f7f;
  border: none;
}

ul.header-nav .dropdown {
  position: relative;
  display: inline-block;
}

ul.header-nav .dropdown-content {
  display: none;
  position: absolute;
  right: 0px; /* not necessarily needed */
  background-color: #fff;
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 120;
}

ul.header-nav .dropdown-content a {
  color: #7f7f7f;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 1.1rem;
  transition: color, background-color 0.15s ease-in;
}

ul.header-nav .dropdown-content a:hover {
  background-color: #478e9e;
  color: #fff;
}

ul.header-nav .dropdown:hover .dropdown-content {
  display: block;
}

ul.header-nav .dropdown:hover .drop-link {
  /* background-color: #3e8e41; */
}

/* ------------------------------------------------------------------------ */
/* ----------- dropdown for mobile ------------------------ */
/* ------------------------------------------------------------------------ */

ul.nav_inner_container .drop-link {
  /* background-color: #4caf50; */
  color: #fff;
  /* padding: 16px; */
  /* font-size: 1.3rem; */
  border: none;
}

ul.nav_inner_container .dropdown {
  position: relative;
  /* display: inline-block;
  font-size: 1.3rem; */
}
ul.nav_inner_container .dropdown .parent-link {
  display: flex;
  align-items: center;
}
ul.nav_inner_container .dropdown .parent-link span {
  text-align: end;
  color: #fff;
  flex: 1 1 auto;
}
ul.nav_inner_container .dropdown-content {
  display: none;
  width: 100%;
  z-index: 2;
}

ul.nav_inner_container .dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 1rem;
  display: block;
  text-align: start;
  transition: color, background-color 0.15s ease-in;
}
ul.nav_inner_container .dropdown-content a:not(:last-of-type) {
  border-bottom: 1px solid rgba(238, 238, 238, 0.05);
}
ul.nav_inner_container .dropdown-content a:hover {
  background-color: #478e9e;
  color: #fff;
}

ul.nav_inner_container .dropdown:hover .dropdown-content {
  display: block;
}

ul.nav_inner_container .dropdown:hover .drop-link {
  /* background-color: #3e8e41; */
}
