.sitemap-end {
    text-align: start;
    padding-top: 5rem;
}

.sitemap .section-title {
    padding: 0.34rem 0rem;
}

.sitemap {
    padding: 0rem 1rem;
}
.sitemap .group-1-label
{
        width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #eee;
}
.sitemap-inner-container {
    text-align: start;
    background-color: #fff;
    border-style: hidden;
    border-radius: 8px;
    padding: 1rem 6rem;
}

.sitemap-inner-container a {
    text-decoration: none;
   
}

.cd-accordion-menu {
    width: 100%;
    list-style-type: none;
    color: #7d7d7d;
}

.cd-accordion-menu ul {
    /* by default hide all sub menus */
    list-style-type: none;
    display: none;
}

.cd-accordion-menu li {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cd-accordion-menu input[type=checkbox] {
    /* hide native checkbox */
    position: absolute;
    opacity: 0;
}

.cd-accordion-menu label,
.cd-accordion-menu a {
    position: relative;
    display: block;
    font-size: 1.1rem;
}

.no-touch .cd-accordion-menu label:hover,
.no-touch .cd-accordion-menu a:hover {
    background: #52565d;
}

.cd-accordion-menu label::before,
.cd-accordion-menu label::after,
.cd-accordion-menu a::after {
    /* icons */
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cd-accordion-menu label {
    cursor: pointer;
    display: inline-block;
}

.cd-accordion-menu label::before {
    /* arrow icon */
    left: 123px;
    top: 13px;
    transform-origin: bottom right;
    background-position: 0 0;
    -webkit-transform: translateY(-50%) rotate(90deg);
    -moz-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    -o-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
}

.cd-accordion-menu label::after {
    /* folder icons */
    left: 41px;
    background-position: -16px 0;
}

.group-2-label::before {
    left: 43px !important;
}

.cd-accordion-menu a::after {
    /* image icon */
    left: 36px;
}

.cd-accordion-menu input[type=checkbox]:checked+label::before {
    /* rotate arrow */
    -webkit-transform: translateY(-11%) translateX(91%);
    -moz-transform: translateY(-11%) translateX(91%);
    -ms-transform: translateY(-11%) translateX(91%);
    -o-transform: translateY(-11%) translateX(91%);
    transform: translateY(-11%) translateX(91%);
}

.cd-accordion-menu input[type=checkbox]:checked+label::after {
    /* show open folder icon if item is checked */
    background-position: -32px 0;
}

.cd-accordion-menu input[type=checkbox]:checked+label+ul,
.cd-accordion-menu input[type=checkbox]:checked+label:nth-of-type(n)+ul {
    /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
    /* show children when item is checked */
    display: block;
}

.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
    padding-left: 0px;
}

.no-touch .cd-accordion-menu ul label:hover,
.no-touch .cd-accordion-menu ul a:hover {
    background: #3c3f45;
}

.cd-accordion-menu>li:last-of-type>label,
.cd-accordion-menu>li:last-of-type>a,
.cd-accordion-menu>li>ul>li:last-of-type label,
.cd-accordion-menu>li>ul>li:last-of-type a {
    box-shadow: none;
}

.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
    left: 59px;
}

.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
    left: 77px;
}

.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
    left: 95px;
}

.cd-accordion-menu ul label::before {
    left: auto;
    right: -10px;
    top: 15px;
}

@media only screen and (min-width: 600px) {
    .cd-accordion-menu label,
    .cd-accordion-menu a {
        font-size: 1.1rem;
    }
    .cd-accordion-menu label::after {
        left: 53px;
    }
    .cd-accordion-menu ul label::after,
    .cd-accordion-menu ul a::after {
        left: 77px;
    }
    .cd-accordion-menu ul ul label::after,
    .cd-accordion-menu ul ul a::after {
        left: 101px;
    }
    .cd-accordion-menu ul ul ul label::after,
    .cd-accordion-menu ul ul ul a::after {
        left: 125px;
    }
}

.cd-accordion-menu.animated label::before {
    /* this class is used if you're using jquery to animate the accordion */
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

.cd-accordion-menu label::before,
.cd-accordion-menu label::after {
  
    background-image: url('../../assets/images/ic_keyboard_arrow_down_black_24dp_2x.png');
    background-repeat: no-repeat;
    background-position: right 0px 0px;
}

.has-children {
    padding-right: 2rem;
}

.has-children li {
   padding-right: 2.7rem;
    padding: 0.2rem 2rem;
    border-bottom: 1px solid #eee;
}
.has-children li:last-child
{
    border-bottom: none;
}

.group-1-label {
    font-weight: bolder;
    color: #333;
}
.sitemap .main_ul li
{
    font-weight: 900;
    color: #333;
}
.sitemap .main_ul li ul li
{
    font-weight: 300;
    color: #7f7f7f;
}

.sitemap .main_ul a:hover
{
    color: $base-1;
    
}
.group-1-label:before , 
.group-1-label:after
{
    content: none;
    display: none !important;
}

