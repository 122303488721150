.msg_history .main_inner_container {
    padding: 0rem !important;
}

.msg_history .visit_details_header {
    padding: 0rem 8rem;
    background-color: #fff;
    border-bottom: 7px solid #d0d0d0;
}

.msg_history_body {
    background-color: #eee;
    overflow-y: scroll;
    max-height: 451px;
    padding: 1rem 0rem;
    position: relative;
    padding-bottom: 0rem;
    direction: ltr;
}

.msg_date {
    text-align: center;
}

.msg_date h2 {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    color: #776f6f;
    display: inline-block;
    padding: 0.5rem 0.8rem;
    background-color: #e6e6e6;
    border-radius: 5px;
}

.user_msg {
    float: left !important
}

.user_msg .msg_container {
    background-color: $base-6;
    color: $base-6-alt;
    float:right;
}

.doctor_msg .msg_container {
    background-color: #fff;
    color: #000;
}

.user_msg .msg_container,
.doctor_msg .msg_container {
    padding: 1rem;
    border-radius: 5px;
    max-width: 86%;
    width: 100%;
    position: relative;
}

.msg_history_body .inner_container {
    padding: 0rem 8rem;
    padding-bottom: 3rem;
}

.user_msg,
.doctor_msg {
    padding: 1rem;
}

.msg_history_body .download_perscription {
    padding: 1rem;
    background-color: #fff;
    position: relative;
    bottom: 0;
    width: 100%;
    top: auto;
}

.msg_history_body .download_perscription a {
    max-width: 219px;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
}

.visit_msg span {
    padding: 0rem 0.5rem;
}

.msg_time {
    text-align: center;
    margin: auto;
    display: block;
    float: none;
}

.chat_name {
    text-align: start;
    font-weight: 900;
}

.chat_time {
    text-align: start;
    display: inline-block;
    float: left;
    font-size: 0.7rem;
    color: #777;
    padding: 0.1rem 0rem;
        position: absolute;
    bottom: -27px;
    top: auto;
left: 0;

}

.msg_container p {
    width: 100%;
    display: inline-block;
    text-align: start;
    position: inherit;
    z-index: 99999;
}

.chatImg 
{
        width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;

}

.doctor_msg  .chatImg 
{
    float: right;
}

.user_msg .chatImg
{
    float: left;
} 

.user_msg .msg_container:after
{
     content: "";
    height: 20px;
    width: 20px;
    /* border-radius: 10px; */
    background-color: inherit;
    position: absolute;
    left: -4px;
    top: 20%;
    -ms-transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    transform: rotate(45deg);
      
}

.doctor_msg .msg_container:after
{
      content: "";
    height: 20px;
    width: 20px;
  
    background-color: inherit;
    position: absolute;
    right: -4px;
    top: 20%;
    -ms-transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    transform: rotate(45deg);
   
}

.doctor_msg .chat_time
{
   left: auto;
   right: 0;
}

.user_msg .chat_name{
   left: 0;
   right: auto;
}