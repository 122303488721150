.breadCrumbs {
  width: 100%;
  margin: auto auto;
  display: block;
  padding: 1rem 4%;
}

.breadCrumbs span {
  font-size: 1.1rem;
}

.breadCrumbs span i {
  font-size: 0.5rem;
  vertical-align: baseline;
  padding: 0rem 0.5rem;
  color: $base-6;
}

.breadCrumbs span span {
  color: #e8308a;
}

.breadCrumbs span a span {
  color: #000;
}
