[dir='ltr'] .intro .intro_form_container {
  right: auto;
  left: 3%;
  float: left;
}

[dir='ltr'] .notification_general h3 span {
  position: static;
  padding: 0 1rem;
  float: none;
}

[dir='ltr'] .table_row .notification_item i,
[dir='ltr'] .notification_general a,
[dir='ltr'] header .toogle_navbar_icon,
[dir='ltr'] .intro .heading_inner_container {
  float: right;
}
[dir='ltr'] .home-medical-form form select {
  background-position: right !important;
}

[dir='ltr'] .intro .app_icons_group {
  float: right;
  left: auto;
  right: 2%;
}

[dir='ltr'] footer .important_links ul li:last-child {
  border-right: none !important;
}

[dir='ltr'] .payment_month,
[dir='ltr'] .payment_details {
  float: left !important;
}

[dir='ltr'] .inline_title.title {
  float: left !important;
}

[dir='ltr'] header .header_hotline i {
  padding-right: 1rem;
  padding-left: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

[dir='ltr'] .visits_section .person_details,
[dir='ltr'] .visits_section .person_img_container,
[dir='ltr'] .visits_section .visit_details,
[dir='ltr'] .visit_details_header .person_img_container {
  float: left !important;
}

[dir='ltr'] .register_form_container #agree {
  float: left;
  padding-left: 0;
  margin-left: 0;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

[dir='ltr'] .noDegree_notify h2,
[dir='ltr'] .top_profile_details .address_phone h5,
[dir='ltr'] header .logo {
  float: left;
}

[dir='ltr'] header .arrow_header img {
  margin: 0 0.5rem;
}

[dir='ltr'] .notification,
[dir='ltr'] header nav.navbar .login_header_link,
[dir='ltr'] #showAddQualificationButton,
[dir='ltr'] #showAddExperienceButton,
[dir='ltr'] #showAddSpecialized,
[dir='ltr'] #cancelQualificationAddForm,
[dir='ltr'] #cancelExperienceEditButton,
[dir='ltr'] #cancelSpecializedAdd,
[dir='ltr'] .cancelSpecializedEdit,
[dir='ltr'] #cancelExperienceAddForm,
[dir='ltr'] #certificates_profile .certificates_header .add_certificate,
[dir='ltr'] #experience_profile .experience_header .add_experience,
[dir='ltr'] #specializedIn_profile .specializedIn_header .add_specializedIn,
[dir='ltr'] header nav.navbar .link_with_border,
[dir='ltr'] header nav.navbar {
  float: right;
}

[dir='ltr'] .logedin_header_link {
  padding-right: 0vw !important;
  padding-left: 3vw !important;
}

[dir='ltr'] header .logout_header_link {
  left: 2rem;
}

[dir='ltr'] header .profileLoader {
  float: left;
  margin-left: 0rem;
  margin-right: 10px;
}

[dir='ltr'] header .profileLoader {
  width: 59px;
}

[dir='ltr'] footer .important_links ul li {
  padding-left: 0px;
}

[dir='ltr'] .notification.show_notification ul {
  float: right;
  left: auto;
  right: 3px;
}

[dir='ltr'] .notification_item a i,
[dir='ltr'] .imageHolder {
  float: left;
}

[dir='ltr'] .editProfile_page #experience_profile .save_cancel .save {
  margin: 0px 10px;
  line-height: 30px;
}

[dir='ltr'] .imageHolder .removeAttachment {
  top: 0;
  left: 0;
  right: auto;
}

[dir='ltr'] footer .important_links ul li {
  border-left: 0;
  border-right: 1px solid #000;
}

[dir='ltr'] .top_profile_details .name_img {
  position: relative;
  float: left;
  margin: 0 1rem 0 0;
}

[dir='ltr'] .step_title {
  padding: 0 0 0 700px;
  float: right;
}

[dir='ltr'] .svg_container {
  float: left;
  right: auto;
  left: 16%;
}

[dir='ltr'] .footer-post-heading h2 span {
  margin-left: 0;
  margin-right: 1rem;
}

[dir='ltr'] header .link_back i {
  float: left;
  margin: 8px 0 0 0;
}

[dir='ltr'] .intro-slider h2 {
  direction: ltr;
}

[dir='ltr'] .how_it_work_user .prev_step,
[dir='ltr'] .how_it_work_doctor .prev_step {
  right: auto;
  left: 6%;
}

[dir='ltr'] .how_it_work_user .next_step,
[dir='ltr'] .how_it_work_doctor .next_step {
  left: auto;
  right: 6%;
}

[dir='ltr'] .common_question_link,
[dir='ltr'] .notification_item h3 {
  direction: ltr;
}

[dir='ltr'] .help .question,
[dir='ltr'] .help .question_mark_icon {
  float: left;
}

[dir='ltr'] .blogListing .articles_section_container .articles_box {
  float: left;
}

[dir='ltr'] .how_it_work_user .inner_container,
[dir='ltr'] .how_it_work_doctor .inner_container {
  overflow: hidden;
}

[dir='ltr'] .header-fixed .header-actions {
  margin-right: 0;
  margin-left: auto;
}

[dir='ltr'] .how_it_work_step_img span {
  margin-left: 9%;
  margin-right: 0;
}

[dir='ltr'] .footer-links {
  text-align: left;
}

[dir='ltr'] footer h2 {
  text-align: left;
}

[dir='ltr'] .footer-contact p span {
  margin-right: 0.5rem;
  margin-left: 0;
}

[dir='ltr'] .footer-post-heading a {
  margin-right: 0;
  margin-left: auto;
}

[dir='ltr'] .header-contact a {
  flex-flow: row;
}

[dir='ltr'] .pr-05 {
  padding-left: 0.5rem;
}
