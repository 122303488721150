.alert {
    position: absolute;
    z-index: 9999999;
}

.alert strong {
    display: none;
}

.notification_general h3 span
{
        position: relative;
    font-size: inherit;
    float: left;
    font-weight: 400;
}

.notification_general a{
        float: left;
        color: #fff;
        transition: all 200ms;

}
.notification_general
{
    background-color:  #3366ff
}
.notification_general a:hover 
{
    color: #000;
    transition: all 200ms;
}
 .notification_general span
 {
     box-shadow: none;
 }
 .notification_general span:hover 
    {
        text-shadow: 1px 1px 4px #000;
    }


    .notification--custom {
        position: absolute;
        background: #E8E8E8;
        width: 80vw;
        height: 30vh;
        top: 72%;
        left: 10%;
        z-index: 999;
        border-radius: 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
    }

    .notification--custom .close-noti {
        font-size: 2rem;
        position: absolute;
        right: 3rem;
        top: 2rem;
        cursor: pointer;
    }

    .notification--custom p {
        font-size: 1.3rem;
        font-weight: bold;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }

.webview .notification--custom {
  width: unset !important;
  height: unset !important;
  margin-top: 50% !important;
  left: 5% !important;
  right: 5% !important;
  padding: 1rem !important;
  box-shadow: 0px 0px 10px #00000055 !important;
}

  .webview .notification--custom .close-noti {
    right: 1rem !important;
    top: 0rem !important;
  }