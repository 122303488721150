.doctor-details {
  background-color: #f4f4f4;
  padding: 4rem 0;
  position: relative;
}

.doctor-details .doctor-details-card-image-details {
  display: flex;
  align-items: flex-start;
}

.doctor-details .doctor-details-card-image img {
  border-radius: 100px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
}

.doctor-details .card .doctor-details-titles {
  justify-content: space-between;
}

.doctor-details .card h3 {
  color: #468e9e;
  font-size: 1.5rem;
}

.doctor-details .card p {
  color: #4c4c4c;
  font-size: 1.1rem;
  padding: 0.5rem 0;
}

.doctor-details .card .doctor-details-card-rating .fa-star {
  color: #ffb501;
  font-size: 1.5rem;
}
.doctor-details .card .doctor-details-card-rating .fa-star-o {
  color: #d3d3d3;
  font-size: 1.5rem;
}

.doctor-details .rating-btn {
  border-radius: 30px;
  background-color: #599ea5;
  color: #fff;
  padding: 1rem 6rem;
}

.doctor-details .doctor-details-announcement {
  border-radius: 40px;
  border: solid 2px #66cccc;
  margin-top: 2rem;
  position: relative;
}

.doctor-details .doctor-details-announcement .card-body span {
  font-size: 1.5rem;
}

/* modelsss */

/* 1. Ensure this sits above everything when visible */
.model-details {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}

.model-details.is-visible {
  visibility: visible;
}

.model-details-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}

.model-details.is-visible .model-details-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.model-details-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.model-details-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.model-details.is-visible .model-details-transition {
  transform: translateY(0);
  opacity: 1;
}

.model-details-header,
.model-details-content {
  padding: 1em;
}

.model-details-header {
  position: relative;
  background-color: #fff;
}

.model-details-close {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em;
  color: #4c4c4c;
  background: none;
  border: 0;
}

.model-details-close:hover {
  color: #777;
}

.model-details-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.model-details-content > *:first-child {
  margin-top: 0;
}

.model-details-content > *:last-child {
  margin-bottom: 0;
}

.model-details .model-details-body h3 {
  color: #7f7f7f;
}

.model-details .model-details-body {
  margin: 1rem 0;
}

.model-details .model-details-body .doctor-details-model-rating {
  margin: 1rem 0;
}

.model-details .model-details-body .fa-star {
  color: #d3d3d3;
  font-size: 2.5rem;
  margin: 0 0.5rem;
}

.model-details .model-details-body .fa-star.active,
.model-details .model-details-body .fa-star.active-hover {
  color: #ffb501;
  font-size: 2.5rem;
}

.model-details .model-details-body .fa-star-o {
  color: #d3d3d3;
  font-size: 2.5rem;
}

.model-details .model-details-body textarea {
  border-radius: 20px;
  border: solid 3px #e9e9e9;
  width: 100%;
  padding: 0 1rem;
}

.model-details .model-details-body button {
  border-radius: 29.3px;
  background-color: #599ea5;
  padding: 1rem 3rem;
  color: #fff;
  border: none;
  outline: none;
}

@media (max-width: 800px) {
  .doctor-details .rating-btn {
    padding: 1rem 3rem;
  }

  .doctor-details .doctor-details-card-image-details {
    justify-content: center;
  }

  .doctor-details .col-lg-10 {
    text-align: center;
  }

  .doctor-details .card .doctor-details-titles {
    justify-content: center;
  }

  .model-details-wrapper {
    left: unset !important;
    width: 100%;
  }

  .doctor-listing .doctor-listing-card-image img {
    height: 100px;
    width: 100px;
  }
}
