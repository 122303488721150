.loginPage .title {
    color: $black;
    padding: 1rem;
    text-align: start;
    font-size: 1.5rem;
}

. .login_form,
.login_page .links {
    float: none;
    margin: auto;
    display: block;
    width: 50%;
}

.login_page .app_icons_group {
    width: 30%;
    float: none;
    margin: auto;
    display: block;
}

.loginPage form input {
    width: 100%;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: #f6f6f6;
    border: none;
    border: 1px solid #e3e3e3;
}

.loginPage form .login_btn input {
    background-color: $base-6;
    color: $white;
}

.loginPage .links {
    padding: 1rem;
}

.loginPage .links a {
    padding: 0.5rem;
    font-size: 1rem;
    color: $black;
    display: inline-block;
    float: right;
    text-align: center;
}

.loginPage .links a:hover {
    color: $base-1;
    transition: all 150ms;
}

.loginPage .forgetPassword,
.loginPage .CreateAcoount {
    padding: 0.8rem;
    text-align: center;
}

.loginPage .CreateAcoount a {
    text-decoration: underline;
    color: #7f7f7f;
}

.loginPage .forgetPassword a {
    color: $base-4;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all 200ms;
}

.loginPage .forgetPassword a:hover,
.loginPage .CreateAcoount a:hover {
    color: $base-6;
    transition: all 200ms;
}

.fb_btn a {
    display: block;
    background-color: $base-fb;
    color: $white;
    width: 100%;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e3e3e3;
    text-align: center;
    position: relative;
}

.fb_btn a i {
    color: $white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 2.9rem;
    background: rgba(0, 0, 0, .25);
    line-height: 3rem;
    font-size: 2rem;
}

.loginPage .intro,
.contact_us_page .intro {
    background-image: url('../images/04_Otlob_register_BG.jpg') !important;
}

.loginPage .register_form_container .form_header {
    text-align: start;
    padding: 0.5rem;
}