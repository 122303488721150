.clinic-details .clinic-img {
  border-radius: 100px;
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: top;
}

.clinic-details .clinic-name {
  color: #236170;
  font-size: 2rem;
}

.clinic-details .clinic-specif {
  color: #4f5151;
  font-size: 1.5rem;
}

.clinic-details .clinic-para {
  color: #4f5151;
  font-size: 1.3rem;
}

.clinic-details .rating-clinicDetails-stars,
#rating-comments .rating-clinicDetails-stars {
  color: #ffb501;
  font-size: 1.5rem;
}

.clinic-details #map {
  height: 400px;
  width: 100%;
}

.clinic-details .map-card-body {
  background-color: #f4f4f4;
}

.clinic-details .clinic-more-details {
  margin-top: 2rem;
}

.clinic-details .clinic-more-details p,
.clinic-details .clinic-more-details a {
  color: #236170;
  font-size: 0.9rem;
}

.clinic-details .clinic-more-details img {
  margin-left: 0.5rem;
}

.clinic-details .btn-clinicDetails-appointment {
  border-radius: 5px;
  background-color: #478e9e;
  width: 100%;
  display: block;
  color: #fff !important;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 0;
  border: none;
}

.clinic-details-tabs {
  background-color: #f4f4f4;
  padding: 3rem 0;
}

.clinic-details .clinic-details-tabs ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}
.clinic-details .clinic-details-tabs ul.tabs li {
  background-color: #e8e8e8;
  display: inline-block;
  padding: 1rem 0;
  cursor: pointer;
  flex: 1;
  text-align: center;
  color: #236170;
  font-weight: bold;
}

.clinic-details .clinic-details-tabs ul.tabs li.current {
  background: #fff;
  color: #236170;
}

.clinic-details .clinic-details-tabs .tab-content {
  display: none;
  background: #fff;
  padding: 2rem 15px;
}

.clinic-details .clinic-details-tabs .tab-content.current {
  display: inherit;
}

.clinic-details .clinic-details-tabs .tab-content .clinic-details-tags span {
  border-radius: 24px;
  border: solid 1px #236170;
  background-color: #f8f8f8;
  padding: 0.3rem 1rem;
  color: #236170;
}

.clinic-details .clinic-details-tabs .tab-content .images-container-tab3 img {
  width: 200px;
  border-radius: 10px;
  margin-left: 0.5rem;
  height: 200px;
  object-fit: cover;
}

.clinic-details .clinic-reserve-online img {
  width: 408px;
  height: 105px;
}

#reservation-appointment.modal {
  max-width: 600px;
  text-align: right;
}

#reservation-appointment.modal a.close-modal {
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/profile/Icon_ionic-ios-close-circle-outline.svg');
}

#reservation-appointment h4 {
  color: #236170;
  margin: 1rem 0;
}

#reservation-appointment .choose-time li {
  border-radius: 25px;
  border: solid 1px #e8e8e8;
  background-color: #f8f8f8;
  padding: 0.1rem 0.7rem;
  margin-top: 0.5rem;
  color: #236170;
  direction: ltr;
  cursor: pointer;
}

#reservation-appointment .choose-time li.active {
  border-radius: 25px;
  border: solid 1px #e8e8e8;
  background-color: #ec1b98;
  color: #fff;
}

#rating-comments.modal {
  max-width: 600px;
  text-align: right;
  border-radius: 48px;
  box-shadow: 0 3px 30px #000;
  border: solid 1px #dedede;
}

#rating-comments.modal .rating-comments-details-container {
  justify-content: space-between;
  margin: 1rem 0;
}

#rating-comments.modal .rating-comments-details-container p {
  color: #4f5151;
  padding-bottom: 0.5rem;
}

#rating-comments.modal .rating-comments-date {
  margin: auto 0;
  color: #ababab;
}

#rating-comments.modal h4 {
  color: #236170;
}

#reserve-clinic-navmenu {
  position: relative;
}
#reserve-clinic-navmenu span {
  border-radius: 8px;
  background-color: #ec1b98;
  font-size: 0.7rem;
  color: #fff;
  padding: 0 0.5rem;
  position: absolute;
  top: -1rem;
  left: 1rem;
}

/* //general css */

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item:not(:last-child) {
  margin-left: 1rem;
}

.list-inline-item {
  display: inline-block;
}

.container-customCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  margin-left: 0.5rem;
}

/* On mouse-over, add a grey background color */
.container-customCheckbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-customCheckbox input:checked ~ .checkmark {
  background-color: #ec1b98;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-customCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-customCheckbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 800px) {
  .clinic-details .clinic-more-details .mr-3rem {
    margin-right: unset;
  }

  .clinic-details .clinic-reserve-online img {
    width: 200px;
    height: 75px;
    margin-bottom: 2rem;
  }

  #reserve-clinic-navmenu span {
    top: 0.1rem;
  }
}

button[disabled] {
    cursor: not-allowed;
}

#loading-appointment-progress {
    display: none;
    width: 80px;
    height: 80px;
}

    #loading-appointment-progress:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #478e9e;
        border-color: #478e9e transparent #478e9e transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

#gallary-img-view {
  max-width: 800px;
  max-height: 80%;
  border-radius: 48px;
}

#clinic-img-view {
  height: calc(65% - 20px);
  object-fit: contain;
}

.btn-view {
  border-radius: 0.35rem;
  background-color: #478e9e;
  color: #ffffff;
  font-size: 1rem;
  border: unset;
  padding: 0.35rem 1rem;
}

button[disabled].btn-view {
  background-color: #cfcfcf;
}

.arrow-icon {
  width: 16px;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}